import { Button, Grid } from "@material-ui/core"
import useStyles from "../../styles";
import { TextInput, SelectInput, PhoneInput } from "../UI/Controls/InputFields";
import { useHistory } from "react-router";
import { safeInt } from "../Base/Utils";
import { getDefaultStateCode } from './../Base/DataProvider';
import { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Common from '../Base/Common';
import * as yup from 'yup';
import shallow from 'zustand/shallow';
import axios from 'axios';
import WebAPI from '../Base/WebAPI';
import AuthContext from "../Auth/AuthContext";
import useStore from "../Base/Store";
import * as Utility from '../Base/Utils';
import * as DataProvider from '../Base/DataProvider';
import { useParams } from 'react-router-dom';
import AppIcon from "../UI/Controls/AppIcons";
import { PageHeader } from "../UI/Controls/Controls";


export default function Address(props) {
    const classes = useStyles();
    const history = useHistory();
    const { type, id } = useParams();
    const authContext = useContext(AuthContext);
    const showAlert = useStore(state => state.showAlert);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [fieldValues, setFieldValues] = useState({
        "id": 0,
        "description": "",
        "addressLine1": "",
        "addressLine2": "",
        "addressType": "",
        "isPrimary": false,
        "referenceId": 0,
        "referenceType": "Customer",
        "stateId": DataProvider.getDefaultStateCode(authContext),
        "city": "",
        "pinCode": "",
        "contactPerson": "",
        "contactEmail": "",
        "contactPhone": "",
        "active": true
    });

    const submitFormData = async (values, { resetForm }) => {
        try {
            setWaiting(true);
            let apiUrl = '/Address/CreateAddress'
            if (safeInt(props.selectedAddress) > 0) {
                apiUrl = '/Address/UpdateAddress';
            }
            let request = {
                "id": safeInt(props.selectedAddress),
                "description": values.description,
                "addressLine1": values.addressLine1,
                "addressLine2": values.addressLine2,
                "addressType": 'Shipping',
                "isPrimary": false,
                "referenceId": Utility.safeInt(id),
                "referenceType": type === "purchaseorder" ? Common.SUPPLIER_ENTITY : Common.CUSTOMER_ENTITY,
                "stateId": Utility.safeInt(values.stateId),
                "city": values.city,
                "pinCode": values.pinCode,
                "contactPerson": values.contactPerson,
                "contactEmail": values.contactEmail,
                "contactPhone": values.contactPhone,
                "website": '',
                "active": true
            };

            if (!Utility.safeBool(props.isNewCustomer)) {
                const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
                if (!!response.data.code && response.data.code > 0) {
                    showAlert(response.data.message, 'error');
                } else {
                    resetForm();
                    closeDialog();
                    response.data.fullAddress = [
                        Utility.safeString(response.data.addressLine1),
                        Utility.safeString(response.data.addressLine2),
                        Utility.safeString(response.data.city),
                        Utility.safeString(response.data.pinCode),
                    ].filter(Boolean).join(' ');
                    setDialogResponse(response.data);
                    showAlert('Address saved successfully.', 'success');
                }
            } else {
                resetForm();
                closeDialog();
                request.fullAddress = [
                    Utility.safeString(request.addressLine1),
                    Utility.safeString(request.addressLine2),
                    Utility.safeString(request.city),
                    Utility.safeString(request.pinCode),
                ].filter(Boolean).join(' ');
                setDialogResponse(request);
                showAlert('Address added successfully.', 'success');
            }
        } catch (error) {
            showAlert("Failed to save Customer. Inernal server error occured." + error.response, "error");
        } finally {
            setWaiting(false);
        }
    }

    const fetchData = async (token) => {
        if (props.selectedAddress > 0) {
            const response = await DataProvider.getData('Address', 'GetAddressById', authContext, token, props.selectedAddress);
            if (!Utility.isNullOrUndefined(response)) {
                if (Utility.safeInt(response.code) > 0) {
                    showAlert(response.message, 'error');
                } else {
                    let tempvalues = { ...fieldValues };
                    tempvalues.id = Utility.safeInt(response.id);
                    tempvalues.description = response.description;
                    tempvalues.addressLine1 = response.addressLine1;
                    tempvalues.addressLine2 = response.addressLine2;
                    tempvalues.addressType = response.addressType;
                    tempvalues.isPrimary = response.isPrimary;
                    tempvalues.referenceId = Utility.safeInt(response.id);
                    tempvalues.referenceType = response.referenceType;
                    tempvalues.stateId = Utility.safeInt(response.stateId);
                    tempvalues.city = response.city;
                    tempvalues.pinCode = response.pinCode;
                    tempvalues.contactPerson = response.contactPerson;
                    tempvalues.contactEmail = response.contactEmail;
                    tempvalues.contactPhone = response.contactPhone;
                    tempvalues.website = response.website;
                    tempvalues.active = true
                    setFieldValues(tempvalues);
                }
            }
        }
    }
    useEffect(() => {
        let source = axios.CancelToken.source();
        if (Utility.safeInt(props.selectedAddress) > 0) {
            async function init() {
                if (!Utility.isNullOrUndefined(authContext.token)) {
                    await fetchData(source.token);
                }
            }
            init();
        }
        return () => {
            source.cancel();
        };
    }, []);
    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
                contactEmail: yup.string().matches(Common.REGEX_EMAIL_MULTI, 'Invalid email'),
                pinCode: yup.string().matches(Common.REGEX_ALPHA_NUMERIC, 'Invalid value'),
                contactPhone: yup.string().matches(Common.REGEX_PHONE_MULTI, 'Invalid mobile number.'),
            })}
            onSubmit={
                async (values, { resetForm }) => {
                    await submitFormData(values, { resetForm });
                }
            }
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <Grid container alignContent="space-between">
                        <Grid item xs={6} sm={6}>
                            <PageHeader heading={`${props.selectedAddress > 0 ? "Update" : "Create"} shipping address`}>
                                <AppIcon name="Shipping" color="primary" />
                            </PageHeader>
                        </Grid>

                        <Grid item xs={6} sm={6}>

                        </Grid>
                    </Grid>
                    <div className={`${classes.page} ${classes.mb1}`}>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Deliver to" name="description" placeholder="" required
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Address line 1" name="addressLine1" placeholder="" required
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Address line 2" name="addressLine2" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Town/City" name="city" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <SelectInput label="State" name="stateId" required>
                                        {
                                            props.stateData.map((item, index) => {
                                                return (
                                                    <option key={safeInt(item.code)} value={safeInt(item.code)}>{item.description}</option>
                                                )
                                            })
                                        }
                                    </SelectInput>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Pincode" name="pinCode" placeholder=""
                                        inputProps={{ maxLength: 6 }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Contact person" name="contactPerson" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Email" name="contactEmail" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <PhoneInput label="Phone" name="contactPhone" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={12} className={classes.pt1}>
                            <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">Save address</Button>
                            <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => closeDialog()}>Close</Button>
                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    )

};
