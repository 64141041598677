import { useState, useContext, useEffect } from "react"
import { isNullOrUndefined, safeInt, safeString } from "../../Base/Utils"
import { Form, Formik } from "formik";
import { generatePdfAttachment, getParty, sendEmail } from "../OrderHelper";
import * as yup from 'yup';
import { PageHeader } from "../../UI/Controls/Controls";
import AppIcon from "../../UI/Controls/AppIcons";
import useStyles from "../../../styles";
import { Button, Grid} from "@material-ui/core";
import { TextInput } from "../../UI/Controls/InputFields";
import useStore from "../../Base/Store";
import AuthContext from '../../Auth/AuthContext';
import shallow from "zustand/shallow";
import { CUSTOMER_ENTITY, REGEX_EMAIL_MULTI } from "../../Base/Common";

export default function EmailSend({ orderId, orderType, referenceType, selectedParty, selectedOrder }) {
    const [fieldValues, setFieldValues] = useState(
        {
            emailAddress: "",
        }
    );
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const showAlert = useStore(state => state.showAlert);
    const closeDialog = useStore(state => state.closeDialog);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    const pageInfo = {
        pageHeading: 'Send via Email',
        pageIcon: 'Email',
        okButtonCaption: 'Send',
        secondaryButtonCaption: 'Send to Email',
        cancelButtonCaption: 'Close',
        entityName: ""
    }

    useEffect(() => {
        async function init() {
            try {
                setWaiting(true);
                const party = await getParty(safeInt(selectedParty.id), referenceType, authContext);
                let tempValues = { ...fieldValues };
                if (!isNullOrUndefined(party)) {
                    if (!isNullOrUndefined(party.addressList) && party.addressList.length > 0) {
                        const address = party.addressList.find(a => a.addressType === 'Billing')
                        tempValues.emailAddress = safeString(address.contactEmail);
                    }
                }
                setFieldValues(tempValues);
            } catch (error) {
                console.log(error);
            } finally {
                setWaiting(false);
            }
        }
        init();
    }, []);



    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            await generatePdfAttachment(orderId, orderType, selectedParty.id, CUSTOMER_ENTITY, authContext,
                async (buffer) => {
                    try {
                        await sendEmail(orderType, CUSTOMER_ENTITY, selectedOrder, selectedParty, showAlert, buffer, authContext,values);
                        closeDialog();
                    } catch (error) {
                        console.log(error);
                        showAlert('An error occured while performing the selected action. Please try again or contact support.', 'error')
                    } finally {
                        setWaiting(false);
                    }
                }
            );

        } catch (error) {
            setWaiting(false);
            console.log(error);
            showAlert('An error occured while performing the selected action. Please try again or contact support.', 'error')
        }
    }

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
                emailAddress: yup.string().matches(REGEX_EMAIL_MULTI, 'Invalid email. Please use ";" as separator to enter multiple emails.'),
            })}
            onSubmit={
                async (values) => {
                    await submitFormData(values, false);
                }
            }
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <PageHeader heading={pageInfo.pageHeading}>
                        <AppIcon name={pageInfo.pageIcon} color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput label="Email" name="emailAddress" placeholder="person1@gmail.com; person2@abc.com"
                                    inputProps={{}} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item container xs={12} className={`${classes.mt2}`} alignItems="center">
                                <Button type="submit" disabled={isSubmitting || waiting} variant="contained" color="secondary"
                                    startIcon={<AppIcon name="Email" size="small" />} title="Send to an Email"
                                    className={`${classes.ml1}`}
                                >
                                    {pageInfo.secondaryButtonCaption}
                                </Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => { closeDialog() }}>
                                    {pageInfo.cancelButtonCaption}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    )
};
