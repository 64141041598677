import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { PageHeader } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import useStyles from '../../../styles';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { CurrencyInput, SelectInput } from '../../UI/Controls/InputFields';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import AuthContext from '../../Auth/AuthContext';
import { formatCurrencyAmount, getAmountExcludingTax, getAmountIncludingTax, ifNullOrEmpty, safeAmount, safeBool, safeInt, safeString } from '../../Base/Utils';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function CalculateRates({ isModal, taxRate, showUseRate = true }) {

    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const classes = useStyles();
    const [fieldValues, setFieldValues] = useState({
        taxRate: ifNullOrEmpty(taxRate, "18"),
        rateIncludingTax: "",
        rateExcludingTax: "",
        taxAmount: 0,
        cgstAmount: 0,
        sgstAmount: 0,
    });
    const authContext = useContext(AuthContext);
    const taxRateData = authContext.masterData.filter(md => md.type === 'TaxRate' && md.code !== "0");
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);
    const [filledAmout, setFilledAmount] = useState(0);
    const taxInclusivePricing = safeBool(authContext.settings.taxInclusivePricing);
    const [gstCalculater, setgstCalculater] = useState(taxInclusivePricing ? 'withoutTax' : 'withTax');
    const decimalPlaces = safeInt(authContext.settings.decimalPlaces);

    const handleGSTtaxChange = (values, setFieldValue) => {
        setgstCalculater(values);
        const newValue = gstCalculater === 'withTax' ? 'withoutTax' : 'withTax';
        setgstCalculater(newValue);
        setFieldValue('gstCalculater', newValue);
        handleChange();
    }

    const handleChange = (values, setFieldValue) => {
        try {
            if (gstCalculater === 'withTax') {
                let amountWithoutTax = getAmountExcludingTax(safeAmount(values.rateIncludingTax), safeAmount(values.taxRate));
                let taxAmount = safeAmount(values.rateIncludingTax) - amountWithoutTax;
                setFilledAmount(safeAmount(amountWithoutTax).toFixed(decimalPlaces));
                setFieldValue('taxAmount', taxAmount.toFixed(decimalPlaces));
                setFieldValue('cgstAmount', safeAmount(taxAmount / 2).toFixed(decimalPlaces));
                setFieldValue('sgstAmount', safeAmount(taxAmount / 2).toFixed(decimalPlaces));
            } else {
                let amountWithTax = getAmountIncludingTax(safeAmount(values.rateExcludingTax), safeAmount(values.taxRate));
                let taxAmount = amountWithTax - safeAmount(values.rateExcludingTax);
                setFilledAmount(safeAmount(amountWithTax).toFixed(decimalPlaces));
                setFieldValue('taxAmount', taxAmount.toFixed(decimalPlaces));
                setFieldValue('cgstAmount', safeAmount(taxAmount / 2).toFixed(decimalPlaces));
                setFieldValue('sgstAmount', safeAmount(taxAmount / 2).toFixed(decimalPlaces));
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
                rateIncludingTax: yup.number().positive('Invalid value')
            })}
            onSubmit={
                (values) => {
                    var calculatedRate = values.rateIncludingTax / (1 + values.taxRate / 100);
                    var priceExcludingTax = Math.round(calculatedRate * 100) / 100;
                    setFieldValues({
                        taxRate: values.taxRate,
                        rateIncludingTax: values.rateIncludingTax,
                        rateExcludingTax: priceExcludingTax
                    });
                }
            }
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <PageHeader heading='GST Calculator'>
                        <AppIcon name="Calculator" color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SelectInput label="GST (Tax Rate)" name="taxRate" required onBlur={(e) => {
                                    setFieldValue('taxRate', e.target.value);
                                    handleChange(values, setFieldValue);
                                }
                                }>
                                    {
                                        taxRateData.map((item, index) => {
                                            return (
                                                <option key={item.code} name="taxRate" value={item.code}>{item.description}</option>
                                            )
                                        })
                                    }
                                </SelectInput>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} className={classes.pullLeft}>
                                    <RadioGroup aria-label="position" value={gstCalculater} name="gstCalculater" defaultValue="top" onChange={() => handleGSTtaxChange(values, setFieldValue)} row className={`${classes.mb2} ${classes.ml1}`}>
                                        <FormControlLabel value="withTax" control={<Radio size="small" />} label="Amount is including Tax" />
                                        <FormControlLabel value="withoutTax" control={<Radio size="small" />} label="Amount is excluding Tax" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            {
                                gstCalculater === "withTax" ? <Grid item xs={12}>
                                    <CurrencyInput currencyCode={currencyCode} label="Amount (Including Tax)" name="rateIncludingTax" placeholder=""
                                        onBlur={(e) => handleChange(values, setFieldValue)} />
                                </Grid> :
                                    <Grid item xs={12}>
                                        <CurrencyInput currencyCode={currencyCode} label="Amount (Excluding Tax)" name="rateExcludingTax" placeholder=""
                                            onBlur={(e) => handleChange(values, setFieldValue)} />
                                    </Grid>
                            }

                            <Grid item container xs={12} spacing={0}>
                                <Grid item xs={12}>
                                    <Card style={{ backgroundColor: '#DBE9FA' }}>
                                        <CardContent>
                                            <Grid container>
                                                <Grid container item xs={12} spacing={1}>
                                                    <Grid item xs={5}>
                                                        <Typography color='primary' component={'h5'} >{gstCalculater === 'withTax' ? 'Taxabale Amount:' : 'Net Amount:'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {formatCurrencyAmount(currencyCode, filledAmout, 'darkgreen', true, '', 2)}
                                                    </Grid>

                                                    <Grid item xs={5}>
                                                        <Typography color='primary' component={'h5'} >{'Tax Amount:'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {formatCurrencyAmount(currencyCode, values.taxAmount, 'darkblue', true, '', 2)}
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography color='primary' component={'h5'} >{'CGST:'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {formatCurrencyAmount(currencyCode, values.cgstAmount, 'blue', true, '', 2)}
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography color='primary' component={'h5'} >{'SGST:'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {formatCurrencyAmount(currencyCode, values.sgstAmount, 'blue', true, '', 2)}
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} sm={12}>
                                {
                                    showUseRate ? <Button variant="contained" color="primary" onClick={() => {
                                        setDialogResponse(filledAmout);
                                        closeDialog();
                                    }}>Use rate</Button> : null
                                }
                                <Button variant="outlined" className={classes.ml1} onClick={() => closeDialog()} color="primary">Cancel</Button>

                            </Grid>
                        </Grid>

                    </div>
                </Form>
            )}
        </Formik>
    );

};
