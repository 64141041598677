import React from 'react';
import { safeBool } from '../../Base/Utils';
import { Card, CardContent, ListItem, ListItemAvatar, Typography, makeStyles } from '@material-ui/core';
import AppIcon from './AppIcons';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '-20px'
    },
    cardContainer: {
        textAlign: 'center',
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        width: 45,
        height: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        color: '#fff',
        backgroundColor: theme.palette.primary.main, // Adjust this to a suitable color
    },
    name: {
        marginTop: theme.spacing(1),
        // fontFamily: 'roboto',
        fontSize: '11px',
        fontWeight: 'bold',
        color: theme.palette.secondary.light, // Ensure text is readable against the background
    },
    mutedTextList: {
        color: theme.palette.text.secondary,
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subsectionContainer: {
        marginTop: '10px',
        borderRadius: '10px'
    }
}));

const UCIconButton = ({ path, iconName, iconColor, iconBackground, caption, disabled = false, handleClick, iconSize = "lg", iconMarginTop = '7px', isImage = false, imageName = "" }) => {
    const classes = useStyles();


    return (
        <div>
            <div className={classes.cardContainer}>
                <Card className={classes.card} style={{/*  background: iconBackground, */ height: '45px', width: '45px', color: iconColor, borderRadius: '23%', padding: '10px', border: `1px solid ${iconColor}` }}>
                    <CardContent className={classes.listItem}>
                        <ListItem
                            path={path}
                            button
                            disableGutters
                            size="small"
                            dense
                            className={classes.quickAddMenu}
                            disabled={disabled}
                            onClick={handleClick}
                        >
                            {
                                safeBool(isImage) ?
                                    <>
                                        <ListItemAvatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px', marginTop: iconMarginTop }}>
                                            <img src={imageName} alt="Account Payable" style={{ color: iconColor, marginLeft: '2px', maxWidth: '65px', maxHeight: '65px' }} />
                                        </ListItemAvatar>
                                    </> :
                                    <ListItemAvatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: iconMarginTop }}>
                                        <AppIcon name={iconName} size={iconSize} style={{ fontSize: "36px" }} />
                                    </ListItemAvatar>
                            }
                        </ListItem>
                    </CardContent>
                </Card>
                <Typography className={classes.name} color="primary">
                    {caption}
                </Typography>
            </div>
        </div>
    );
}

export default UCIconButton;
 