import { EMPLOYEE_ENTITY } from "../Base/Common";
import { getData } from "../Base/DataProvider";
import { isNullOrUndefined, safeFloat, safeInt, safeString } from "../Base/Utils";

export const getEmployeeRequest = (values, isDelete = false) => {
    let request = {};
    request.id = safeInt(values.id);
    request.departmentId = safeInt(values.departmentId);
    request.employeeCode = safeString(values.employeeCode);
    request.firstName = safeString(values.firstName);
    request.surname = safeString(values.surname);
    request.dateOfBirth = values.dateOfBirth;
    request.dateOfJoining = values.dateOfJoining;
    request.currentSalary = safeFloat(values.currentSalary);
    request.idProof = safeString(values.idProof);
    request.educationalQualification = safeString(values.educationalQualification);
    request.experienceDetails = safeString(values.experienceDetails);
    request.additionalDetails = safeString(values.additionalDetails);
    request.active = !isDelete;
    request.addressList = [];
    let address = {};
    address.id = safeInt(values.addressId);
    address.addressLine1 = safeString(values.addressLine1);
    address.addressLine2 = safeString(values.addressLine2);
    address.addressType = safeString(values.addressType);
    address.referenceType = safeString(values.referenceType);
    address.stateId = safeInt(values.stateId);
    address.city = safeString(values.city);
    address.pinCode = safeString(values.pinCode);
    address.contactPerson = safeString(values.contactPerson);
    address.contactEmail = safeString(values.contactEmail);
    address.contactPhone = safeString(values.contactPhone);
    address.active = true;

    request.addressList.push(address);


    return request;
}

export const getEmployees = async (authContext, showAlert, setTableData, token = null) => {
    const response = await getData(EMPLOYEE_ENTITY, "GetEmployeeList", authContext, token);
    if (!isNullOrUndefined(response)) {
        if (safeInt(response.code) > 0) {
            showAlert(response.message, 'error');
        } else {
            setTableData(response);
        }
    }
    return response;
}

export const getEmployee = async (id, authContext, showAlert, token = null) => {
    const response = await getData(EMPLOYEE_ENTITY, "GetEmployeeById", authContext, token, safeInt(id));
    if (!isNullOrUndefined(response)) {
        if (safeInt(response.code) > 0) {
            showAlert(response.message, 'error');
        }
    }
    return response;
}