import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import useStyles from '../../styles';
import AppIcon from '../UI/Controls/AppIcons';
import useStore from "../Base/Store";
import AuthContext from "../Auth/AuthContext";
import { APP_MODULE_BankAccounts, APP_MODULE_Bills, APP_MODULE_CompositeItem, APP_MODULE_CreditNotes, APP_MODULE_CustomerGroups, APP_MODULE_Customers, APP_MODULE_DebitNotes, APP_MODULE_DeliveryChallans, APP_MODULE_Expenses, APP_MODULE_Invoices, APP_MODULE_ItemGroup, APP_MODULE_Items, APP_MODULE_Payables, APP_MODULE_PurchaseOrders, APP_MODULE_Purchases, APP_MODULE_Quotations, APP_MODULE_Receivables, APP_MODULE_SalesOrders, APP_MODULE_Suppliers } from '../Base/Common';

export default function QuickAdd(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const closeDialog = useStore(state => state.closeDialog);

    function menuHeadingClickHandler(e) {
        history.push("/" + e.currentTarget.attributes.path.value);
        closeDialog();
    }
    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4}>
                    <List component="nav" dense={true} tabIndex={0} style={{ border: '1px solid green', borderRadius: '5px', margin: '5px 5px' }}>
                        <ListItem path="newinvoice" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Invoices).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSuccess}>
                                    <AppIcon name="InvoiceIcon" size="small"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<span>Sales invoice</span>} secondary={<span className={classes.mutedTextList}>(Shortcut Alt + N)</span>} style={{ color: theme.palette.success.dark }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Invoices).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="newquotation" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Quotations).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSuccess}>
                                    <AppIcon name="QuotationIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Quotation/Estimate" style={{ color: theme.palette.success.dark }} secondary={<span className={classes.mutedTextList}>(Shortcut Alt + Q)</span>} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Quotations).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no acces to this menu."></AppIcon>
                            }
                        </ListItem>


                        {
                            <ListItem path="newsalesorder" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                                disabled={!authContext.moduleAccess(APP_MODULE_SalesOrders).allowAdd}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatarAddIconSuccess}>
                                        <AppIcon name="SalesOrder"></AppIcon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Sales Order" disableTypography style={{ color: theme.palette.success.dark }} />
                                {
                                    !authContext.moduleAccess(APP_MODULE_SalesOrders).allowAdd &&
                                    <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                                }
                            </ListItem>
                        }
                        {

                            <ListItem path="newdeliverychallan" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                                disabled={!authContext.moduleAccess(APP_MODULE_DeliveryChallans).allowAdd}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatarAddIconSuccess}>
                                        <AppIcon name="DeliveryChallan"></AppIcon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Delivery challan" disableTypography style={{ color: theme.palette.success.dark }} />
                                {
                                    !authContext.moduleAccess(APP_MODULE_DeliveryChallans).allowAdd &&
                                    <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                                }
                            </ListItem>
                        }

                        <ListItem path="newcreditnote" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_CreditNotes).allowAdd}>

                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSuccess}>
                                    <AppIcon name="CreditNote"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Credit note/Sales returns" disableTypography style={{ color: theme.palette.success.dark }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_CreditNotes).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        {

                            <ListItem path="receiveadvance" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                                disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatarAddIconSuccess}>
                                        <AppIcon name="RupeeIcon"></AppIcon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Receive advance payment" style={{ color: theme.palette.success.dark }} />
                                {
                                    !authContext.moduleAccess(APP_MODULE_Receivables).allowAdd &&
                                    <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                                }
                            </ListItem>
                        }
                        {

                            <ListItem path="selectparty/Customer" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                                disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatarAddIconSuccess}>
                                        <AppIcon name="RupeeIcon"></AppIcon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Receive incoming payment" secondary={<span className={classes.mutedTextList}>(Shortcut Alt + I)</span>} style={{ color: theme.palette.success.dark }} />
                                {
                                    !authContext.moduleAccess(APP_MODULE_Receivables).allowAdd &&
                                    <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                                }
                            </ListItem>
                        }
                    </List>
                </Grid>

                <Grid item xs={12} sm={4}>

                    <List component="nav" dense={true} style={{ border: '1px solid lightblue', borderRadius: '5px', margin: '5px 5px' }}>

                        <ListItem path="newpurchasebill" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Bills).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSecondary}>
                                    <AppIcon name="PurchaseIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText primary="Purchase bill" secondary={<span className={classes.mutedTextList}>(Shortcut Alt + B)</span>}
                                style={{ color: theme.palette.secondary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Bills).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="newpurchaseorder" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_PurchaseOrders).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSecondary}>
                                    <AppIcon name="PurchaseOrderIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText primary="Purchase order" disableTypography
                                style={{ color: theme.palette.secondary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_PurchaseOrders).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="newdebitnote" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_DebitNotes).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSecondary}>
                                    <AppIcon name="DebitNote"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Debit note/Purchase returns" disableTypography
                                style={{ color: theme.palette.secondary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_DebitNotes).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="expense" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Expenses).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSecondary}>
                                    <AppIcon name="Expense"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Expense" secondary={<span className={classes.mutedTextList}>(Shortcut Alt + X)</span>}
                                style={{ color: theme.palette.secondary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Expenses).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="payadvance" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Payables).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSecondary}>
                                    <AppIcon name="RupeeIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Make advance payment" disableTypography
                                style={{ color: theme.palette.secondary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Payables).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="selectparty/Supplier" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Payables).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIconSecondary}>
                                    <AppIcon name="RupeeIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Make outgoing payment" secondary={<span className={classes.mutedTextList}>(Shortcut Alt + O)</span>}
                                style={{ color: theme.palette.secondary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Payables).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>
                    </List>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <List component="nav" dense={true} style={{ border: '1px solid lightsteelblue', borderRadius: '5px', margin: '5px 5px' }}>
                        <ListItem path="product" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Items).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="ProductsIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Item/Product" style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Items).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="productgroup" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_ItemGroup).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="ItemGroup"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Item group" disableTypography style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_ItemGroup).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="compositeItem" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_CompositeItem).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="CompositeItem"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Composite Item" disableTypography style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_CompositeItem).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="customer" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Customers).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="CustomerIcon"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Customer" disableTypography style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Customers).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="supplier" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_Suppliers).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="Supplier"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Vendor/Supplier" disableTypography style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_Suppliers).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="customergroup" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_CustomerGroups).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="CustomerGroup"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Customer group" disableTypography style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_CustomerGroups).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                        <ListItem path="bankaccount" onClick={menuHeadingClickHandler} button disableGutters size="small" dense={true} className={classes.quickAddMenu}
                            disabled={!authContext.moduleAccess(APP_MODULE_BankAccounts).allowAdd}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarAddIcon}>
                                    <AppIcon name="Bank"></AppIcon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Bank account" disableTypography style={{ color: theme.palette.primary.main }} />
                            {
                                !authContext.moduleAccess(APP_MODULE_BankAccounts).allowAdd &&
                                <AppIcon name="Lock" color="error" size="small" title="You have no access to this menu."></AppIcon>
                            }
                        </ListItem>

                    </List>
                </Grid>
            </Grid>
        </div>
    )
};
