import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import useStore from "../../Base/Store";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppIcon from "../../UI/Controls/AppIcons";
import useStyles from '../../../styles';
import { safeBool } from '../../Base/Utils';

export default function DialogWindow(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const currentDialog = useStore(state => state.currentDialog);
    const closeDialog = useStore(state => state.closeDialog);    

    return (

        <Dialog fullScreen={fullScreen} open={props.open && currentDialog === props.name} fullWidth={true} maxWidth={props.width}
            TransitionProps={{ onExited: props.onExited }}
            className={`${classes.dialogRoot} ${props.compact ? classes.dialogCompact : ''}`}
            classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollBody: classes.topPaperScrollBody,
            }}
            PaperProps={{
                style: {
                    backgroundColor: safeBool(props.dark) ? 'rgba(50,54,57,1)' : 'white',
                },
            }}
        >
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <Tooltip title="Click to close dialog">
                    <IconButton onClick={() => closeDialog(false, '')} className={classes.noSpacing} tabIndex={-1}>
                        <AppIcon name="Clear" style={{ cursor: 'pointer', margin: '5px', color: '#F44336' }} size="medium" />
                    </IconButton>
                </Tooltip>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {props.component}
            </DialogContent>
            {/* <DialogActions classes={{ root: classes.leftAlignDialogActions }}>
                <Button color="primary" variant="contained" size="small">{props.okButtonCaption}Save</Button>
                <Button color="primary" autoFocus variant="outlined" size="small">{props.cancelButtonCaption}Cancel</Button>
            </DialogActions> */}
        </Dialog>


    )

};
