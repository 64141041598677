import { ERROR_INTERNAL_SERVER } from "../Base/Common";
import { getFinancialEndDate, getFinancialStartDate, getFiscalYearDatesById, safeInt, safeString, safeBool, isNullOrUndefined } from "../Base/Utils";
import WebAPI from "../Base/WebAPI";


export const getSubscriberRequest = (subscriberId, addressId, additionalDetails, values) => {
    return {
        "id": safeInt(subscriberId),
        "shortName": values.shortName,
        "taxRegistrationTypeId": safeInt(values.taxRegistrationTypeId),
        "businessTypeId": safeInt(values.businessTypeId),
        "legalName": values.legalName,
        "taxIdNumber": safeString(values.taxIdNumber).toUpperCase(),
        "fiscalStartDate": values.fiscalStartDate,
        "fiscalEndDate": values.fiscalEndDate,
        "logo": "",
        "currencyCode": safeString(values.currencyCode),
        "additionalDetails": JSON.stringify(additionalDetails),
        "sourceId": safeInt(values.sourceId),
        "isPrimary": safeBool(values.isPrimary),
        "addressList": [
            {
                "id": safeInt(addressId),
                "description": "",
                "addressLine1": values.addressLine1,
                "addressLine2": values.addressLine2,
                "addressType": "Billing",
                "isPrimary": true,
                "stateId": safeInt(values.stateId),
                "city": values.city,
                "pinCode": values.pinCode,
                "contactPerson": "",
                "contactEmail": values.contactEmail,
                "contactPhone": values.contactPhone,
                "website": values.website,
                "active": true
            }
        ]
    }
}

export const getAdditionalDetails = (values) => {
    var additionalDetails = [];
    additionalDetails.push({
        fieldName: 'drugLicense20B',
        fieldValue: values.drugLicense20B
    });
    additionalDetails.push({
        fieldName: 'drugLicense21B',
        fieldValue: values.drugLicense21B
    });
    additionalDetails.push({
        fieldName: 'fssaiLicense',
        fieldValue: values.fssaiLicense
    });
    additionalDetails.push({
        fieldName: 'msmeCertificateNumber',
        fieldValue: values.msmeCertificateNumber
    });
    additionalDetails.push({
        fieldName: 'importExportCode',
        fieldValue: values.importExportCode
    });
    additionalDetails.push({
        fieldName: 'tanNumber',
        fieldValue: values.tanNumber
    });
    additionalDetails.push({
        fieldName: 'CertificateOne',
        fieldValue: values.CertificateOne
    });
    additionalDetails.push({
        fieldName: 'CertificateTwo',
        fieldValue: values.CertificateTwo
    });
    additionalDetails.push({
        fieldName: 'lutNumber',
        fieldValue: values.lutNumber
    });
    additionalDetails.push({
        fieldName: 'lutStartDate',
        fieldValue: values.lutStartDate
    });
    additionalDetails.push({
        fieldName: 'lutEndDate',
        fieldValue: values.lutEndDate
    });
    return additionalDetails;
}

export const saveSubscriber = async (subscriberId, addressId, values, authContext, setWaiting, showAlert, fiscalYears, companyMode = false) => {
    try {
        setWaiting(true);
        var apiUrl = '/Subscriber/UpdateSubscriber';
        if (subscriberId === 0) {
            apiUrl = '/Subscriber/CreateSubscriber';
        }
        var additionalDetails = getAdditionalDetails(values);
        const request = getSubscriberRequest(subscriberId, addressId, additionalDetails, values);
        var fiscalYearDates = {
            startDate: getFinancialStartDate(),
            endDate: getFinancialEndDate()
        }
        if (safeInt(values?.FinancialYear) > 0) {
            fiscalYearDates = getFiscalYearDatesById(values?.FinancialYear, fiscalYears);
        }
        request.fiscalEndDate = fiscalYearDates.endDate;
        request.fiscalStartDate = fiscalYearDates.startDate;

        if (companyMode) {
            let subscriptions = [];
            subscriptions.push(getActiveSubscription(authContext.currentSubscriber));
            request['subscriptions'] = subscriptions;
        }
        const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_INTERNAL_SERVER, 'error');
        return null;
    }
    finally {
        setWaiting(false);
    }
}

const getActiveSubscription = (subscriberDetails) => {
    try {
        const subscriptions = subscriberDetails.subscriptions;
        var activeSubscription = null;
        if (!isNullOrUndefined(subscriptions) && subscriptions.length > 0) {
            activeSubscription = subscriptions.find(s => (s.validSubscription === true));
            if (isNullOrUndefined(activeSubscription)) {
                // Return the current/last active subscription
                activeSubscription = subscriptions.find(s => (s.active === true));
            }
        }
        return activeSubscription;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const UpdateUserSubscriberLinkPrimary = async (subscriberId, authContext, setWaiting, showAlert, history) => {
    try {
        setWaiting(true);
        var apiUrl = "/Users/UpdateUserSubscriberLinkPrimary";
        const request = {
            "userId": safeInt(authContext.currentUser.id),
            "subscriberId": safeInt(subscriberId),
            "isPrimary": true,
            "active": true
        }
        const response = await WebAPI.post(apiUrl, request, { headers: { "AccessToken": authContext.token } });
        if (!isNullOrUndefined(response.data) && safeInt(response.data.code) > 0) {
            showAlert(response.data.message, 'error');
        } else {
            if (!isNullOrUndefined(response.data)) {
                showAlert('Company Change successfully.');
                authContext.setSubscriberSessionData(response);
                authContext.updateContextState();
                history.replace('/home');
                history.push('/dashboard');
            } else {
                showAlert(response.data.message, 'error');
            }
        }
    } catch (error) {
        console.log("delete ", error);
    } finally {
        setWaiting(false);
    }
}


export const getCompanyList = async (token, authContext) => {
    try {
        const response = await WebAPI.get('/Users/GetUserSubscriberLinkList', {
            headers: { "AccessToken": authContext.token },
            cancelToken: token,
        });
        return response;
    } catch (error) {
        console.error('Error fetching company list:', error);
        throw error;
    }
};