import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useOnlineStatus from './useOnlineStatus';

const OnlineStatusIndicator = () => {
    const { isOnline, wasOffline } = useOnlineStatus();
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isOnline !== null) {
            if (!isOnline) {
                setNotification({
                    message: `😢 You're Offline! Please check your Internet Connection.`,
                    type: 'error',
                });
                setOpen(true);
            } else if (wasOffline) {
                setNotification({
                    message: `😊 You're Back Online! Connected to Internet.`,
                    type: 'success',
                });
                setOpen(true);
            }
        }
    }, [isOnline, wasOffline]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            autoHideDuration={9000}
            onClose={handleClose}            
        >
            <Alert onClose={handleClose} severity={notification.type} variant="filled">
                {notification.message}
            </Alert>
        </Snackbar>
    );
};

export default OnlineStatusIndicator;
