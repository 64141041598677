import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import shallow from "zustand/shallow";
import useStyles from "../../../styles";
import AuthContext from "../../Auth/AuthContext";
import useStore from "../../Base/Store";
import { formatToCurency, getLocaleDate, safeInt } from "../../Base/Utils";
import { isFreeTrial, verifySubscription } from "../../Shared/OrderHelper";
import AppIcon from "../../UI/Controls/AppIcons";
import { PageHeader } from "../../UI/Controls/Controls";


export default function Subscription({ isModal = false }) {
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [razorScriptLoaded, setRazorScriptLoaded] = useStore(state => [state.razorScriptLoaded, state.setRazorScriptLoaded], shallow);
    const authContext = useContext(AuthContext);

    const subscription = authContext.currentSubscriber.subscriptions[0];
    const isValidSubscription = verifySubscription(authContext.currentSubscriber);

    const classes = useStyles();
    const history = useHistory();
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);


    const loadScript = (src) => {
        if (!razorScriptLoaded) {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = () => {
                    setRazorScriptLoaded(true);
                    resolve(true);
                };
                script.onerror = () => {
                    setRazorScriptLoaded(false);
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        }
    };

    useEffect(() => {
        if (!razorScriptLoaded) {
            loadScript("https://checkout.razorpay.com/v1/checkout.js");
        }
    });

    return (
        <>
            <PageHeader heading="Subscription details">
                <AppIcon name="Subscription" color="primary" />
            </PageHeader>
            <div className={classes.page}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TableContainer>
                            <Table aria-label="Current subscription details">
                                {!isValidSubscription &&
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: 'red', fontWeight: 'bold' }} colSpan={2} component="th" scope="row">{"Your subscription has expired!"}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                }
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Package name:</TableCell>
                                        <TableCell align="left">{subscription.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Package description:</TableCell>
                                        <TableCell align="left">{subscription.description}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold', color: 'navy' }} >Start date:</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold', color: 'navy' }} >{getLocaleDate(subscription.startDate)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" style={{ fontWeight: 'bold', color: `${isValidSubscription ? 'navy' : 'red'}` }} scope="row">End date:</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold', color: `${isValidSubscription ? 'navy' : 'red'}` }} >{getLocaleDate(subscription.endDate)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold', color: 'green' }}>Amount paid:</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold', color: 'green' }}>{formatToCurency(subscription.amountPaid)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">No of users:</TableCell>
                                        <TableCell align="left">{safeInt(subscription.numberOfUsers)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item container xs={12} sm={12}>
                        {
                            <>
                                <Button disabled={waiting} variant="contained" color="primary" className={classes.successButton}
                                    onClick={() => {
                                        let upgradePath = isFreeTrial(authContext.currentSubscriber) ? '/packages' : 'renewSubscription';
                                        history.push(upgradePath);
                                    }
                                    } size="large" >
                                    Upgrade now!
                                </Button>
                            </>
                        }
                        <Button variant="outlined" size="large" color="primary" className={classes.ml1} onClick={() => isModal ? closeDialog() : history.goBack()}>{isModal ? 'Cancel' : 'Back'}</Button>
                    </Grid>
                </Grid>
            </div >
        </>
    );
};
