import { ButtonBase, List, ListItem, ListItemText, Hidden, Typography, Drawer, Collapse, useMediaQuery, Button, Box, Avatar, Tooltip, IconButton, Zoom } from "@material-ui/core";
import useNavStyles from "./Styles/NavStyles";
import { useTheme } from "@material-ui/core";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Fab from '@material-ui/core/Fab';
import { Fragment } from "react";

import AppIcon from "../Controls/AppIcons";
import AuthContext from "../../Auth/AuthContext";
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import DialogWindow from "../Controls/DialogWindow";
import QuickAdd from "../../Shared/QuickAdd";
import { isFreeTrial, validityInDays, verifySubscription } from "../../Shared/OrderHelper";
import { APP_MODULE_Dashboard, APP_MODULE_Invoices, APP_MODULE_Receivables, APP_MODULE_Reports } from "../../Base/Common";
import { getFiscalYearDatesById, getFiscalYearId, safeInt, safeString } from "../../Base/Utils";
import SettingsIcon from '@material-ui/icons/Settings';
import './style.css';
import QuickInfoDrawer from "../Controls/QuickInfoDrawer";
import ApplicationSettings from "../../User/Settings/ApplicationSettings";
import FiscalYearObject from '../../User/FiscalYears.json';
import BottomDrawer from "../BottomDialog";
import QuickAddMobile from "../../Shared/QuickAddMobile";

export default function SideDrawer(props) {
    const authContext = useContext(AuthContext);
    const [navValue, setNavValue] = useState('dashboard');
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const [isMobileDrawerOpen, toggleMobileDrawer] = useStore(state => [state.isMobileDrawerOpen, state.toggleMobileDrawer], shallow);
    const [validityExpiringInDays, setValidityExpiringInDays] = useState(0);
    const [validSubsciption, setValidSubsciption] = useState(0);
    const history = useHistory();
    const { fiscalYears } = FiscalYearObject;
    const { window } = props;
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useNavStyles();
    const dashboardAccess = authContext.moduleAccess(APP_MODULE_Dashboard);
    const receivablesAccess = authContext.moduleAccess(APP_MODULE_Receivables);
    const reportsAccess = authContext.moduleAccess(APP_MODULE_Reports);
    const invoiceAccess = authContext.moduleAccess(APP_MODULE_Invoices);
    const showAlert = useStore(state => state.showAlert);
    const showSalesOffer = useStore(state => state.showSalesOffer);
    const [isSettingsDrawerOpen, toggleSettingsDrawer] = useStore(state => [state.isSettingsDrawerOpen, state.toggleSettingsDrawer], shallow);
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };
    const [isInvoiceFullScreenToggle, toggleInvoiceScreen] = useStore(state => [state.isInvoiceFullScreenToggle, state.toggleInvoiceScreen], shallow);

    var denseMode = mobileDevice ? false : true;

    const container = window !== undefined ? () => window().document.body : undefined;

    const [menuList, setMenuList] = useState([]);
    const [fydescription, setFydescription] = useState(null);

    const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setIsBottomDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsBottomDrawerOpen(false);
    };

    const isPathChangeCloseDialog = () => {
        setIsBottomDrawerOpen(false);
    }

    const handleBottomNavChange = (event, newValue) => {
        setNavValue(newValue);
    };

    function navMenuClickHandler(e) {
        history.push(e.currentTarget.attributes.path.value);
        var menus = [...menuList];
        var selectedMenuId = e.currentTarget.id;
        for (let index = 0; index < menus.length; index++) {
            var menu = menus[index];
            if (menu.id.toString() === selectedMenuId) {
                menu.isSelected = true;
            } else {
                if (!!menu.items && menu.items.length > 0) {
                    for (let index = 0; index < menu.items.length; index++) {
                        const menuItem = menu.items[index];
                        if (menuItem.id.toString() === selectedMenuId) {
                            menuItem.isSelected = true;
                        } else {
                            menuItem.isSelected = false;
                        }
                    }
                } else {
                    menu.isSelected = false;
                }
            }
        }
        setMenuList(menus);
        toggleMobileDrawer(false);
    }

    function menuHeadingClickHandler(e) {
        var menus = [...menuList];
        for (let index = 0; index < menus.length; index++) {
            if (menus[index].id.toString() === e.currentTarget.id) {
                menus[index].isExpanded = !menus[index].isExpanded;
            } else {
                menus[index].isExpanded = false;
            }
        }
        setMenuList(menus);
    }

    useEffect(() => {
        var appModules = authContext.appModules;
        var menus = appModules.filter((module) => {
            return module.parentId === 0 && module.allowView === true;
        });

        for (let index = 0; index < menus.length; index++) {
            menus[index]['isExpanded'] = false;
            menus[index]['items'] = appModules.filter((appModule) => {
                return appModule.parentId === menus[index].appModuleId && appModule.allowView === true;
            });
        }
        setMenuList(menus);

        let fiscalYearDateId = getFiscalYearId(authContext.currentSubscriber.fiscalStartDate.substring(0, 10), authContext.currentSubscriber.fiscalEndDate.substring(0, 10), fiscalYears);
        if (safeInt(fiscalYearDateId) > 0) {
            let fyDesc = getFiscalYearDatesById(fiscalYearDateId, fiscalYears);
            setFydescription(fyDesc.description)
        }

        let validityRemaining = validityInDays(authContext.currentSubscriber)
        setValidityExpiringInDays(validityRemaining);
        setValidSubsciption(verifySubscription(authContext.currentSubscriber));
        if (safeInt(validityRemaining) !== 0 && safeInt(validityRemaining) <= 10) {
            let upgradePath = isFreeTrial(authContext.currentSubscriber) ? '/packages' : 'renewSubscription';
            showAlert("Subscription expiring in " + validityRemaining + " days", 'error', 'top', 9000, `${upgradePath}`, 'Upgrade now!');
        }
    }, []);

    const handleSettingsDrawerToggle = () => {
        toggleSettingsDrawer(!isSettingsDrawerOpen);
    };


    const menuitems = (
        <div>
            <div className={classes.marginForAppBar} />
            <List component="nav" dense={denseMode}>
                {menuList.map((menu, index) => {
                    if (menu.items.length > 0) {
                        return (
                            <Fragment key={menu.id}>
                                <ListItem className={classes.menuListContainer} dense={denseMode} key={menu.id} id={menu.id} button size="small" path={menu.pageUrl} onClick={menuHeadingClickHandler}
                                    /* style={{ borderTop: '0.1px solid gray' }} */>
                                    <Avatar className={`${classes.avatarSideBar} ${classes.mr1}`}>
                                        <AppIcon name={menu.iconName} size="small"></AppIcon>
                                    </Avatar>
                                    <ListItemText primary={menu.name} className={classes.listItemText} />
                                    {menu.isExpanded ? <AppIcon name="ExpandLess" size="medium" style={{ color: "#fff" }} /> : <AppIcon name="RightChevron" size="medium" style={{ color: "#fff" }} />}
                                </ListItem>
                                {
                                    menu.items.map((menuItem, i) => {
                                        return (
                                            <Collapse key={menuItem.id} in={menu.isExpanded} timeout="auto" unmountOnExit>
                                                <List dense={denseMode} key={menuItem.id} component='div' disablePadding className={classes.menuItems} >
                                                    <ListItem dense={denseMode} button path={menuItem.pageUrl} onClick={navMenuClickHandler} id={menuItem.id} className={`${menuItem.isSelected ? classes.selectedListItem : ''}`}
                                                        /* style={{ borderTop: '1px solid #C8E6C9' }} */>
                                                        <Avatar className={`${classes.iconSideBar} ${classes.mr1}`}>
                                                            <AppIcon name={menuItem.iconName} size="small"></AppIcon>
                                                        </Avatar>
                                                        <ListItemText primary={menuItem.name} className={classes.listItemText} />
                                                    </ListItem>
                                                </List>
                                            </Collapse>
                                        )
                                    })
                                }
                            </Fragment>
                        )
                    } else {
                        return (
                            <ListItem dense={denseMode} key={menu.id} id={menu.id} button size="small" className={classes.menuListContainer} selected={menu.isSelected}
                                path={menu.pageUrl} onClick={navMenuClickHandler} /* style={{ borderTop: '1px solid #C8E6C9' }} */>
                                <Avatar className={`${classes.avatarSideBar} ${classes.mr1}`}>
                                    <AppIcon name={menu.iconName} size="small"></AppIcon>
                                </Avatar>
                                <ListItemText primary={menu.name} className={classes.listItemText} />
                            </ListItem>
                        )
                    }
                }
                )}
            </List>
        </div>
    );

    const expiringWarning = (
        <Box textAlign='center' style={{ marginBottom: '72px' }}>
            <Button style={{ color: 'orangered', fontSize: '11px' }} variant="text" startIcon={<AppIcon name="Warning" size="small" />}
            >{"Subscription expiring in " + validityExpiringInDays + " days"}</Button>
            <Button className={classes.buyNowButton} size="small"
                onClick={() => {
                    let upgradePath = isFreeTrial(authContext.currentSubscriber) ? '/packages' : 'renewSubscription';
                    history.push(upgradePath);
                }}>
                Upgrade Now
            </Button>
            {
                showSalesOffer && isFreeTrial(authContext.currentSubscriber) &&
                <ButtonBase style={{ color: 'darkorange' }}
                    onClick={
                        () => {
                            navigator.clipboard.writeText('FREEDOM50');
                            showAlert('Coupon copied!');
                        }
                    }>
                    <p style={{ color: 'yellow' }}>FLAT 50% OFF! Use Coupon: FREEDOM50</p>
                </ButtonBase>
            }
        </Box>
    );

    const expiryWarning = (
        <Box textAlign='center' style={{ marginBottom: '72px' }}>
            <Button style={{ color: '#EA3A60' }} variant="text" startIcon={<AppIcon name="Warning" size="small" />}
                onClick={() => {
                    let upgradePath = isFreeTrial(authContext.currentSubscriber) ? '/packages' : 'renewSubscription';
                    history.push(upgradePath);
                }}
            >Subscription expired!</Button>
            <Button className={classes.buyNowButton} size="small"
                onClick={() => {
                    let upgradePath = isFreeTrial(authContext.currentSubscriber) ? '/packages' : 'renewSubscription';
                    history.push(upgradePath);
                }}
            >
                Upgrade Now
            </Button>
            {
                showSalesOffer && isFreeTrial(authContext.currentSubscriber) &&
                <ButtonBase style={{ color: 'darkorange' }}
                    onClick={
                        () => {
                            navigator.clipboard.writeText('FREEDOM50');
                            showAlert('Coupon copied!');
                        }
                    }>
                    <p style={{ color: 'yellow' }}>FLAT 50% OFF! Use Coupon: FREEDOM50</p>
                </ButtonBase>
            }

        </Box>
    );

    return (
        <>

            <nav className={classes.drawer} aria-label="side menu" style={{ display: isInvoiceFullScreenToggle ? "none" : "" }}>
                <Hidden smUp implementation="css">
                    <Drawer
                        {...props}
                        container={container}
                        variant="persistent"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {menuitems}
                        {!validSubsciption && expiryWarning}
                        {(validityExpiringInDays !== 0 && validityExpiringInDays <= 10 && validSubsciption) && expiringWarning}

                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open={isMobileDrawerOpen}
                    >
                        {menuitems}

                        {!validSubsciption && expiryWarning}
                        {(validityExpiringInDays !== 0 && validityExpiringInDays <= 10 && validSubsciption) && expiringWarning}
                        {
                            <>
                                <Box textAlign='center' style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'fixed',
                                    bottom: 20,
                                    padding: '7px 5px',
                                    left: 0,
                                    zIndex: 100000,
                                    width: '210px',
                                    background: '#232F3E',
                                    // borderTop: '0.1px solid steelblue',
                                    borderRight: '1px solid #232F3E'
                                }} >
                                    <Typography variant="caption" color="secondary" style={{ color: 'lightsteelblue' }} >
                                        User: <strong>{authContext.currentUser.firstName}</strong>
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '210px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        position: 'fixed',
                                        bottom: 0,
                                        left: 0,
                                        padding: '4px 5px',
                                        zIndex: 100000,
                                        color: '#00ff99',
                                        background: '#232F3E',
                                        fontWeight: 'bold',
                                        borderTop: '0.1px solid lightsteelblue',
                                        borderRight: '1px solid #232F3E'
                                    }}
                                >
                                    <Typography variant="caption" style={{ marginBottom: '-5px' }}>
                                        <strong>{safeString(fydescription)}</strong>
                                    </Typography>
                                    <IconButton className="OnboardingSettingIconSidebar" onClick={handleSettingsDrawerToggle} aria-label="Manage application settings" title="Manage application settings">
                                        <SettingsIcon fontSize="small" style={{ color: "#fff" }} /><Typography variant="body2" style={{ color: '#ffffff' }}> Settings </Typography>
                                    </IconButton>
                                </Box>
                            </>
                        }
                    </Drawer>
                </Hidden>
            </nav>
            {
                mobileDevice ? <>
                    <Zoom
                        in={true}
                        timeout={transitionDuration}
                        unmountOnExit
                        delay
                        style={{
                            transitionDelay: `1000ms`
                        }}
                    >
                        <Tooltip title="Click to add new item. Shortcut (Alt + A)">
                            <Fab className={`${classes.addActionMobile}`} onClick={(e) => handleDrawerOpen()} variant={"circular"}>
                                <AppIcon name="Add" size="medium" />
                            </Fab>
                        </Tooltip>
                    </Zoom>

                </> : null
            }

            <BottomNavigation value={navValue} onChange={handleBottomNavChange} showLabels className={`${classes.bottomNavbar} ${classes.mobileOnly}`} style={mobileDevice ? { paddingBottom: '45px' } : {}}>
                <BottomNavigationAction label="Dashboard" value="dashboard" icon={<AppIcon name="DashboardIcon" size="lg" />}
                    disabled={!dashboardAccess.allowView}
                    onClick={() => history.push("/dashboard")} style={{ color: 'white' }} />
                {/*   <BottomNavigationAction label="Customers" value="customers" icon={<AppIcon name="CustomersIcon" size="lg" />}
                    disabled={!customerAccess.allowView}
                    onClick={() => history.push("/customers")} style={{ color: 'white' }} /> */}
                <BottomNavigationAction label="Invoices" value="invoices" icon={<AppIcon name="InvoiceIcon" size="lg" />}
                    disabled={!invoiceAccess.allowView}
                    onClick={() => history.push("/invoices")} style={{ color: 'white' }} />
                <BottomNavigationAction label="Receivables" value="receivables" icon={<AppIcon name="RupeeIcon" size="lg" />}
                    disabled={!receivablesAccess.allowView}
                    onClick={() => history.push("/receivables")} style={{ color: 'white' }} />
                <BottomNavigationAction label="Reports" value="reports" icon={<AppIcon name="ReportsIcon" size="lg" />}
                    disabled={!reportsAccess.allowView}
                    onClick={() => history.push("/reports")} style={{ color: 'white' }} />
            </BottomNavigation>

            <DialogWindow name="quickAddDialog" open={isDialogOpen}
                width="md" component={<QuickAdd />}
                onExited={e => toggleMobileDrawer(false)} />

            <BottomDrawer open={isBottomDrawerOpen} onClose={handleDrawerClose}
                width="md" component={<QuickAddMobile isPathChangeCloseDialog={isPathChangeCloseDialog} />}
            />

            <QuickInfoDrawer isFullWidth={true} isInfoDrawerOpen={isSettingsDrawerOpen}
                toggleDrawerOpen={handleSettingsDrawerToggle}
                title={'Settings'} content={<ApplicationSettings close={handleSettingsDrawerToggle} />} />
        </>
    )
};
