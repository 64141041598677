import { INVENTORY_ENTITY } from "../Base/Common";
import { getDataList } from "../Base/DataProvider";
import { isNullOrUndefined, safeInt } from "../Base/Utils";
import WebAPI from "../Base/WebAPI";

export const getWarehouseWiseInventory = async (productId, setWaiting, authContext, showAlert, token = null) => {
    try {
        setWaiting(true);
        const request = {
            productId: safeInt(productId),
            warehouseId: 0
        }
        const response = await getDataList(INVENTORY_ENTITY, "InventoryWarehouseWiseList", request, authContext, token, 'put')
        if (!isNullOrUndefined(response) && safeInt(response.code > 0)) {
            showAlert(response.message, 'error');
        } else {
            return response;            
        }

    } catch (error) {
        if (WebAPI.isCancel(error) === true) {
            console.log('Warehouse-wise Inventory list : Request cancelled.');
        } else {
            showAlert('Failed to load Warehouse-wise Inventory list. Internal sever error occured.', 'error');
        }
        return null;
    } finally {
        setWaiting(false);
    }
}

export const getWarehouseList = async (setWaiting, authContext, showAlert, token = null) => {
    try {
        setWaiting(true);
        var response = await WebAPI.get('/Warehouse/GetWarehouseList/' + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: token });
        if (!!response.data) {
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                return response.data;
            }
        }
        return null;
    } catch (error) {
        if (WebAPI.isCancel(error) === true) {
            console.log('Warehouse list : Request cancelled.');
        } else {
            showAlert('Failed to load warehouse list. Internal sever error occured.', 'error');
        }
        return null;
    } finally {
        setWaiting(false);
    }
}