import { Popover } from "@material-ui/core";

export default function Popup({ key, element, handleClose, component }) {
    return (
        <Popover
            id={key}
            open={Boolean(element)}
            anchorEl={element}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}            
        >
            {component}
        </Popover>

    )
};
