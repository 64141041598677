import { Button, Grid } from "@material-ui/core";
import useStyles from "../../../styles";
import { isNullOrUndefined } from "../../Base/Utils";
import AppIcon from "./AppIcons";

export default function ImageUpload({ setWaiting, setFieldValue, showAlert, imageFieldName, imageFieldPreview, values, uploadImage, altText= 'Image display' }) {
    const classes = useStyles();

    return (
        <>
            <Grid item container alignContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <input accept="image/*" name={imageFieldName}
                        value={undefined}
                        onChange={(e, value) => {
                            try {
                                setWaiting(true);
                                if (!e.target.files || e.target.files.length === 0) {
                                    setFieldValue(imageFieldName, undefined);
                                    return
                                }
                                var reader = new FileReader();
                                var file = e.currentTarget.files[0];
                                if (file.size > 1000000) {
                                    showAlert('Failed to upload image. Please verify the image file size. (Max image size allowed: 1MB)', 'error');
                                } else {
                                    reader.readAsDataURL(file);

                                    reader.onload = function () {
                                        setWaiting(false);
                                        setFieldValue(imageFieldName, reader.result);
                                        setFieldValue(imageFieldPreview, reader.result);
                                    };
                                    reader.onerror = function (error) {
                                        setWaiting(false);
                                        showAlert('Failed to load image. Please verify the image format. (Accepted formats: JPEG, PNG)', 'error');
                                        console.log('Error: ', error);
                                    };
                                }
                            } catch (error) {
                                setWaiting(false);
                            } finally {
                                setWaiting(false);
                            }
                        }}
                        className={classes.uploadFileInput}
                        id="fileUploadButton" type="file" />
                    <label htmlFor="fileUploadButton">
                        <Button variant="contained" color="secondary" aria-label="upload picture" component="span"
                            startIcon={<AppIcon name="BrowseFolder" />}>
                            Browse Image...
                        </Button>
                    </label>
                    <p style={{ color: 'blue', fontStyle: 'italic' }}>Max image size allowed: 1MB</p>
                </Grid>
                <Grid item xs={12}>
                    <img className={`${classes.m1} ${classes.imageFit}`} name={imageFieldPreview}
                        src={isNullOrUndefined(values[imageFieldPreview]) ? '' : values[imageFieldPreview]} alt={altText} width={'50%'} height={'50%'} />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                    <Button variant='contained' color='secondary' fullWidth
                        onClick={async () => await uploadImage(values)}
                        startIcon={<AppIcon name="Upload" size="small" />}
                    >Upload</Button>
                </Grid> */}
            </Grid>
        </>
    )
};
