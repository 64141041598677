import { Button, Grid } from "@material-ui/core";
import axios from "axios";
import { Form, Formik } from "formik";
import { useConfirm } from "material-ui-confirm";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import shallow from "zustand/shallow";
import useStyles from "../../../../styles";
import AuthContext from "../../../Auth/AuthContext";
import { APP_MODULE_Bills, APP_MODULE_Invoices, DELIVERY_CHALLAN_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY } from "../../../Base/Common";
import { getData } from "../../../Base/DataProvider";
import useStore from "../../../Base/Store";
import { addDays, getConfirmOptions, getDatePart, getEmptyActionDefinition, getISODate, getISODateTime, getMinDate, isNullOrUndefined, lowerCaseFirstLetter, preventFormSubmit, safeBool, safeDate, safeFloat, safeInt, safeString } from "../../../Base/Utils";
import WebAPI from "../../../Base/WebAPI";
import AppIcon from "../../../UI/Controls/AppIcons";
import { AdaptiveButton, LockIcon, PageHeader } from "../../../UI/Controls/Controls";
import DialogWindow from "../../../UI/Controls/DialogWindow";
import { DateInput, TextInput, SelectInput } from "../../../UI/Controls/InputFields";
import MuiTable from "../../../UI/Controls/MuiTable";
import Subscription from "../../../User/Subscription/Subscription";
import Item from "../../Item";
import { getColumnDefinition, getColumnDefinitionSummary, getOrderItems, setNextSequenceNumber, deleteItem, reCalculateOrderTotals, getOrderSummary, handleDeleteDiscount, editItem, addItem, verifySubscription, getPurchaseBillRequest, getInvoiceRequest, getDeliveryChallanRequest } from "../../OrderHelper";
import OrderDiscount from "../OrderDiscount";
import * as yup from 'yup';

export default function OrderSummary({ fromOrderType, toOrderType, orderId, referenceType, heading, orderType, icon }) {

    const pageHeading = heading;
    const orderTypeId = orderType;
    const pageIcon = icon;

    const authContext = useContext(AuthContext);
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode)
    const isPharmacy = authContext.settings.isPharmacy;
    const taxInclusivePricing = safeBool(authContext.settings.taxInclusivePricing);
    const decimalPlaces = safeInt(authContext.settings.decimalPlaces);
    const enableCess = safeBool(authContext.settings.enableCess);

    var orderSummaryColumnDef = getColumnDefinitionSummary(currencyCode);
    const orderColumnDef = getColumnDefinition(true, false, true, currencyCode, safeBool(authContext.settings.allowFreeQuantity), safeBool(authContext.settings.includeReferencePrice), isPharmacy, taxInclusivePricing, decimalPlaces, enableCess);
    const classes = useStyles();
    const history = useHistory();
    const showAlert = useStore(state => state.showAlert);
    const confirm = useConfirm();
    const formRef = useRef();
    const isMultiWarehouse = safeBool(authContext.settings.isMultiWarehouse);

    //Dialog related
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const [dialogResponse, setDialogResponse] = useStore(state => [state.dialogResponse, state.setDialogResponse], shallow);

    //State    
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [order, setOrder] = useState(null);
    const [orderItems, setOrderItems] = useState([]);
    const [orderSummary, setOrderSummary] = useState([]);
    const [isOrderChanged, setIsOrderChanged] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(0);
    const [orderDiscounted, setOrderDiscounted] = useState(false);
    const [orderItemDiscounted, setOrderItemDiscounted] = useState(false);
    const [canExecute, setCanExecute] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);


    const [fieldValues, setFieldValues] = useState({
        "id": 0,
        "orderNumber": "",
        "orderSequence": 0,
        "orderDate": getISODate(),
        "orderReference": "",
        "termsAndConditions": "",
        "statusId": 2,
        "orderTotal": 0,
        "taxAmount": 0,
        "grandTotal": 0,
        "roundOffAmount": 0,
        "isIGST": false,
        "referenceId": 0,
        "referenceType": "",
        "referenceName": "",
        "outstandingAmount": 0,
        "isCredit": true,
        "modeOfPayment": "",
        "poReference": "",
        "poDate": "",
        "isPaid": false,
        "transportDetails": "",
        "eWayBillNo": "",
        "deliveryDate": "",
        "paymentDueDate": getISODate(),
        "placeOfSupply": "",
        "active": true,
        "isManualOrderNumber": false,
        "useUnitConversion": false,
        "concurrencyLock": "",
        "isTaxInclusive": taxInclusivePricing,
        "warehouseId": 0,
        "warehouseName": "",
    });

    const handleKeyboardShortcut = async (e, values) => {
        if (!isDialogOpen) {
            if (e.altKey && (e.key === 's' || e.key === 'S')) {
                formRef.current.handleSubmit();
            }
        }
    }

    const handleDialogClosed = (dialogName, setFieldValue, values, setValues) => {
        try {
            if (dialogResponse != null) {
                switch (dialogName) {
                    case "supplierDialog":
                        // parties.push(dialogResponse);
                        // handlePartySelectionChange(setFieldValue, dialogResponse);
                        break;
                    case "itemDialog":
                        let updatedItems = null;
                        if (selectedItemId > 0) {
                            updatedItems = editItem(selectedItemId, dialogResponse, orderItems, values.isIGST);
                        } else {
                            updatedItems = addItem(dialogResponse, orderItems, values.isIGST);
                        }
                        setOrderItems(updatedItems);
                        let tempOrder = reCalculateOrderTotals(updatedItems, values, authContext.settings['roundOff' + toOrderType + 'Total']);
                        setValues(tempOrder);
                        setOrderSummary(getOrderSummary(tempOrder));
                        setDialogResponse(null);
                        setIsOrderChanged(true);
                        break;
                    case "discountDialog":
                        if (!isNullOrUndefined(dialogResponse)) {
                            if (safeFloat(dialogResponse.orderDiscountAmount) > 0) {
                                setFieldValue('orderDiscountPercent', safeFloat(dialogResponse.orderDiscountPercent));
                                setFieldValue('orderDiscountAmount', safeFloat(dialogResponse.orderDiscountAmount));
                            } else {
                                setFieldValue('orderDiscountPercent', 0);
                                setFieldValue('orderDiscountAmount', 0);
                            }
                            let tempOrderItems = getOrderItems(dialogResponse[lowerCaseFirstLetter(toOrderType) + 'Details'], true)
                            setOrderItems(tempOrderItems);

                            if (authContext.settings['roundOff' + toOrderType + 'Total'] === true) {
                                dialogResponse.grandTotalDisplay = Math.round(safeFloat(dialogResponse.grandTotal));
                            } else {
                                dialogResponse.grandTotalDisplay = safeFloat(dialogResponse.grandTotal);
                            }
                            let tempOrderSummary = getOrderSummary(dialogResponse);
                            setOrderSummary(tempOrderSummary);
                            setIsOrderChanged(true);
                        }
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDialogResponse(null);
        }

    }

    const handleDeleteAction = (e, row, values) => {
        confirm(getConfirmOptions('Delete item?', 'Are you sure, you want to delete this item?'))
            .then(() => {
                deleteOrderItem(row.id, values);
            }).catch(() => { });
    }

    const deleteOrderItem = (id, values) => {
        let updatedItems = deleteItem(id, orderItems);
        setOrderItems(updatedItems);
        let orderTemp = reCalculateOrderTotals(updatedItems, values, authContext.settings['roundOff' + toOrderType + 'Total']);
        setFieldValues(orderTemp);
        setOrderSummary(getOrderSummary(orderTemp));
        setIsOrderChanged(true);
    }

    const handleAddAction = (row) => {
        setSelectedItemId(0);
        openDialog('itemDialog');
    }

    const handleEditAction = (e, row) => {
        if (safeInt(row.id) > 0) {
            setSelectedItemId(row.id);
            openDialog('itemDialog');
        }
    }

    const fetchData = async (token) => {
        try {
            setWaiting(true);
            const response = await getData(fromOrderType, 'Get' + fromOrderType + 'ById', authContext, token, orderId);
            if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
                showAlert(response.error, 'error');
            } else {
                var tempOrder = { ...fieldValues };
                Object.getOwnPropertyNames(response).map(prop => {
                    if (prop.includes('Date')) {
                        tempOrder[prop] = getDatePart(response[prop], "");
                    } else {
                        tempOrder[prop] = response[prop];
                    }
                });
                if (tempOrder.poDate === getMinDate()) {
                    tempOrder.poDate = "";
                }
                if (toOrderType === INVOICE_ENTITY) {
                    // if (!isNullOrEmpty(tempOrder.orderReference)) {
                    //     tempOrder.orderReference = tempOrder.orderNumber + " | " + safeString(tempOrder.orderReference);
                    // } else {
                    tempOrder.orderReference = tempOrder.orderNumber
                    // }
                } else {
                    tempOrder.poReference = tempOrder.orderNumber;
                }
                tempOrder.orderDate = getISODate();
                tempOrder.orderNumber = "";
                if (authContext.settings['RoundOff' + toOrderType + 'Total'] === true) {
                    tempOrder.grandTotalDisplay = Math.round(safeFloat(tempOrder.grandTotal));
                } else {
                    tempOrder.grandTotalDisplay = safeFloat(tempOrder.grandTotal);
                }
                if (isMultiWarehouse) {
                    for (let i = 0; i < response[lowerCaseFirstLetter(fromOrderType) + 'Details'].length; i++) {
                        let orderItem = response[lowerCaseFirstLetter(fromOrderType) + 'Details'][i];
                        let warehouse = authContext.warehouseList.find(w => w.id === orderItem.warehouseId);
                        orderItem.warehouseName = warehouse?.name;
                    }
                }
                let tempOrderItems = [];
                tempOrderItems = getOrderItems(response[lowerCaseFirstLetter(fromOrderType) + 'Details'], true)
                let tempOrderSummary = getOrderSummary(tempOrder);

                const hasItemWithDiscount = orderItems.find(i => i.discountAmount > 0);
                if (!isNullOrUndefined(hasItemWithDiscount)) {
                    setOrderItemDiscounted(true);
                }
                setOrderDiscounted(safeFloat(tempOrder.orderDiscountAmount) > 0);

                const party = await getData(referenceType, 'Get' + referenceType + 'ById', authContext, token, tempOrder.referenceId);
                setSelectedParty(party);
                if (isNullOrUndefined(party)) {
                    showAlert('Could not load party details, please retry.', 'error');
                    return;
                } else {
                    var orderDate = new Date(tempOrder.orderDate);
                    var dueDate = addDays(orderDate, safeInt(party.creditDays));
                    tempOrder.paymentDueDate = getISODateTime(dueDate).slice(0, 10);
                }
                // if (!isNullOrUndefined(party.taxIdNumber) && party.taxIdNumber.length > 2) {
                //     tempOrder.placeOfSupply = party.taxIdNumber.substring(0, 2);
                // } 
                const shippingAddress = party.addressList.find(a => a.addressType === 'Shipping');
                if (isNullOrUndefined(shippingAddress)) {
                    const bilingAddress = party.addressList.find(a => a.addressType === 'Billing');
                    tempOrder.placeOfSupply = bilingAddress.stateId;
                } else {
                    tempOrder.placeOfSupply = shippingAddress.stateId;
                }
                setOrder(tempOrder);
                setFieldValues(tempOrder);
                setOrderItems(tempOrderItems);
                setOrderSummary(tempOrderSummary);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            if (!verifySubscription(authContext.currentSubscriber)) {
                openDialog('subscriptionDialog');
                return;
            }
            if (orderItems.findIndex(i => i.showBatchWarning === true) !== -1) {
                showAlert('There are items for which batch numbers are required. Please edit the item and add batch numbers.', 'error');
                return;
            }
            if (orderItems.findIndex(i => i.showSerialWarning === true) !== -1) {
                showAlert('There are items for which serial numbers are required. Please edit the item and add serial numbers.', 'error');
                return;
            }
            var request = {};
            var orderRequest = {}
            if (toOrderType === PURCHASE_BILL_ENTITY) {
                orderRequest = getPurchaseBillRequest(values, { id: safeInt(selectedParty.id), legalName: values.referenceName }, orderItems, true);
                request[lowerCaseFirstLetter(fromOrderType) + 'Id'] = safeInt(orderId);
                request.purchaseBill = orderRequest;
            } else if (toOrderType === DELIVERY_CHALLAN_ENTITY) {
                orderRequest = getDeliveryChallanRequest(values, { id: safeInt(selectedParty.id), legalName: values.referenceName }, orderItems, true);
                request[lowerCaseFirstLetter(fromOrderType) + 'Id'] = safeInt(orderId);
                request.deliveryChallan = orderRequest;
            }
            else {
                orderRequest = getInvoiceRequest(values, { id: safeInt(selectedParty.id), legalName: values.referenceName }, orderItems, true);
                request[lowerCaseFirstLetter(fromOrderType) + 'Id'] = safeInt(orderId);
                request.invoice = orderRequest;
            }

            if (safeInt(orderRequest.referenceId) === 0) {
                showAlert('Could not load party details, please retry.', 'error');
                return;
            } else {
                var apiVersion = "";
                if (toOrderType === PURCHASE_BILL_ENTITY || toOrderType === INVOICE_ENTITY) {
                    apiVersion = "2";
                }
                var response = await WebAPI.put('/' + fromOrderType + '/ConvertTo' + toOrderType + apiVersion, request, { headers: { "AccessToken": authContext.token } });
                if (!!response.data.code && response.data.code > 0) {
                    showAlert(response.data.message, 'error');
                } else {
                    showAlert(fromOrderType + ' converted successfully.', 'success');
                    history.push("/" + toOrderType.toLowerCase() + "/" + response.data.id);
                }
            }

        } catch (error) {
            console.log(error);
            showAlert(fromOrderType + ' convert failed. Internal server error.', 'error');
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        const moduleAccess = toOrderType === INVOICE_ENTITY ? authContext.moduleAccess(APP_MODULE_Invoices) : authContext.moduleAccess(APP_MODULE_Bills);
        setCanExecute(moduleAccess.allowAdd);
        async function init() {
            if (authContext.token != null) {
                setStateList(authContext.masterData.filter(md => md.type === 'State'));
                if (!verifySubscription(authContext.currentSubscriber)) {
                    openDialog('subscriptionDialog');
                }
                if (toOrderType === INVOICE_ENTITY) {
                    setNextSequenceNumber(toOrderType, authContext, fieldValues, setFieldValues);
                }
                await fetchData(source.token);
            }
        }
        init();
        return () => {
            source.cancel();
            setSelectedParty(null);
            setStateList(null);
            setFieldValues({});
        };
    }, []);

    const validate = yup.object({
        //orderNumber: yup.string().required('Please enter a value'),
        orderDate: yup.date().required('Please enter a value'),
        //paymentDueDate: yup.date().required('Please enter due date')
    });

    return (
        <>

            <Formik
                innerRef={formRef}
                initialValues={fieldValues}
                validationSchema={validate}
                enableReinitialize={true}
                onSubmit={async (values) => { await submitFormData(values) }}
            >
                {({ values, setValues, setFieldValue, isSubmitting }) => (
                    <>
                        <Form
                            onKeyDown={
                                async (e) => {
                                    preventFormSubmit(e);
                                    await handleKeyboardShortcut(e, values);
                                }
                            }
                        >
                            <Grid container alignContent="space-between" alignItems="center">
                                <Grid item xs={12}>
                                    <PageHeader heading={pageHeading} bodyText2={'Please review and click on save to confirm the conversion.'}>
                                        <AppIcon name={pageIcon} size="medium" color="primary" />
                                    </PageHeader>
                                </Grid>
                            </Grid>

                            <div className={classes.page}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <TextInput name="referenceName" label={referenceType} disabled={true} />
                                        <label className={classes.ml1}>{isNullOrUndefined(selectedParty) ? "" : safeString(selectedParty.taxIdNumber)}</label>
                                    </Grid>
                                    {
                                        toOrderType === PURCHASE_BILL_ENTITY &&
                                        <>
                                            <Grid item container xs={12} sm={9} spacing={2}>
                                                <Grid item xs={12} sm={3}>
                                                    <TextInput name="orderNumber" label="Bill no." required />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <DateInput name="orderDate" label={'Purchase date'} required />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <DateInput name="paymentDueDate" label={'Due date'} required />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <TextInput name="poReference" label="P.O. #" />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        toOrderType === DELIVERY_CHALLAN_ENTITY &&
                                        <>
                                            <Grid item xs={4} sm={2}>
                                                <DateInput name="orderDate" label="Challan date" required compact="true" />
                                            </Grid>
                                            <Grid item xs={8} sm={7}>
                                                <TextInput name="notes" label="Terms/Notes" placeholder="" />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        toOrderType === INVOICE_ENTITY &&
                                        <>
                                            <Grid item container xs={12} sm={9} spacing={2}>
                                                <Grid item xs={12} sm={3}>
                                                    <DateInput name="orderDate" label={'Invoice date'} required inputProps={{ autoFocus: true }} />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <DateInput name="paymentDueDate" label={'Due date'} required />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <DateInput name="deliveryDate" label={'Delivery date'} />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <TextInput name="orderReference" label="Reference" />
                                                </Grid>
                                            </Grid>
                                            <Grid item container spacing={2} xs={12}>

                                                <Grid item xs={12} sm={2}>
                                                    <TextInput name="poReference" label="P.O. #" />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <DateInput name="poDate" label="P.O. date" />
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <TextInput name="transportDetails" label="Transport details" />
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <TextInput name="termsAndConditions" label="Notes/Terms" />
                                                </Grid>
                                                <Grid item xs={6} sm={2}>
                                                    <SelectInput name="placeOfSupply" label="Place of supply" required>
                                                        <option key="0" value="" />
                                                        {
                                                            stateList.map((state, index) => {
                                                                return (
                                                                    <option key={safeInt(state.code)} value={safeInt(state.code)}>{state.description}</option>
                                                                )
                                                            })
                                                        }
                                                    </SelectInput>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item container xs={12} spacing={0} className={classes.itemToolBar}>
                                        <Grid container item className={classes.pullRight}>
                                            <AdaptiveButton style={{ marginRight: 8, color: (waiting || isSubmitting || isOrderChanged || (orderItemDiscounted && orderDiscounted)) ? "" : "#4CAF50", cursor: ((waiting || isSubmitting || isOrderChanged) ? 'not-allowed' : 'pointer') }}
                                                disabled={waiting || isSubmitting || isOrderChanged || (orderItemDiscounted && orderDiscounted)}
                                                caption="Add discount" captionsmall="Add discount" textvariant="true"
                                                iconbutton={<AppIcon name="Percentage" size="small" />}
                                                onClick={() => openDialog('discountDialog')}
                                                className={classes.noSpacing} />
                                            <AdaptiveButton style={{ marginRight: 8 }}
                                                caption="Add item" captionsmall="Add item" textvariant="true"
                                                iconbutton={<AppIcon name="AddCircle" />}
                                                onClick={handleAddAction}
                                                className={classes.noSpacing} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MuiTable columns={orderColumnDef} data={orderItems} actions={[
                                                rowData => ({
                                                    icon: () => <AppIcon name="Edit" color={(rowData.id === 0 || (rowData.id + "").substr(0, 4) === 'new_') ? "" : "primary"} />,
                                                    tooltip: (rowData.id === 0) ? null : 'Edit item',
                                                    onClick: handleEditAction,
                                                    disabled: (rowData.id === 0 || (rowData.id + "").substr(0, 4) === 'new_')
                                                }),
                                                {
                                                    icon: () => <AppIcon name="Delete" color={!!orderItems && orderItems.length === 1 ? "" : "error"} />,
                                                    tooltip: 'Delete item',
                                                    onClick: (e, row) => handleDeleteAction(e, row, values),
                                                    disabled: (!!orderItems && orderItems.length === 1) ? true : false
                                                }
                                            ]}
                                                rowClick={handleEditAction}
                                                toolbar={false} search={false} hideHeader={false} sorting={false}
                                                hasMobileView={true} />
                                            <MuiTable columns={orderSummaryColumnDef} data={orderSummary}
                                                actions={
                                                    getEmptyActionDefinition(2, true, async () => {
                                                        if (isOrderChanged) {
                                                            showAlert('Please click on update to save changes first.', 'error');
                                                        } else {
                                                            setIsOrderChanged(true);
                                                            await handleDeleteDiscount(toOrderType, orderTypeId, orderId, setFieldValue, setWaiting, setOrderItems, setOrderSummary, showAlert, authContext);
                                                        }
                                                    })
                                                }
                                                toolbar={false} search={false} hideHeader={true} sorting={false}
                                                hasMobileView={true} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={10} sm={8} className={`${classes.mt2} ${classes.mobileOnly}`} alignItems="center">
                                        <Button type="submit" disabled={isSubmitting || waiting || !canExecute}
                                            variant="contained" color="primary" endIcon={!canExecute && <LockIcon />}
                                            startIcon={<AppIcon name="Save" />}><span>Save<sub className={classes.mutedTextBtn}> (Alt + S)</sub></span></Button>
                                        <Button variant="outlined" className={classes.ml1}
                                            onClick={() => history.go(-1)}>Back</Button>
                                    </Grid>

                                </Grid>
                                <span className={`${classes.mt2} ${classes.submitBar} ${classes.desktopOnly}`}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button type="submit" disabled={isSubmitting || waiting || !canExecute}
                                                variant="contained" color="primary" endIcon={!canExecute && <LockIcon />}
                                                startIcon={<AppIcon name="Save" />}><span>Save<sub className={classes.mutedTextBtn}> (Alt + S)</sub></span></Button>
                                            <Button variant="outlined" className={classes.ml1}
                                                onClick={() => history.go(-1)}>Back</Button>
                                        </Grid>
                                    </Grid>
                                </span>
                                <DialogWindow name="subscriptionDialog" open={isDialogOpen}
                                    width="md" component={<Subscription isModal={true} />} />
                                <DialogWindow name="itemDialog" open={isDialogOpen}
                                    onExited={e => handleDialogClosed('itemDialog', setFieldValue, values, setValues)}
                                    width="lg" component={<Item allowBatchDetails={true} orderId={orderId} itemId={selectedItemId} entityName={toOrderType} />} />
                                <DialogWindow name="discountDialog" open={isDialogOpen}
                                    onExited={e => handleDialogClosed('discountDialog', setFieldValue, values, setValues)}
                                    width="md" component={
                                        <OrderDiscount isModal={true} entityName={toOrderType} orderId={orderId}
                                            orderTypeId={orderTypeId} orderDiscounted={orderDiscounted} orderItemDiscounted={orderItemDiscounted}
                                            orderTotal={values.orderTotal} grandTotal={values.grandTotal}
                                        />
                                    } />
                            </div>
                        </Form>
                    </>
                )}
            </Formik>


        </>
    );
};
