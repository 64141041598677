import React from 'react';
import { capitalizeFirstLetter, isNullOrUndefined } from '../Base/Utils';
import NoDataImage from '../../images/NoData.png'
export default function OrderPreview({ pdfUrl, entityName }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top', height: '100vh', background: '#fff' }}>
            {!isNullOrUndefined(pdfUrl) && pdfUrl !== "" ? (
                <iframe
                    src={`${pdfUrl}#toolbar=0&navpanes=0&view=FitH`}
                    title="Invoice Preview"
                    style={{ width: '100%', height: '100%', border: 'none', background: '#fff' }}
                />
            ) : (
                <div style={{ textAlign: 'center', color: '#5CC1DF' }}>
                    <img src={NoDataImage} alt="Oops" style={{ width: '50%' }} />
                    <h2>Select a {capitalizeFirstLetter(entityName)} to see preview</h2>
                </div>
            )}
        </div>
    );
};
