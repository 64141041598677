import { Button, ButtonBase, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import { PageHeader } from "../Controls/Controls";
import AppIcon from "../Controls/AppIcons";
import useStyles from "../../../styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Page({
    pageHeading = '',
    subHeading = '',
    pageIcon = '',
    okButtonCaption = 'Save',
    okButtonIcon = '',
    entityName = '',
    cancelButtonCaption = 'Back',
    showCancelButton = true,
    // onCancel = () => { },
    showDeleteButton = false,
    onDelete = (values) => { },
    resetButtonCaption="Reset",
    showResetButton = false,
    onReset = (values, setValues) => { },
    fieldValues = null,
    onFormSubmit = (values) => { },
    validationSchema = {},
    formContent = null,
    actionButtons = null,
    isModal = false,
    waiting = false,

}) {

    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <Grid container alignContent="space-between">
                <Grid item xs={6} sm={6}>
                    <PageHeader heading={pageHeading} subHeading={subHeading}>
                        <AppIcon name={pageIcon} color="primary" />
                    </PageHeader>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <div className={classes.pullRight}>
                        {actionButtons}
                    </div>
                </Grid>
            </Grid>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                validationSchema={yup.object(validationSchema)}
                onSubmit={async (values) => { await onFormSubmit(values) }}
            >
                {({ isSubmitting, values, setFieldValue, setValues }) => (
                    <Form>
                        <div className={classes.page}>
                            {formContent}
                        </div>

                        <Grid container>
                            <Grid item container xs={10} sm={8} className={`${classes.mt2}`} alignItems="center">
                                <Button type="submit" disabled={isSubmitting || waiting} variant="contained" color="primary"
                                    startIcon={<AppIcon name={okButtonIcon} />}>
                                    {okButtonCaption}
                                </Button>
                                {
                                    showCancelButton &&
                                    <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => history.goBack()}>
                                        {cancelButtonCaption}
                                    </Button>
                                }

                            </Grid>
                            <Grid item container xs={10} sm={8} className={`${classes.desktopOnly}`}></Grid>
                            {
                                (showDeleteButton) &&
                                <Grid item container xs={2} sm={4} className={classes.pullRight} alignItems="center">
                                    <ButtonBase title={`Delete ${entityName}`} onClick={() => { onDelete(values) }} disabled={waiting}>
                                        <AppIcon color={"error"} name="Delete" size="large" />
                                    </ButtonBase>
                                </Grid>
                            }
                            {
                                (showResetButton) &&
                                <Grid item container xs={2} sm={4} className={classes.pullRight} alignItems="center">
                                    <Button variant="text" color={"secondary"} onClick={() => { onReset(values, setValues) }} disabled={waiting}>
                                        {resetButtonCaption}
                                    </Button>
                                    {/*  <ButtonBase title={`Reset`} onClick={() => { onReset(values, setValues) }} disabled={waiting}>
                                        <AppIcon color={"secondary"} name="Refresh" size="large" />
                                    </ButtonBase> */}
                                </Grid>
                            }
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>

    )

};
