import { Button, ButtonBase, Grid } from "@material-ui/core";
import useStyles from "../../styles"

import { LockIcon, PageHeader } from "../UI/Controls/Controls";
import { TextInput, PhoneInput } from "../UI/Controls/InputFields";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useHistory, useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import AppIcon from "../UI/Controls/AppIcons";
import AuthContext from "../Auth/AuthContext";
import WebAPI from "../Base/WebAPI";
import useStore from "../Base/Store";
import axios from "axios";
import shallow from 'zustand/shallow';

import * as Common from '../Base/Common';
import * as Utility from "../Base/Utils";
import { useConfirm } from "material-ui-confirm";
import { setAuditInformation } from "../Base/Support";

export default function CustomerGroup(props) {
    const entityName = Common.COST_CENTER_ENTITY;
    const confirm = useConfirm();
    const classes = useStyles();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const moduleAccess = authContext.moduleAccess(Common.APP_MODULE_CustomerGroups);
    const [canExecute, setCanExecute] = useState(false);
    var { id } = useParams();

    const showAlert = useStore(state => state.showAlert);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);

    // State
    const [auditDetails, setAuditDetails] = useStore(state => [state.auditDetails, state.setAuditDetails], shallow);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [addressId, setAddressId] = useState(0);
    const [fieldValues, setFieldValues] = useState({
        id: 0,
        groupName: '',
        contactPerson: '',
        contactEmail: '',
        contactPhone: '',
        active: true
    });

    const deleteCustomerGroup = async () => {
        confirm(Utility.getConfirmOptions('Delete ' + entityName, 'Are you sure, you want to delete ' + entityName + '?'))
            .then(async () => {
                try {
                    setWaiting(true);
                    var response = await WebAPI.put('/' + entityName + '/Delete' + entityName, id, { headers: { "AccessToken": authContext.token } });
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                    } else {
                        showAlert(entityName + ' deleted successfully.', 'success');
                        history.push('/customergroups');
                    }
                } catch (error) {
                    showAlert(entityName + ' delete failed. Internal server error.', 'error');
                } finally {
                    setWaiting(false);
                }

            }).catch(() => { });
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        setCanExecute((Utility.safeInt(id) > 0 && moduleAccess.allowEdit) || (Utility.safeInt(id) === 0 && moduleAccess.allowAdd));
        async function init() {
            if (authContext.token != null) {
                Utility.setInputFocus("groupName");
                await fetchData(source);
            }
        }
        init();
        return () => {
            setAuditDetails(null);
            source.cancel();
        };
    }, []);

    const fetchData = async (source) => {
        try {
            setWaiting(true);
            if (!props.isModal && !!id && id > 0) {
                const response = await WebAPI.get('/CostCenter/GetCostCenterById/' + id, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                if (!!response.data) {
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                        return;
                    }
                    setAuditInformation(response.data, setAuditDetails);
                    var customerGroup = { ...fieldValues };
                    customerGroup.id = response.data.id;
                    customerGroup.groupName = response.data.name;
                    if (!!response.data.address) {
                        const address = response.data.address;
                        setAddressId(address.id);
                        customerGroup.contactPerson = address.contactPerson;
                        customerGroup.contactEmail = address.contactEmail;
                        customerGroup.contactPhone = address.contactPhone;
                    }
                    setFieldValues(customerGroup);
                }
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Request cancelled.');
            } else {
                showAlert('Failed to get customer group details. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            var apiUrl = '/CostCenter/CreateCostCenter';
            if (!props.isModal && !!id && id > 0) {
                apiUrl = '/CostCenter/UpdateCostCenter';
            }
            let request = {
                "id": !!id ? parseInt(id) : 0,
                "subscriberId": authContext.currentUser.subscriberId,
                "name": values.groupName,
                "active": true
            };

            // if (Utility.safeString(values.contactPerson).trim() !== ''
            //     || Utility.safeString(values.contactEmail) !== ''
            //     || Utility.safeString(values.contactPhone) !== '') {
            request['address'] = {
                "id": !!addressId ? parseInt(addressId) : 0,
                "description": "",
                "isPrimary": true,
                "addressType": 'CostCenter',
                "addressLine1": '',
                "addressLine2": '',
                "city": '',
                "state": 0,
                "pinCode": '',
                "contactPerson": values.contactPerson,
                "contactEmail": values.contactEmail,
                "contactPhone": values.contactPhone,
                "active": true
            }
            // }
            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });

            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                if (props.isModal === true) {
                    setDialogResponse(response.data);
                    closeDialog();
                } else {
                    history.goBack();
                }
                showAlert('Customer group saved successfully.', 'success');
            }

        } catch (error) {
            showAlert("Failed to save Customer group. Inernal server error occured." + error.response, "error");
        } finally {
            setWaiting(false);
        }
    }

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
                contactEmail: yup.string().matches(Common.REGEX_EMAIL_MULTI, 'Invalid email'),
                pinCode: yup.string().matches(Common.REGEX_ALPHA_NUMERIC, 'Invalid value'),
                contactPhone: yup.string().matches(Common.REGEX_PHONE_MULTI, 'Invalid mobile number.'),
            })}
            onSubmit={
                async (values) => {
                    await submitFormData(values);
                }
            }
        >
            {({ isSubmitting, values }) => (
                <Form>
                    <PageHeader heading={(!!id && id > 0) ? 'Cost Center details' : 'New Cost Center'}>
                        <AppIcon name="CustomerGroup" color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextInput label="Cost Center name" name="groupName" placeholder="" required
                                    inputProps={{ autoFocus: true, minLength: 1, maxLength: 50 }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput label="Contact person" name="contactPerson" placeholder=""
                                    inputProps={{ maxLength: 50 }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput label="Email" name="contactEmail" placeholder=""
                                    inputProps={{ maxLength: 200 }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PhoneInput label="Phone" name="contactPhone" placeholder=""
                                    inputProps={{ maxLength: 10 }} />
                            </Grid>
                            <Grid item container xs={10} sm={8}>
                                <Button type="submit" disabled={isSubmitting || waiting || !canExecute}
                                    variant="contained" color="primary" endIcon={!canExecute && <LockIcon />}>{(!!id && id > 0 && !props.isModal) ? 'Update' : 'Save'}</Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => props.isModal ? closeDialog() : history.goBack()}>{props.isModal ? 'Cancel' : 'Back'}</Button>
                            </Grid>

                            {(!Utility.safeBool(props.isModal) && Utility.safeInt(id) > 0 && canExecute) && moduleAccess.allowDelete &&
                                <Grid item container xs={2} sm={4} className={classes.pullRight} alignItems="center">
                                    <ButtonBase title="Delete cost center" onClick={deleteCustomerGroup} disabled={waiting}>
                                        <AppIcon color="error" name="Delete" size="large" />
                                    </ButtonBase>
                                </Grid>
                            }

                        </Grid>

                    </div>
                </Form>
            )}

        </Formik>
    );
};