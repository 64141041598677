import axios from 'axios';
import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';
import useStyles from '../../../styles';
import useStore from '../../Base/Store';
import AppIcon from '../../UI/Controls/AppIcons';
import { PageHeader } from '../../UI/Controls/Controls';

const CHANNEL_ID = 'UChxUStbZbK8zor-dgVXxd-g';
const PLAYLIST_ID = 'PLZRfogCtuAV2lyU3ZzeUAg52jLRhdBDFL';
const API_KEY = 'AIzaSyAR8ypily4uuTfMUjbGZyhQElrub-Kvp8Q';


export default function HelpVideos() {
    // General
    const classes = useStyles();

    // State
    const [videos, setVideos] = useState([]);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    const getVideos = async () => {
        try {
            //https://youtube.com/playlist?list=
            setWaiting(true);
            const response = await axios.get(
                `https://www.googleapis.com/youtube/v3/playlistItems?key=${API_KEY}&playlistId=${PLAYLIST_ID}&part=snippet,id&order=date&maxResults=100`
            );
            setVideos(response.data.items);
        } catch (error) {
            console.error(error);
        } finally {
            setWaiting(false);
        }
    }
    useEffect(() => {
        async function init() {
            await getVideos();
        }
        init();
        return () => {
            setVideos(null);
        };
    }, []);
    return (
        <div style={{ overflowY: 'auto' }}>
            <PageHeader heading="Help">
                <AppIcon name="Help" color="secondary" />
            </PageHeader>
            <div className={classes.page}>
                {videos.map(video => (
                    <div className={classes.iframeContainer}>
                        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`} title="Getting started with ezybill" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    // <div key={video.snippet.resourceId.videoId}>
                    //     <h2>{video.snippet.title}</h2>                        
                    //     <a href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`} target="_blank" rel="noreferrer">
                    //         Watch on YouTube
                    //     </a>
                    // </div>
                ))}
            </div>
        </div>
    );
}


