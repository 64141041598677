import * as Utility from './Utils';
import * as Common from './Common';

export function getAlertNameById(alertTypeId) {
    switch (Utility.safeInt(alertTypeId)) {
        case Common.ALERT_TYPE_CREDIT_DAYS:
            return "CREDIT_DAYS";
        case Common.ALERT_TYPE_CREDIT_LIMIT:
            return "CREDIT_LIMIT";
        case Common.ALERT_TYPE_CUSTOMER_PAYMENT_DUE:
            return "CUSTOMER_PAYMENT_DUE";
        case Common.ALERT_TYPE_CUSTOM:
            return "ITEMS_CUSTOM";
        case Common.ALERT_TYPE_ITEMS_EXPIRED:
            return "ITEMS_EXPIRED";
        case Common.ALERT_TYPE_ITEMS_EXPIRING:
            return "ITEMS_EXPIRING";
        case Common.ALERT_TYPE_LOW_STOCK:
            return "LOW_STOCK";
        case Common.ALERT_TYPE_OUT_OF_STOCK:
            return "OUT_OF_STOCK";
        case Common.ALERT_TYPE_SUPPPLIER_PAYMENT_DUE:
            return "SUPPPLIER_PAYMENT_DUE";
        default:
            break;
    }
}

export function setAuditInformation(entity, setAuditDetails) {
    try {
         setAuditDetails({
             createUserId: entity.createUserId,
             dateCreated: entity.dateCreated,
             createUserName: entity.createUserName,
             createEmailAddress: entity.createEmailAddress,

             updatedUserId: entity.updateUserId,
             dateUpdated: entity.dateUpdated,             
             updateUserName: entity.updateUserName,
             updateEmailAddress: entity.updateEmailAddress,
         });        
    } catch (error) {
        console.log(error);
    }
}

