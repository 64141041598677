import React, { useState } from "react"
import { TextField, InputLabel, FormControl, Select, InputAdornment, useTheme, Button, ButtonBase, Grid, IconButton } from "@material-ui/core"
import { useField } from 'formik';
import useStyles from "../../../styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Autocomplete } from '@material-ui/lab';
import AppIcon from "./AppIcons";
import { getCurrencySymbol, isNullOrEmpty, isNullOrUndefined, safeBool, safeString } from "../../Base/Utils";
import { EBPopper } from "./Controls";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const TextInput = ({ gridInputStyle = false, label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="text" variant="outlined" size="small"
            label={gridInputStyle ? "" : label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth
            placeholder={gridInputStyle ? "" : safeString(props?.placeholder)}
            InputProps={{
                ...props.InputProps,
                classes: {
                    input: safeBool(props.boldInputText) ? classes.boldInput : null,
                },
                style: gridInputStyle
                    ? {
                        height: '30px',  
                        fontSize: '13px'                      
                    }
                    : {
                        ...props.InputProps,
                        style: {

                        },
                    },
            }}
        />
    )

};

const GridInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="text" variant="outlined" size="small"
            {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth
            InputProps={{
                ...props.InputProps,
                classes: {
                    input: safeBool(props.boldInputText) ? classes.boldInput : null,
                },
                style: {

                    padding: '0px',
                    height: '30px',
                },
            }} />
    )

};


const TextButtonInput = ({ label, buttonIconName, buttonColor = "secondary", buttonVariant = "text", buttonCaption = '', buttonTooltip, buttonClass = '', handleClick, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="text" variant="outlined" size="small"
            label={label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth
            InputProps={{
                ...props.InputProps,
                style: {

                },
                endAdornment: <>
                    {
                        <InputAdornment position="end" style={{ marginRight: '-12px', marginTop: '0px' }}
                            onClick={isNullOrUndefined(handleClick) ? () => { } : handleClick}
                        >
                            <Button variant={buttonVariant} color={buttonColor} title={safeString(buttonTooltip)} className={buttonClass}
                                startIcon={<AppIcon name={buttonIconName} />}>
                                {buttonCaption}
                            </Button>
                        </InputAdornment>
                    }
                </>
            }} />
    )

};

const SearchInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="text" variant="outlined" size="small"
            InputProps={{
                startAdornment: <InputAdornment position="start"><AppIcon name="Search" /></InputAdornment>,
                maxLength: 12,
                ...props.InputProps,
                style: {

                },

            }}

            placeholder="Type to search..."
            label={label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth
        />
    )

};

const PhoneInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="tel" variant="outlined" size="small"
            InputProps={{
                startAdornment: <InputAdornment position="start">{'+91'}</InputAdornment>,
                maxLength: 12,
                ...props.InputProps,
                style: {

                },
            }}
            // inputProps={{ maxLength: 12 }}
            label={label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth />
    )
};

const EmailInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="email" variant="outlined" size="small"
            InputProps={{
                ...props.InputProps,
                style: {

                },
            }}
            label={label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth />
    )
};

const MobilePasswordInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <TextField type="password" variant="outlined" size="small"
            InputProps={{
                autoComplete: safeBool(props.isnew) ? 'current-password' : 'new-password',
                ...props.InputProps,
                style: {

                },
            }}
            label={label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth />
    )
};


const PasswordInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);

    if (props.hidden) {
        return null;
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <TextField
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            size="small"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            color="primary"
                            tabIndex={-1}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),

                ...props.InputProps,
                style: {
                    ...props.InputProps?.style,
                },
                /*   autoComplete: safeBool(props.isnew) ? 'current-password' : 'new-password', */
            }}
            label={label}
            {...field}
            {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.touched && meta.error ? meta.error : ''}
            fullWidth
        />
    )
};

const DateTimeInput = ({ label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    var inputProps = {
        ...props.InputProps,
        style: {

        },
    };
    if (props.compact) {
        inputProps = {            
            classes: {
                input: safeBool(props.boldInputText) ? classes.dateInputBold : classes.dateInput,
            },
            ...props.InputProps,
            style: {

            },
        }
    }
    return (
        <TextField type="datetime-local" variant="outlined" size="small"
            InputLabelProps={{
                shrink: true,
                ...props.InputProps,
            }}
            InputProps={inputProps}
            label={label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth />
    )
};

const DateInput = ({ label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);

    if (props.hidden) {
        return null;
    }

    const handleDateChange = (e) => {
        const value = e.target.value;
        const isValidDate = value.match(/^\d{4}-\d{2}-\d{2}$/); // Match the format YYYY-MM-DD
        if (!isValidDate) {
            return;
        }

        field.onChange(e);
    };

    const inputProps = props.compact
        ? {
            classes: {
                input: safeBool(props.boldInputText) ? classes.dateInputBold : classes.dateInput,
            },
        }
        : {};

    return (
        <TextField
            type="date"
            variant="outlined"
            size="small"
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                ...inputProps, // Spread existing inputProps
                inputProps: {
                    min: "0001-01-01", // Minimum date
                    max: "2999-12-31", // Maximum date
                },
            }}
            label={label}
            {...field}
            {...props}
            onChange={handleDateChange}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error}
            fullWidth
        />
    );
};



const NumberInput = ({ gridInputStyle = false, label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    var inputProps = {
        ...props.InputProps,
        style: {

        },
    };
    if (props.compact) {
        inputProps = {
            classes: {
                input: safeBool(props.boldInputText) ? classes.numberInput : classes.numberInputBold,
            },
            style: gridInputStyle
                ? {
                    fontSize: '13px', 
                    padding: '0px',
                    height: '30px',
                }
                : {
                    ...props.InputProps,
                    style: {

                    }
                },
        }
    }
    return (
        <TextField type="number" variant="outlined" size="small"
            InputProps={inputProps}
            label={gridInputStyle ? "" : label} {...field} {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error} fullWidth
            onWheel={() => document.activeElement.blur()}
        />
    )
};

const CurrencyInput = ({ gridInputStyle = false, label, ...props }) => {
    const classes = useStyles();
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    if (isNullOrEmpty(props.currencyCode)) {
        props.currencyCode = 'INR';
    }
    // var inputProps = null;
    // if (props.compact) {
    //     inputProps = {
    //         classes: {
    //             input: classes.numberInput,
    //         },
    //     }
    // }
    return (
        <TextField
            type="number"
            variant="outlined"
            size="small"
            InputProps={{
                [gridInputStyle ? "startAdornment" : "startAdornment"]: (
                    <InputAdornment style={{ margin: '0px', padding: '0px' }}>
                        {getCurrencySymbol(props.currencyCode)}
                    </InputAdornment>
                ),                
                classes: {
                    input: safeBool(props.boldInputText) ? classes.numberInputBold : classes.numberInput,
                },
                style: gridInputStyle
                    ? {
                        fontSize: '13px', 
                        padding: '0px',
                        height: '30px',
                        paddingRight: '7px',
                        direction: "ltr",
                        paddingLeft: '5px'
                    }
                    : {
                        ...props.InputProps,
                        style: {

                        },
                    },
            }}
            label={gridInputStyle ? "" : label}
            {...field}
            {...props}
            error={meta.touched && meta.error ? true : false}
            helperText={meta.error}
            fullWidth
            onWheel={() => document.activeElement.blur()}
        />

    )
};

const CheckboxInput = ({ children, label, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field} {...props}
                    size="small"
                />

            }
            label={label}
        >
            {children}
        </FormControlLabel>

    )
};

const SelectInput = ({ gridInputStyle = false, label, searchable, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    if (props.hidden) {
        return false;
    }
    if (searchable === true) {
        return (
            <Autocomplete
                {...field} {...props}
                classes={{
                    option: classes.option

                }}
                PopperComponent={EBPopper}
                fullWidth
                size="small"
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                renderInput={props.renderInput}

            />
        )
    } else {
        return (
            <FormControl required={props.required} variant="outlined" size="small" native="false" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple" size="small">{gridInputStyle ? "" : label}</InputLabel>
                <Select
                    sx={{
                        "&:hover": {
                            "&& fieldset": {
                                border: "3px solid green"
                            }
                        }
                    }}
                    inputProps={{
                        style: gridInputStyle
                            ? {
                                fontSize: '13px', 
                                padding: '0px 2px',
                                height: '30px',
                            }
                            : {
                                ...props.InputProps,
                                style: {

                                },
                            },
                    }}
                    native
                    {...field}
                    {...props}
                    error={meta.touched && meta.error ? true : false}
                    {...(!gridInputStyle && { label: label })}
                    variant={"outlined"} // Adjust the variant as needed
                >
                    {props.children}
                </Select>
            </FormControl>
        )
    }

};

const QuantityInput = ({ label, handleClick, showaslink, gridInputStyle = false, ...props }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    var inputAdornmentStyle = { fontSize: '10px' }
    if (safeBool(showaslink)) {
        inputAdornmentStyle = { fontSize: '12px', textDecoration: 'underline', textDecorationStyle: 'dotted', color: theme.palette.primary.main, cursor: 'pointer', fontWeight: 'bold' }
    }
    return (
        <>
            <TextField type="number" variant="outlined" size="small" compact="true"
                InputProps={{
                    endAdornment: <>
                        <InputAdornment position="end" style={{ margin: '-5px', padding: '-5px' }}
                            onClick={isNullOrUndefined(handleClick) ? () => { } : handleClick}
                        >
                            <span style={inputAdornmentStyle}>
                                {
                                    safeString(props.uom)
                                }
                            </span>
                        </InputAdornment>
                    </>,
                      
                    classes: {
                        input: safeBool(props.boldInputText) ? classes.quantityInputBold : classes.quantityInput,
                    },              
                    style: gridInputStyle
                        ? {
                            fontSize: '13px', 
                            padding: '0px',
                            height: '30px',
                            paddingRight: '7px'
                        }
                        : {
                            ...props.InputProps,
                            style: {

                            },
                        },

                }}
                label={gridInputStyle ? "" : label} {...field} {...props}
                error={meta.touched && meta.error ? true : false}
                helperText={meta.error} fullWidth
                onWheel={() => document.activeElement.blur()}
            />
            {/* <label>{"Ex: 12-5"}</label> */}
        </>

    );
}


const DiscountInput = ({ label, handleClick, isDiscountAmount, gridInputStyle = false, ...props }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <>
            <TextField type="number" variant="outlined" size="small" compact="true"
                InputProps={{
                    endAdornment: <>
                        <InputAdornment position="end" style={{ margin: '-5px', padding: '-5px' }}
                            onClick={isNullOrUndefined(handleClick) ? () => { } : handleClick}
                        >
                            <span style={{ cursor: 'pointer' }}>
                                {
                                    <AppIcon name={isDiscountAmount} color={isDiscountAmount === "Percentage" ? theme.palette.secondary.main : 'green'} size="sm" style={{ fontSize: '8px' }} />
                                }
                                <AppIcon name="ExpandMore" size="xs" color="primary" style={{ fontSize: '14px' }} />
                            </span>
                        </InputAdornment>
                    </>,

                    classes: {
                        input: classes.quantityInput,
                    },
                    style: gridInputStyle
                        ? {
                            fontSize: '13px', 
                            padding: '0px',
                            height: '30px',
                            paddingRight: '7px'
                        }
                        : {
                            ...props.InputProps,
                            style: {

                            },
                        },

                }}
                label={gridInputStyle ? "" : label} {...field} {...props}
                error={meta.touched && meta.error ? true : false}
                helperText={meta.error} fullWidth
                onWheel={() => document.activeElement.blur()}
            />
            {/* <label>{"Ex: 12-5"}</label> */}
        </>

    );
}


const YesNoSelectInput = ({ label, name, required, nocaption = 'No', yescaption = 'Yes', ...props }) => {
    return (
        <SelectInput InputProps={{
            ...props.InputProps,
            style: {

            },
        }} label={label} name={name} required={required}>
            {
                safeBool(required) === false &&
                <option value={-1}>{""}</option>
            }
            <option value={0}>{nocaption}</option>
            <option value={1}>{yescaption}</option>
        </SelectInput>
    )
}

const InputField = ({ type, name, label, mandatory, title, minlength, maxlength, xs = 12, sm = 6, md = 4, lg = 3, visible = true, compact = false }) => {
    return (
        <>
            {
                (type === "Date" && visible) &&
                <Grid item xs={xs} sm={sm} md={md}>
                    <DateInput name={name} label={label} required={safeBool(mandatory)} compact={compact} title={title} />
                </Grid>
            }
            {
                (type === "DateTime" && visible) &&
                <Grid item xs={xs} sm={sm} md={md}>
                    <DateTimeInput name={name} label={label} required={safeBool(mandatory)} compact={compact} title={title} />
                </Grid>
            }
            {
                (type === "Text" && visible) &&
                <Grid item xs={xs} sm={sm} md={md}>
                    <TextInput name={name} label={label} required={safeBool(mandatory)} compact={compact} title={title} />
                </Grid>
            }
            {
                (type === "Numeric" && visible) &&
                <Grid item xs={xs} sm={sm} md={md}>
                    <NumberInput name={name} label={label} required={safeBool(mandatory)} compact={compact} title={title} />
                </Grid>
            }
            {
                (type === "CheckBox" && visible) &&
                <Grid item xs={xs} sm={sm} md={md}>
                    <CheckboxInput name={name} label={label} required={safeBool(mandatory)} compact={compact} title={title} />
                </Grid>
            }
            {
                (type === "Currency" && visible) &&
                <Grid item xs={xs} sm={sm} md={md}>
                    <CurrencyInput name={name} label={label} required={safeBool(mandatory)} compact={compact} title={title} />
                </Grid>
            }
        </>
    )
}

export { InputField, YesNoSelectInput, TextInput, GridInput, TextButtonInput, SearchInput, PhoneInput, EmailInput, PasswordInput, MobilePasswordInput, DateInput, DateTimeInput, NumberInput, CurrencyInput, CheckboxInput, SelectInput, QuantityInput, DiscountInput };
