import { Box, Grid, Typography } from "@material-ui/core";
import { useContext } from "react";
import useStyles from "../../styles";
import AuthContext from "../Auth/AuthContext";

export default function Home() {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                style={{ marginTop: 'auto', minHeight: '63vh' }}
            >
                <Box style={{ backgroundColor: '#DAE9F3', padding: '108px', borderRadius: '15px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" color="primary" align="center">{"Welcome " + authContext.currentUser.firstName + "!"}</Typography>
                            <Typography variant="h5" color="primary" align="center" className={classes.mt2}>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
};
