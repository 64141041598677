import { useContext, useEffect, useState } from "react"
import shallow from "zustand/shallow";
import AuthContext from "../../Auth/AuthContext";
import { getDataList } from "../../Base/DataProvider";
import useStore from "../../Base/Store";
import { useHistory } from "react-router";
import { isNullOrUndefined, safeBool, safeInt, safeFloat, setInputFocus, safeString } from "../../Base/Utils";
import { Form, Formik } from "formik";
import { PageHeader } from "../../UI/Controls/Controls";
import AppIcon from "../../UI/Controls/AppIcons";
import useStyles from "../../../styles";
import { Button, Grid } from "@material-ui/core";
import { CurrencyInput, NumberInput, SelectInput } from "../../UI/Controls/InputFields";

export default function OrderDiscount({ isModal, entityName, orderTypeId, orderId, orderDiscounted, orderItemDiscounted, orderTotal, grandTotal }) {
    const authContext = useContext(AuthContext);
    const classes = useStyles();

    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);

    const history = useHistory();
    const showAlert = useStore(state => state.showAlert);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [fieldValues, setFieldValues] = useState({
        id: 0,
        discountPercent: 0,
        discountAmount: 0,
        discountType: orderItemDiscounted ? 2 : 1,
        taxableAmount: safeFloat(orderTotal).toFixed(2),
        grandTotal: safeFloat(grandTotal).toFixed(2)
    });

    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            if (safeFloat(values.discountAmount) === 0 && safeFloat(values.discountPercent) === 0) {
                showAlert("Please either enter a discount percentage or amount", "error");
                setInputFocus('discountPercent');
            } else {
                const request = { orderId: safeInt(orderId), orderTypeId: orderTypeId, discountAmount: safeFloat(values.discountAmount), discountPercent: safeFloat(values.discountPercent), discountType: safeInt(values.discountType) };
                const response = await getDataList(entityName, 'ProcessDiscount', request, authContext, null, 'put');
                if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
                    showAlert(response.message, 'error');
                } else {
                    setDialogResponse(response);
                    closeDialog();
                }
            }

        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const handleBlur = (e, values, setFieldValue) => {
        let afterTax = values.discountType == "1" ? false : true;
        if (e.target.name === 'discountType') {
            setFieldValue('discountType', e.target.value);
            afterTax = (e.target.value == "1") ? false : true;
        }
        switch (e.target.name) {
            case "discountPercent":
                let discountAmount = 0;
                if (afterTax) {
                    discountAmount = (safeFloat(values.grandTotal) * (safeFloat(values.discountPercent) / 100))
                } else {
                    discountAmount = (safeFloat(values.taxableAmount) * (safeFloat(values.discountPercent) / 100))
                }
                setFieldValue('discountAmount', discountAmount.toFixed(2));
                break;
            case "discountAmount":
                let discountPercent = 0;
                if (afterTax) {
                    discountPercent = (100 * (safeFloat(values.discountAmount) / safeFloat(values.grandTotal)));
                } else {
                    discountPercent = (100 * (safeFloat(values.discountAmount) / safeFloat(values.taxableAmount)));
                }
                setFieldValue('discountPercent', discountPercent.toFixed(2));
                break;
            default:
                break;
        }
    }
    useEffect(() => {
    }, []);

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            onSubmit={
                async (values) => {
                    await submitFormData(values);
                }
            }
        >
            {({ values, setFieldValue, setValues, isSubmitting, isValid }) => (
                <Form>
                    <PageHeader heading={'Add discount'}>
                        <AppIcon name="Percentage" color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <SelectInput label="Type" name="discountType" onChange={(e) => handleBlur(e, values, setFieldValue)}
                                    disabled={orderItemDiscounted || orderDiscounted}>
                                    <option value={1}>Discount before tax</option>
                                    <option value={2}>Discount after tax</option>
                                </SelectInput>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <NumberInput label="Discount % age" name="discountPercent" placeholder="" inputProps={{ step: 0.01, min: 0, max: 100 }} onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CurrencyInput currencyCode={currencyCode} label="Discount amount" name="discountAmount" placeholder="" compact="true" onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CurrencyInput currencyCode={currencyCode} label="Invoice total (Before tax)" name="taxableAmount" placeholder="" disabled />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CurrencyInput currencyCode={currencyCode} label="Grand total (After tax)" name="grandTotal" placeholder="" disabled />
                            </Grid>
                            <Grid item container xs={10} sm={8}>
                                <Button type="submit" disabled={isSubmitting || waiting} variant="contained" color="primary" size="small">{'Add discount'}</Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} size="small" onClick={() => safeBool(isModal) ? closeDialog() : history.goBack()}>{safeBool(isModal) ? 'Cancel' : 'Back'}</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    )

};
