import { Button, ButtonBase, Grid, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router";
import axios from "axios";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import useStyles from "../../styles";
import AuthContext from "../Auth/AuthContext";
import { PRODUCT_ENTITY, REPORT_ENTITY } from "../Base/Common";
import { getData, getDataList } from "../Base/DataProvider";
import useStore from "../Base/Store";
import { filterOptions, getLocaleDate, isNullOrUndefined, safeInt, safeString, setInputFocus } from "../Base/Utils";
import AppIcon from "../UI/Controls/AppIcons";
import { PageHeader } from "../UI/Controls/Controls";
import { DateInput, SearchInput, TextInput } from "../UI/Controls/InputFields";
import MuiTable from "../UI/Controls/MuiTable";
import { getProductStockDescription, getOrderType } from "./OrderHelper";

export default function Search() {
    const classes = useStyles();
    const history = useHistory();
    //state        
    const [isSearchDrawerOpen, toggleSearchDrawer] = useStore(state => [state.isSearchDrawerOpen, state.toggleSearchDrawer], shallow);
    const showAlert = useStore(state => state.showAlert);
    const authContext = useContext(AuthContext);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [searchFilter, setSearchFilter] = useStore(state => [state.searchFilter, state.setSearchFilter], shallow);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showDateFilter, setShowDateFilter] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [fieldValues, setFieldValues] = useState(
        {
            fromDate: searchFilter.fromDate,
            toDate: searchFilter.toDate,
            productId: searchFilter.productId,
            serialNumber: searchFilter.serialNumber,
            orderNumber: searchFilter.orderNumber,
            productCode: "",
            productDescription: ""
        });

    const viewOrder = (id, orderTypeId) => {
        if (safeInt(id) > 0) {
            history.push("/" + getOrderType(orderTypeId).toLowerCase() + "/" + id);
            toggleSearchDrawer(!isSearchDrawerOpen);
        }
    }
    const fetchData = async (token) => {
        try {
            setWaiting(true);
            const response = await getData(PRODUCT_ENTITY, "ProductList", authContext, token, authContext.currentUser.subscriberId);
            if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
                showAlert(response.error, 'error');
            } else {
                setProducts(response);
                if (safeInt(fieldValues.productId) > 0) {
                    const product = response.find(p => p.id === fieldValues.productId);
                    setSelectedProduct(product);
                }
            }

        } catch (error) {

        } finally {
            setWaiting(false);
        }
    }

    const getList = async (values) => {
        try {
            setWaiting(true);
            if (safeInt(values.productId) === 0 && safeString(values.serialNumber) === "" && safeString(values.orderNumber) === "") {
                // showAlert('Please input at least one search criteria.', 'error');
                setTimeout(() => {
                    setInputFocus('productDescription');
                });
                return;
            }
            const filter = {
                fromDate: values.fromDate,
                toDate: values.toDate,
                productId: safeInt(values.productId),
                serialNumber: safeString(values.serialNumber),
                orderNumber: safeString(values.orderNumber),
                productCode: ""
            }

            setSearchFilter(filter);

            var response = await getDataList(REPORT_ENTITY, "GetOrderDetailsSearchList", filter, authContext, null, 'put');
            if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
                showAlert(response.error, 'error');
            } else {
                setTableData(response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            await fetchData(source.token);
            await getList(fieldValues);
        }
        init();
        return () => {
            setTableData(null);
            setSelectedProduct(null);
            setProducts(null);
            setFieldValues(null);
            source.cancel();
        };
    }, []);

    return (
        <>

            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                onSubmit={async (values) => { await getList(values) }}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <div style={{ overflowY: 'auto' }}>
                        <Form>
                            <PageHeader heading="Search transactions by" >
                                <AppIcon name="Search" color="primary" className={classes.ml1} />
                            </PageHeader>
                            <div className={classes.page}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={2} >
                                        <Autocomplete
                                            fullWidth
                                            filterOptions={filterOptions}
                                            size="small"
                                            id="productId"
                                            name="productId"
                                            options={products}
                                            getOptionLabel={(option) => !isNullOrUndefined(option) ? (safeString(option.description) + " " + safeString(option.productCode) + " " + safeString(option.barCode)) : ""}
                                            renderOption={(props, option) => {
                                                return (
                                                    <>
                                                        <span style={{ fontSize: '12px' }}>
                                                            {safeString(props.description) + " " + safeString(props.productCode) + " " + safeString(props.barCode)} <br />
                                                            <span style={{ fontSize: '11px', fontWeight: 'bold' }}>
                                                                {getProductStockDescription(props)}
                                                            </span>
                                                        </span>
                                                    </>
                                                );
                                            }}
                                            value={selectedProduct}
                                            onChange={async (e, val) => {
                                                if (!isNullOrUndefined(val)) {
                                                    setFieldValue('productId', safeInt(val.id));
                                                    setFieldValue('productDescription', val.description);
                                                    setFieldValue('productCode', val.productCode);
                                                } else {
                                                    setFieldValue('productId', 0);
                                                    setFieldValue('productDescription', '');
                                                    setFieldValue('productCode', '');
                                                }
                                                setSelectedProduct(val);
                                            }}
                                            renderInput={(props) =>
                                                <SearchInput id="productDescription" fullWidth name="productDescription"  {...props} label="Item" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    {
                                        authContext.settings.useBatchSerialNoTracking &&
                                        <Grid item xs={12} sm={2}>
                                            <TextInput name="serialNumber" label="Serial #" />
                                        </Grid>
                                    }

                                    <Grid item xs={12} sm={2}>
                                        <TextInput name="orderNumber" label="Voucher #" />
                                    </Grid>
                                    {
                                        showDateFilter &&
                                        <>
                                            <Grid item xs={6} sm={2}>
                                                <DateInput name="fromDate" label="From date" required compact="true" />
                                            </Grid>
                                            <Grid item xs={6} sm={2}>
                                                <DateInput name="toDate" label="To date" required compact="true" />
                                            </Grid>
                                        </>

                                    }
                                    <Grid item xs={12} sm={2}>
                                        <Button type="submit" variant="contained" color="secondary" startIcon={<AppIcon name="Search" />}>Search</Button>
                                    </Grid>

                                    <Grid item container xs={12} spacing={0}>
                                        <span className={classes.pullLeft}>
                                            <Tooltip title="Click to view more filter options." tabIndex={98}>
                                                <ButtonBase onClick={() => setShowDateFilter(!showDateFilter)} className={`${classes.link} ${classes.mr1}`}>
                                                    {showDateFilter ? 'Show less' : 'Show more filter options'}
                                                </ButtonBase>
                                            </Tooltip>
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiTable columns={[
                                            {
                                                title: 'Date', field: 'orderDate', alwaysVisible: true, disableClick: true,
                                                render: rowData => <span>{getLocaleDate(rowData.orderDate)}</span>,
                                                customSort: (a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime()
                                            },
                                            {
                                                title: 'Voucher #', field: 'orderNumber', alwaysVisible: true, disableClick: true,
                                                render: rowData => <ButtonBase onClick={() => viewOrder(rowData.orderId, rowData.orderTypeId)} type="text" color="primary" size="small" className={classes.linkButton}>{rowData.orderNumber}</ButtonBase>
                                            },
                                            {
                                                title: 'Type', field: 'orderTypeId', disableClick: true,
                                                render: rowData => <span>{getOrderType(rowData.orderTypeId)}</span>
                                            },
                                            { title: 'Party', field: 'referenceName', disableClick: true },
                                            { title: 'Item', field: 'description', disableClick: true },

                                        ]}
                                            data={tableData}
                                            actions={[]}
                                            toolbar={false}
                                            paging={true}
                                            sorting={true}
                                        />

                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>

        </>
    );

};
