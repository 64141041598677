import { APPLICATION_ID_GANIT } from "../../Base/Common";
import { getDataList } from "../../Base/DataProvider";
import { getConfirmOptions, getStateIdByName, isNullOrEmpty, isNullOrUndefined, safeInt, safeString, setInputFocus } from "../../Base/Utils";
import { verifySubscription } from "../OrderHelper";


export const getGSTINInfo = async (gstin, stateList, setFieldValue, setWaiting, showAlert, confirm, authContext, isSignUp = false) => {
    try {
        setWaiting(true);
        if (!isSignUp && !verifySubscription(authContext.currentSubscriber)) {
            showAlert('You do not have a active subscription plan. Please go to organization profile and upgrade your subscription plan.');
            return;
        }
        if (safeString(gstin).length < 15) {
            showAlert('Failed to fetch GSTIN details. GSTIN should be 15 digits.', 'error');
            return;
        }
        const request = {
            gstin: gstin,
            applicationId: APPLICATION_ID_GANIT,
            providerName: 'GSTAdaequare',
            mode: 2,
            serviceType: 9,
        };
        const response = await getDataList("GST", "SearchGSTTaxPayer", request, authContext, null, 'post');
        if (!isNullOrUndefined(response)) {
            if (safeInt(response.code) > 0) {
                let errorObject = JSON.parse(response.message);
                if (!isNullOrUndefined(errorObject)) {
                    showAlert(errorObject.message, 'error');
                } else {
                    setInputFocus('taxIdNumber');
                    showAlert('Failed to fetch GSTIN details. Please verify your GSTIN and try again. ' + response.message, 'error');
                }
            } else {                
                confirm(getConfirmOptions('Update address?', 'GSTIN validated successfully. Do you want to update address from GST records?', 'Yes update address', 'No'))
                    .then(async () => {
                        let address = getAddressFromGSTInfo(response, stateList);
                        if (!isNullOrUndefined(address)) {
                            setFieldValue('name', address.legalName);
                            setFieldValue('legalName', address.legalName);
                            setFieldValue('addressLine1', address.addressLine1);
                            setFieldValue('addressLine2', address.addressLine2);
                            setFieldValue('city', address.city);
                            setFieldValue('pinCode', address.pinCode);
                            setFieldValue('stateId', address.stateId);
                            setFieldValue('contactPerson', address.contactPerson);
                        }
                        showAlert('GSTIN validated and details fetched successfully! Please click on Save/Update and continue.');
                    }).catch(() => { });
            }
        }
    } catch (error) {
        showAlert('Failed to get GSTIN data. Please try again later.', 'error');
    } finally {
        setWaiting(false);
    }
}

const getAddressFromGSTInfo = (gstInfo, stateList) => {
    let address = null;
    if (!isNullOrUndefined(gstInfo)) {
        address = {};
        address.legalName = safeString(gstInfo.tradeName);
        address.contactPerson = safeString(gstInfo.legalName);
        address.addressLine1 = safeString(gstInfo.blockNo) + ' ' + safeString(gstInfo.flatNo) + safeString(gstInfo.buildingName);
        address.addressLine2 = safeString(gstInfo.street) + ' ' + safeString(gstInfo.location);
        address.stateId = getStateIdByName(stateList, safeString(gstInfo.state));
        address.city = safeString(gstInfo.city);
        if (isNullOrEmpty(address.city)) {
            address.city = safeString(gstInfo.district);
        }
        address.pinCode = safeString(gstInfo.pinCode);        
    }
    return address;
}