import React, { useState } from 'react';
import { Button, Popover, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppIcon from '../UI/Controls/AppIcons';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const SortButton = ({ buttonLabel, sortOptions, onSortChange, iconName, buttonStyle, buttonClass }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSortClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                style={buttonStyle}
                className={buttonClass}
                variant="text"
                color="primary"
                size="small"
                endIcon={<AppIcon name={iconName} size="small" />}
                onClick={handleSortClick}
            >
                {buttonLabel}
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleSortClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '10px' }}>
                    {sortOptions?.map((option) => (
                        <Typography
                            variant="body1"
                            key={option.field}
                            onClick={() => {
                                onSortChange(option.field);
                                handleSortClose();
                            }}
                            style={{ padding: '5px', cursor: 'pointer', display: 'flex', justifyContent: 'left', alignItems: 'center', }}
                        >
                            {option.label}
                            {option.isSorted && (
                                option.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
                            )}
                        </Typography>
                    ))}
                </div>

            </Popover>
        </>
    );
};

SortButton.propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            direction: PropTypes.oneOf(['asc', 'desc']),
            isSorted: PropTypes.bool, // Add isSorted to prop types
        })
    ).isRequired,
    onSortChange: PropTypes.func.isRequired,
    iconName: PropTypes.string,
    buttonStyle: PropTypes.object,
    buttonClass: PropTypes.string,
};

SortButton.defaultProps = {
    iconName: 'Sort',
    buttonStyle: { background: '#FAFAFA' },
    buttonClass: '',
};

export default SortButton;
