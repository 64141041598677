import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { ButtonBase } from '@material-ui/core';
import AppIcon from '../Controls/AppIcons';
import { isNullOrEmpty } from '../../Base/Utils';
import useStyles from '../../../styles';
import anyDeskIcon from '../../../images/anydesk.png'
import hsnCodeFinder from '../../../images/hsn_code_finder.jpeg';
import gstPortal from '../../../images/gst_portal.png'


export default function HelpMenu({ HelpOpen, onClose, helpAnchorEl }) {
    const classes = useStyles();
    const open = Boolean(helpAnchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleMenuItemClick = async (action, phoneNumber) => {
        switch (action) {
            case "Call":
                if (!isNullOrEmpty(phoneNumber)) {
                    window.open(`tel:${phoneNumber}`, '_blank')
                }
                break;
            case "WhatsApp":
                if (!isNullOrEmpty(phoneNumber)) {
                    window.open(`https://api.whatsapp.com/send/?phone=91${phoneNumber}&type=phone_number&app_absent=0`, '_blank')
                }
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <Popover
                id={id}
                open={HelpOpen}
                anchorEl={helpAnchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List subheader={<ListSubheader style={{ fontWeight: 'bold', color: '#1976D2' }}>Resources</ListSubheader>} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div style={{ textAlign: 'left' }}>
                        <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <a href='https://www.youtube.com/@ezybillgstbillingsoftware4887' rel="noreferrer" target='_blank' style={{ color: '#000' }}>
                                <ListItemText id="switch-list-label-wifi" primary="Help/How to? Videos" style={{ marginRight: '70px' }} />
                                <ListItemSecondaryAction>
                                    <ButtonBase>
                                        <AppIcon name="YouTube" size="xl" style={{ color: 'red' }} />
                                    </ButtonBase>
                                </ListItemSecondaryAction>
                            </a>
                        </ListItem>
                    </div>
                </List>

                <List subheader={<ListSubheader style={{ fontWeight: 'bold', color: '#1976D2' }}>Support Numbers</ListSubheader>} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        <ListItemText id="switch-list-label-wifi" primary="+91 90190 59713" style={{ marginRight: '70px' }} />
                        <ListItemSecondaryAction>
                            <ButtonBase style={{ color: '#000', marginRight: '20px' }} onClick={() => handleMenuItemClick("Call", '9019059713')}>
                                <AppIcon name="PhoneTalk" style={{ color: 'blue' }} />
                            </ButtonBase>
                            <ButtonBase onClick={() => handleMenuItemClick("WhatsApp", '9019059713')}>
                                <AppIcon name="WhatsappIcon" style={{ color: 'green', fontSize: '20px' }} />
                            </ButtonBase>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        {<ListItemText id="switch-list-label-bluetooth" primary="+91 74834 63220" style={{ fontWeight: 'bold' }} />}
                        <ListItemSecondaryAction>

                            <ButtonBase style={{ color: '#000', marginRight: '20px' }} onClick={() => handleMenuItemClick("Call", '7483463220')}>
                                <AppIcon name="PhoneTalk" style={{ color: 'blue' }} />
                            </ButtonBase>
                            <ButtonBase onClick={() => handleMenuItemClick("WhatsApp", '7483463220')}>
                                <AppIcon name="WhatsappIcon" style={{ color: 'green', fontSize: '20px' }} />
                            </ButtonBase>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        {<ListItemText id="switch-list-label-bluetooth" primary="+91 63629 54414" style={{ fontWeight: 'bold' }} />}
                        <ListItemSecondaryAction>

                            <ButtonBase style={{ color: '#000', marginRight: '20px' }} onClick={() => handleMenuItemClick("Call", '6362954414')}>
                                <AppIcon name="PhoneTalk" style={{ color: 'blue' }} />
                            </ButtonBase>
                            <ButtonBase onClick={() => handleMenuItemClick("WhatsApp", '6362954414')}>
                                <AppIcon name="WhatsappIcon" style={{ color: 'green', fontSize: '20px' }} />
                            </ButtonBase>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <List subheader={<ListSubheader style={{ fontWeight: 'bold', color: '#1976D2' }}>Your Feedback</ListSubheader>} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        <a href='https://g.page/r/CXlkizyX5yZsEB0/review' target='_blank' style={{ color: '#000' }} rel='noreferrer'>
                            <ListItemText id="switch-list-label-wifi" primary="Google Review" style={{ marginRight: '70px' }} />
                            <ListItemSecondaryAction>
                                <ButtonBase>
                                    <AppIcon name="Review" size="xs" style={{ color: '#F6B907', fontSize: "18px" }} />
                                    <AppIcon name="Review" size="xs" style={{ color: '#F6B907', fontSize: "18px" }} />
                                    <AppIcon name="Review" size="xs" style={{ color: '#F6B907', fontSize: "18px" }} />
                                    <AppIcon name="Review" size="xs" style={{ color: '#F6B907', fontSize: "18px" }} />
                                    <AppIcon name="Review" size="xs" style={{ color: '#F6B907', fontSize: "18px" }} />
                                </ButtonBase>
                            </ListItemSecondaryAction>
                        </a>
                    </ListItem>
                </List>
                <List subheader={<ListSubheader style={{ fontWeight: 'bold', color: '#1976D2' }}>Important Links</ListSubheader>} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div style={{ textAlign: 'left' }}>
                        <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <a href='https://anydesk.com/' rel="noreferrer" target='_blank' style={{ color: '#000', textDecoration: 'none' }}>
                                <ListItemText id="switch-list-label-wifi" primary="Download AnyDesk" style={{ marginRight: '70px' }} />
                                <ListItemSecondaryAction>
                                    <ButtonBase>
                                        <img alt='downalod anydesk' src={anyDeskIcon} style={{ width: '25px', height: '25px' }} />
                                    </ButtonBase>
                                </ListItemSecondaryAction>
                            </a>
                        </ListItem>
                        <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <a href='https://services.gst.gov.in/services/searchhsnsac' rel="noreferrer" target='_blank' style={{ color: '#000', textDecoration: 'none' }}>
                                <ListItemText id="switch-list-label-wifi" primary="HSN Code Finder" style={{ marginRight: '70px' }} />
                                <ListItemSecondaryAction>
                                    <ButtonBase>
                                        <img alt='HSN Code Finder' src={hsnCodeFinder} style={{ width: '25px', height: '25px' }} />
                                    </ButtonBase>
                                </ListItemSecondaryAction>
                            </a>
                        </ListItem>
                        <ListItem className={classes.contextItemHover} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                            <a href='https://www.gst.gov.in/' rel="noreferrer" target='_blank' style={{ color: '#000', textDecoration: 'none' }}>
                                <ListItemText id="switch-list-label-wifi" primary="GST Portal Login" style={{ marginRight: '70px' }} />
                                <ListItemSecondaryAction>
                                    <ButtonBase>
                                        <img alt='GST Portal Login' src={gstPortal} style={{ width: '25px', height: '25px' }} />
                                    </ButtonBase>
                                </ListItemSecondaryAction>
                            </a>
                        </ListItem>
                    </div>
                </List>
            </Popover>
        </div>
    );
}
