import { Button, Portal } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import useStore from '../../Base/Store';
import { isNullOrEmpty, safeString } from '../../Base/Utils';
import shallow from 'zustand/shallow';

export default function Alert(props) {
    const history = useHistory();
    const alertOpen = useStore(state => state.alertOpen);
    const alertSeverity = useStore(state => state.severity);
    const alertPosition = useStore(state => state.alertPosition);
    const closeAlert = useStore(state => state.closeAlert);
    const alertMessage = useStore(state => state.alertMessage);
    const alertDelay = useStore(state => state.alertDelay);
    const alertLink = useStore(state => state.alertLink);
    const alertLinkCaption = useStore(state => state.alertLinkCaption);
    const [isSettingsDrawerOpen, toggleSettingsDrawer] = useStore(state => [state.isSettingsDrawerOpen, state.toggleSettingsDrawer], shallow);

    return (
        <Portal>
            <Snackbar open={alertOpen} autoHideDuration={alertDelay} onClose={closeAlert} anchorOrigin={{ vertical: alertPosition, horizontal: 'center' }}>
                <MuiAlert onClose={closeAlert} severity={alertSeverity} style={{ maxWidth: '100%', fontSize: 'bold' }}
                    action={
                        isNullOrEmpty(safeString(alertLink)) ? null
                            : <Button
                                variant='outlined'
                                onClick={() => {
                                    if (alertLink === 'Settings') {
                                        toggleSettingsDrawer(true)
                                    } else {
                                        history.push(alertLink);
                                    }
                                    closeAlert();
                                }}
                                color='inherit' size='small'><strong> {safeString(alertLinkCaption)}</strong></Button>}
                >
                    <strong> {alertMessage}</strong>

                </MuiAlert>
            </Snackbar>
        </Portal>
    );

};
