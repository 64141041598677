import { Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import AppIcon from '../../../UI/Controls/AppIcons';
import useStyles from '../../../../styles';
import { ifNullOrEmpty, isNullOrEmpty, isNullOrUndefined } from '../../../Base/Utils';
import shallow from 'zustand/shallow';
import useStore from '../../../Base/Store';
import { PageHeader } from '../../../UI/Controls/Controls';
import { ERROR_INTERNAL_SERVER } from '../../../Base/Common';
const SignatureCapture = ({ authorisedSignature }) => {
    const classes = useStyles();
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const signaturePad = useRef(null);
    const theme = useTheme();
    const showAlert = useStore(state => state.showAlert);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!isNullOrEmpty(authorisedSignature)) {
            setTimeout(() => {
                signaturePad.current.fromDataURL(authorisedSignature, 'image/png');
            });
        }
    }, []);
    const submitFormData = () => {
        try {

            if (!isNullOrUndefined(signaturePad.current) && !signaturePad.current.isEmpty() && !isNullOrUndefined(signaturePad.current.getCanvas())) {
                setDialogResponse(signaturePad.current.getCanvas().toDataURL('image/png'));
                closeDialog();
            } else {
                setDialogResponse("");
                closeDialog();
            }
        } catch (error) {
            showAlert(ERROR_INTERNAL_SERVER, 'error');
            console.log(error);
        } finally {

        }
    }
    return (
        <div className={classes.page}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PageHeader heading={'Capture signature'}>
                        <AppIcon name="Pen" color="primary" />
                    </PageHeader>
                </Grid>
                <Grid item xs={12}>
                    <ReactSignatureCanvas ref={signaturePad}
                        penColor='blue' canvasProps={{className: mobileDevice ? 'signaturePanelMobile' : 'signaturePanel' }} />
                </Grid>
                <Grid item xs={12}>
                    <Button color="primary" variant="outlined" startIcon={<AppIcon name="Save" />}
                        onClick={() => {
                            submitFormData()
                        }}
                    >Save</Button>
                    <Button className={classes.ml1} color="warning" variant="outlined" startIcon={<AppIcon name="Cancel" />}
                        onClick={() => {
                            signaturePad.current.clear();
                            /*   closeDialog(); */
                        }}
                    >Clear</Button>
                </Grid>
            </Grid>


        </div>
    );
}

export default SignatureCapture;
