import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { safeBool } from '../../../Base/Utils';
import useStyles from './../../../../styles';
import '../style.css';

export const SettingsHeader = ({ heading, subHeading, isSearchVisiable }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid item xs={12} sm={12} container spacing={1}>
                <Grid item xs={12} >
                    <Box>
                        <Typography variant={mobileDevice ? "h6" : "h5"} gutterBottom>
                            {heading}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={mobileDevice ? "caption" : ""} display="block" gutterBottom>
                            {subHeading}
                        </Typography>
                    </Box>
                </Grid>
            </Grid >
        </>

    );
}

