import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import RealeseNoteObject from './RealseNotes.json';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const RealseNotes = () => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const showReleseNotes = localStorage.getItem('showReleseNotes');
        if (showReleseNotes === "true") {
            setTimeout(() => {
                setOpen(true);
            }, 2000);
        } else {
            setOpen(false);
        }
    }, [])

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem('showReleseNotes', 'false');
    };
    const latestRelease = RealeseNoteObject.find(release => release.latest);

    return (
        <div>
            <Dialog
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' }
                }}
                open={open}
                maxWidth="md"
                fullWidth>
                <DialogContent>
                    <div>
                        {latestRelease && (
                            <div>
                                <Typography variant="h6" style={{ marginBottom: '30px', textAlign: 'center' }}>
                                    Release Notes App Version: {latestRelease.version}
                                </Typography>
                                {latestRelease.notes.map((section, idx) => (
                                    <div key={idx} style={{ marginLeft: '20px' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                            {section.heading}
                                        </Typography>
                                        <List style={{ padding: '3px', marginTop: '2px', fontFamily: 'monospace' }}>
                                            {section.features.map((feature, i) => (
                                                <ListItem key={i} style={{ paddingLeft: '0', paddingTop: '0', paddingBottom: '0' }}>
                                                    <ListItemIcon>
                                                        <ArrowRightAltIcon fontSize="small" color='primary' />
                                                    </ListItemIcon>
                                                    <ListItemText primary={feature} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant='contained'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RealseNotes;
