
import { createFilterOptions } from '@material-ui/lab';
import { wrapTextInBrackets } from '../../../Base/Utils';
import { PartyDescription } from '../Controls';
import { SelectInput, TextInput } from '../InputFields';
import { CUSTOMER_ENTITY } from '../../../Base/Common';
import { List } from "react-virtualized";
import React from "react";
import './style.css';

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, role, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 62;

    // Get the current window width
    const windowWidth = window.innerWidth;
    const listWidth = windowWidth < 540 ? windowWidth : 540;

    return (
        <div ref={ref} style={{ position: 'relative' }}>
            <div {...other}>
                <List
                    height={200}
                    width={listWidth}
                    rowHeight={itemSize}
                    overscanCount={5}
                    rowCount={itemCount}
                    rowRenderer={props => {
                        return React.cloneElement(children[props.index], {
                            style: props.style
                        });
                    }}
                    role={role}

                />
            </div>
        </div>
    );
});

export default function SelectParty({ parties, selectedParty, handleSelectionChanged, referenceType = CUSTOMER_ENTITY, isRequired, boldInputText = false }) {
    const filter = createFilterOptions();
    return (
        <SelectInput
            searchable={true}
            id="referenceId"
            name="referenceId"
            placeholder={`Select ${referenceType}`}
            options={parties}
            ListboxComponent={ListboxComponent}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    if (referenceType === CUSTOMER_ENTITY) {
                        filtered.push({
                            inputValue: params.inputValue,
                            legalName: `+ Add New Customer`,
                            addNew: true
                        });
                    } else {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `+ Add New Supplier`,
                            addNew: true
                        });
                    }
                }
                return filtered;
            }}
            getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                if (referenceType === CUSTOMER_ENTITY) {
                    return option.legalName + wrapTextInBrackets(option.shortName);
                } else {
                    return option.name + wrapTextInBrackets(option.shortName);
                }
            }}
            renderOption={
                (option) => <PartyDescription party={option} referenceType={referenceType} />
            }
            value={selectedParty}
            onChange={handleSelectionChanged}
            renderInput={(props) =>
                <TextInput fullWidth name="referenceName"  {...props} label={referenceType} variant="outlined" required={isRequired} tabindex="-1"
                    placeholder={`Select ${referenceType}`}
                    boldInputText={boldInputText} />
            }
        />
    )
};
