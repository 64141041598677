import React, { useContext, useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Collapse, Avatar, Typography, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AppIcon from '../Controls/AppIcons'; // Replace with your icon component
import AuthContext from '../../Auth/AuthContext';
import { isNullOrUndefined } from '../../Base/Utils';
import { useHistory } from "react-router";
import QuickInfoDrawer from '../Controls/QuickInfoDrawer';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import ApplicationSettings from '../../User/Settings/ApplicationSettings';
import config from '../../../config.json';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 240,
        flexShrink: 0,
        zIndex: 1500,
    },
    drawerPaper: {
        width: '100vw',
        background: 'linear-gradient(to bottom, #131921, #232F3E)',
        color: '#fff',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItem: {
        color: 'white',
    },
    bottomText: {
        marginTop: 'auto',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        paddingBottom: "10px"
    },
    // menuListContainer: {
    //     display: 'flex-start',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     /*   marginBottom: theme.spacing(1), */
    //     borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    // },
    avatarSideBar: {
        marginRight: theme.spacing(1),
        background: '#131921'
    },
    listItemText: {
        color: 'white',
    },
    iconSideBar: {
        marginRight: theme.spacing(1),
    },
    selectedListItem: {
        backgroundColor: 'rgba(256, 0, 0, 0.8)',
    },
    menuListContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    menuItems: {
        borderBottom: '1px solid #131921',
        background: '#131921',
        paddingLeft: theme.spacing(4),
    },
    iconTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '16px'
    }
}));

const MobileSideDrawer = ({ open, handleOpenMobileDrawer }) => {
    const classes = useStyles();
    const [menuList, setMenuList] = useState([]);
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [isSettingsDrawerOpen, toggleSettingsDrawer] = useStore(state => [state.isSettingsDrawerOpen, state.toggleSettingsDrawer], shallow);

    const handleSettingsDrawerToggle = () => {
        toggleSettingsDrawer(!isSettingsDrawerOpen);
        handleOpenMobileDrawer();
    };


    useEffect(() => {
        const appModules = authContext.appModules || [];
        const menus = appModules.filter((module) => module.parentId === 0 && module.allowView);
        const menuItems = menus.map((menu) => ({
            ...menu,
            isExpanded: false,
            items: appModules.filter((subModule) => subModule.parentId === menu.appModuleId && subModule.allowView),
        }));

        setMenuList(menuItems);
    }, [authContext.appModules]);

    const toggleExpansion = (index) => {
        setMenuList((prevMenuList) =>
            prevMenuList.map((menu, i) => ({
                ...menu,
                isExpanded: i === index ? !menu.isExpanded : false,
            }))
        );
    };

    function navMenuClickHandler(e) {
        history.push(e.currentTarget.attributes.path.value);
        var menus = [...menuList];
        var selectedMenuId = e.currentTarget.id;
        for (let index = 0; index < menus.length; index++) {
            var menu = menus[index];
            if (menu.id.toString() === selectedMenuId) {
                menu.isSelected = true;
            } else {
                if (!!menu.items && menu.items.length > 0) {
                    for (let index = 0; index < menu.items.length; index++) {
                        const menuItem = menu.items[index];
                        if (menuItem.id.toString() === selectedMenuId) {
                            menuItem.isSelected = true;
                        } else {
                            menuItem.isSelected = false;
                        }
                    }
                } else {
                    menu.isSelected = false;
                }
            }
        }
        setMenuList(menus);
        handleOpenMobileDrawer();
    }

    return (
        <Drawer
            className={classes.drawer}
            open={open}
            onClose={handleOpenMobileDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
            variant="temporary"
        >
            <Box display="flex" flexDirection="column" height="100%">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <IconButton color="secondary" onClick={() => console.log('Settings clicked')}>
                        {/*  <SettingsIcon style={{ color: '#fff' }} /> */}
                    </IconButton>
                    <IconButton color="primary" onClick={handleOpenMobileDrawer}>
                        <CloseIcon style={{ color: '#fff' }} />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" marginBottom={2} padding={1}>
                    {
                        !isNullOrUndefined(authContext.currentSubscriber.logo) ?
                            <Avatar onClick={() => {
                                history.push('/user-profile');
                                handleOpenMobileDrawer()
                            }} alt="User Avatar" src={authContext.currentSubscriber.logo} className={classes.avatar} /> :
                            <Avatar alt="User Avatar" src="https://via.placeholder.com/150" className={classes.avatar}
                                onClick={() => {
                                    history.push('/user-profile');
                                    handleOpenMobileDrawer()
                                }} />
                    }
                    <Typography variant="h6" onClick={() => {
                        history.push('/user-profile');
                        handleOpenMobileDrawer()
                    }}>{authContext.currentSubscriber.legalName}</Typography>
                    <Typography variant="body2">{authContext.currentSubscriber.taxIdNumber}</Typography>
                    <Box display="flex" justifyContent="center">
                        <Box className={classes.iconTextContainer} onClick={handleSettingsDrawerToggle}>
                            <Avatar className={classes.avatarSideBar}>
                                <AppIcon name="Settings" size="small" />
                            </Avatar>
                            <Typography variant="body2">App Settings</Typography>
                        </Box>
                        <Box className={classes.iconTextContainer} onClick={() => {
                            history.push('/subscription');
                            handleOpenMobileDrawer()
                        }}>
                            <Avatar className={classes.avatarSideBar}>
                                <AppIcon name="Subscription" size="small" />
                            </Avatar>
                            <Typography variant="body2">Subscription</Typography>
                        </Box>
                        <Box className={classes.iconTextContainer} onClick={() => {
                            history.push('/changePassword');
                            handleOpenMobileDrawer()
                        }}>
                            <Avatar className={classes.avatarSideBar}>
                                <AppIcon name="Password" size="small" />
                            </Avatar>
                            <Typography variant="body2">Password</Typography>
                        </Box>
                    </Box>
                </Box>
                <List component="nav" style={{ marginBottom: '24px' }}>
                    {menuList.map((menu, index) => {
                        if (menu.items.length > 0) {
                            return (
                                <React.Fragment key={menu.appModuleId}>
                                    <ListItem
                                        className={classes.menuListContainer}
                                        button
                                        onClick={() => toggleExpansion(index)}
                                        selected={menu.isExpanded}
                                    >
                                        <Avatar className={classes.avatarSideBar}>
                                            <AppIcon name={menu.iconName} size="small" />
                                        </Avatar>
                                        <ListItemText primary={menu.name} className={classes.listItemText} />
                                        {menu.isExpanded ? (
                                            <AppIcon name="ExpandLess" size="medium" style={{ color: '#fff' }} />
                                        ) : (
                                            <AppIcon name="RightChevron" size="medium" style={{ color: '#fff' }} />
                                        )}
                                    </ListItem>
                                    <Collapse in={menu.isExpanded} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding className={classes.menuItems}>
                                            {menu.items.map((menuItem) => (
                                                <ListItem
                                                    key={menuItem.appModuleId}
                                                    button
                                                    onClick={navMenuClickHandler}
                                                    selected={menuItem.isSelected}
                                                    path={menuItem.pageUrl}
                                                    id={menuItem.id}
                                                    className={`${menuItem.isSelected ? classes.selectedListItem : ''}`}
                                                >
                                                    <span className={classes.iconSideBar}>
                                                        <AppIcon name={menuItem.iconName} size="small" />
                                                    </span>
                                                    <ListItemText primary={menuItem.name} className={classes.listItemText} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <ListItem key={menu.appModuleId} button size="small" className={classes.menuListContainer} selected={menu.isSelected}
                                    path={menu.pageUrl} onClick={navMenuClickHandler}>
                                    <Avatar className={`${classes.avatarSideBar} ${classes.mr1}`}>
                                        <AppIcon name={menu.iconName} size="small" />
                                    </Avatar>
                                    <ListItemText primary={menu.name} className={classes.listItemText} />
                                </ListItem>
                            )
                        }
                    })}
                </List>
                <Box className={classes.bottomText}>
                    <span style={{ color: 'gray', fontFamily: 'sans-serif' }}><span style={{ color: '#0898E7', fontSize: '1em' }}>ezy</span><span style={{ color: '#00C853', fontSize: '1em' }}>bill</span> v{config.VERSION}</span>
                    <p style={{ textAlign: 'center', fontSize: '10px', marginTop: '3px' }} >{' \u00A9' + new Date().getFullYear() + ' EMERSYS LLP'}</p>
                </Box>
            </Box>
            <QuickInfoDrawer isFullWidth={true} isInfoDrawerOpen={isSettingsDrawerOpen}
                toggleDrawerOpen={handleSettingsDrawerToggle}
                title={'Settings'} content={<ApplicationSettings close={handleSettingsDrawerToggle} />} />
        </Drawer>


    );
};

export default MobileSideDrawer;
