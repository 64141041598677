import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, ListItemAvatar } from '@material-ui/core';
import AppIcon from './AppIcons';
import { safeBool } from '../../Base/Utils';

const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#000',
            borderRadius: '5px',
            cursor: 'pointer'
        },
    },
    appIcon: {
        marginRight: theme.spacing(2),
    },
    subheading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left'
    },
}));

const UCListButton = ({ path, iconColor, iconName, iconSize = "lg", primaryText = "", secondaryText = "", disable = false, handleButtonClick, isImage = false, imageName = "" }) => {
    const classes = useStyles();

    const handleClick = (e) => {
        if (!disable) {
            handleButtonClick(e);
        }
    };

    return (
        <div className={classes.section} onClick={handleClick} path={path} style={{ opacity: disable ? 0.5 : 1, pointerEvents: disable ? 'none' : 'auto' }}>
            <div className={classes.appIcon}>
                <div style={{
                    height: '27px',
                    width: '27px',
                    color: iconColor,
                    borderRadius: '23%',
                    border: `1px solid ${iconColor}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {
                        safeBool(isImage) ?
                            <>
                                <ListItemAvatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
                                    <img src={imageName} alt="Account Payable" style={{ color: iconColor, marginLeft: '2px', maxWidth: '35px', maxHeight: '35px' }} />
                                </ListItemAvatar>
                            </>
                            :
                            <>
                                <ListItemAvatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
                                    <AppIcon name={iconName} size={iconSize} style={{ fontSize: "18px" }} />
                                </ListItemAvatar>
                            </>
                    }

                </div>
            </div>
            <div className={classes.subheading}>
                <Typography variant='caption' style={{ color: "#fff" }}>
                    {primaryText}
                </Typography>
                <Typography variant='caption' style={{ fontFamily: 'roboto', fontStyle: 'italic', color: 'lightsteelblue' }}>
                    {secondaryText}
                </Typography>
            </div>
        </div>
    );
}

export default UCListButton;
