import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from '../../../styles';
import { Fragment } from 'react';
import { exportToCsv, isNullOrUndefined, safeBool, safeString } from '../../Base/Utils';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color="action" />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const MuiTable = (props) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  var collapsedPanel = null;
  const cellPaddingTop = safeBool(mobileDevice) ? 16 : 8;
  const cellPaddingBottom = safeBool(mobileDevice) ? 8 : 4;
  var componentObj = props.toolbar === false ? {
    Toolbar: props => <Fragment></Fragment>
  } : null;


  if (mobileDevice === true) {
    if (props.hasMobileView === true) {
      // Hide all columns except marked as mobileOnly
      for (let index = 0; index < props.columns.length; index++) {
        const colDef = props.columns[index];
        if (colDef.mobileOnly === false || colDef.mobileOnly === undefined) {
          colDef['hidden'] = true;
        }
      }
    } else {
      // Hide all columns except marked as alwaysVisible
      for (let index = 0; index < props.columns.length; index++) {
        const colDef = props.columns[index];
        if (colDef.alwaysVisible === false || colDef.alwaysVisible === undefined) {
          colDef['hidden'] = true;
        }
        else {
          delete colDef['hidden'];
        }
      }
      collapsedPanel = function (rowData) {
        return (
          <div className={classes.rowDetailsContainer}>
            {props.columns.map((col, index) => (
              (col.alwaysVisible === false || col.alwaysVisible === undefined) ?
                <div key={col.title}>
                  <Typography display="inline" variant="caption" color="primary" className={classes.pl2}>
                    <b>{col.title}:</b>
                  </Typography>
                  <Typography display="inline" variant="subtitle2" className={classes.pl2}>
                    {rowData[col.field]}
                  </Typography>
                </div>
                : null
            ))}
          </div>
        )
      };
    }

  } else {
    for (let index = 0; index < props.columns.length; index++) {
      const colDef = props.columns[index];
      if (colDef.alwaysVisible === false || colDef.alwaysVisible === undefined) {
        if (colDef['hidden'] === false) {
          colDef['hidden'] = false;
        }
      }
      if (colDef.mobileOnly === true) {
        colDef['hidden'] = true;
      }
    }
  }


  const getTableHeaderStyle = (style) => {
    if (isNullOrUndefined(style)) {
      return {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
      }
    } else {
      switch (style) {
        case 'default':
          return {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
        case 'info':
          return {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
        case 'error':
          return {
            backgroundColor: '#F5C6CB',
            color: theme.palette.warning.contrastText,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
        case 'warning':
          return {
            backgroundColor: '#FFEEBA',
            color: theme.palette.warning.contrastText,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
        case 'secondary':
          return {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
        case 'success':
          return {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.contrastText,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
        default:
          return {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.contrastText,
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
            display: ((props.hideHeader === true) || (props.hasMobileView === true && mobileDevice === true)) ? 'none' : null,
          }
      }
    }
  }

  return (
    <MaterialTable icons={tableIcons}
      {...props}
      detailPanel={collapsedPanel}
      localization={
        {
          header: {
            actions: !!props.headeraction ? props.headeraction : ''
          },
          body: {
            emptyDataSourceMessage: ''
          },
        }
      }
      onRowClick={(event, rowData, togglePanel) => !!collapsedPanel ? togglePanel() : isNullOrUndefined(props.rowClick) ? () => { } : props.rowClick(event, rowData)}

      options={{
        exportAllData: true,
        search: props.search,
        searchAutoFocus: false,
        actionsColumnIndex: (mobileDevice && (props.hasMobileView || props.actionsColumnIndex == 0)) ? 0 : -1,
        paging: props.paging,
        emptyRowsWhenPaging: false,
        pageSize: 100,
        pageSizeOptions: [100, 200, 500],
        searchFieldAlignment: 'left',
        title: safeString(props.title),
        showTitle: isNullOrUndefined(props.title) ? false : true,
        exportButton: props.exportButton,
        exportFileName: props.exportFileName,
        exportCsv: exportToCsv, //(columns, data) => exportToCsv(columns, data, props.exportFileName),
        grouping: safeBool(props.grouping),
        sorting: safeBool(props.sorting),
        selection: props.selection,
        headerStyle: getTableHeaderStyle(props.headerStyle),
        cellStyle: {
          paddingTop: cellPaddingTop,
          paddingBottom: cellPaddingBottom,
        },
        localization: {
          header: {
            actions: 'Actions'
          }
        },
        doubleHorizontalScroll: safeBool(props.doubleHorizontalScroll),
        defaultExpanded: true
      }}
      components={componentObj}
    />
  )

};

export default MuiTable;

