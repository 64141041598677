import WebAPI from "../Base/WebAPI";
import * as Common from '../Base/Common';
import * as Utility from '../Base/Utils';
import config from '../../config.json';

export async function getDataList(entityName, methodName, request, authContext, cancelToken, webmethod = 'post') {
    try {
        var url = '/' + entityName + '/' + methodName + '/';
        var response = null;
        if (!Utility.isNullOrUndefined(request)) {
            if (webmethod === 'post') {
                response = await WebAPI.post(url, request, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
            } else {
                response = await WebAPI.put(url, request, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
            }
        } else {
            response = await WebAPI.get(url, request, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
        }

        return response?.data;
    } catch (error) {
        console.log(error);
        if (WebAPI.isCancel(error) === true) {
            console.log(entityName + ' list: request cancelled.');
        }
        return null;
    }
}

export async function getData(entityName, methodName, authContext, cancelToken, queryString = '') {
    try {
        var url = '/' + entityName + '/' + methodName + '/' + queryString;
        const response = await WebAPI.get(url, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
        return response.data;
    } catch (error) {
        if (WebAPI.isCancel(error) === true) {
            console.log(entityName + ' : request cancelled.');
        }
        return null;
    }
}

export async function getFilteredEntityList(entityName, request, authContext, cancelToken, lite = false) {
    try {
        let url = '';
        let methodName = entityName;
        if (lite === true) {
            methodName += 'Lite';
        }
        switch (entityName) {
            case Common.PRODUCT_ENTITY:
                url = '/' + entityName + '/' + methodName + 'List/' + authContext.currentUser.subscriberId;
                break;
            case Common.DEBIT_NOTE_ENTITY:
            case Common.CREDIT_NOTE_ENTITY:
            case Common.DELIVERY_CHALLAN_ENTITY:
            case Common.SALES_ORDER_ENTITY:
            case Common.QUOTATION_ENTITY:
                url = '/' + entityName + '/Get' + methodName + 'List';
                break;
            case Common.PURCHASE_ORDER_ENTITY:
            case Common.PURCHASE_BILL_ENTITY:
            case Common.INVOICE_ENTITY:
                url = '/' + entityName + '/Get' + methodName + 'List';
                break;
            default:
                url = '/' + entityName + '/Get' + methodName + 'List/' + authContext.currentUser.subscriberId;
                break;
        }

        var response = await WebAPI.post(url, request, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
        return response.data;
    } catch (error) {
        if (WebAPI.isCancel(error) === true) {
            console.log(entityName + ' list: request cancelled.');
        }
        return null;
    }
}

export async function getEntityList(entityName, authContext, cancelToken, lite = false) {
    try {
        let url = '';
        let methodName = entityName;
        if (lite === true) {
            methodName += 'Lite';
        }
        switch (entityName) {
            case Common.PRODUCT_ENTITY:
                url = '/' + entityName + '/' + methodName + 'List/' + authContext.currentUser.subscriberId;
                break;
            case Common.INVENTORY_ENTITY:
                url = '/' + entityName + '/' + methodName + 'List';
                break;
            case Common.DEBIT_NOTE_ENTITY:
            case Common.CREDIT_NOTE_ENTITY:
            case Common.DELIVERY_CHALLAN_ENTITY:
            case Common.QUOTATION_ENTITY:
                url = '/' + entityName + '/Get' + methodName + 'List';
                break;
            case Common.PURCHASE_ORDER_ENTITY:
            case Common.PURCHASE_BILL_ENTITY:
            case Common.INVOICE_ENTITY:
                url = '/' + entityName + '/Get' + methodName + 'List';
                break;
            case Common.RECEIVABLES_ENTITY:
            case Common.PAYABLES_ENTITY:
                url = '/Payments/Get' + methodName + 'List';
                break;
            default:
                url = '/' + entityName + '/Get' + methodName + 'List/' + authContext.currentUser.subscriberId;
                break;
        }

        var response = await WebAPI.get(url, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
        return response.data;
    } catch (error) {
        if (WebAPI.isCancel(error) === true) {
            console.log(entityName + ' list: request cancelled.');
        }
        return null;
    }
}

export function getMasterData(type, authContext) {
    return authContext.masterData.filter(md => md.type === type);
}

export async function getLinkedTypesList(type, authContext, cancelToken) {
    try {
        var response = await WebAPI.get('/LinkedTypes/LinkedTypesList/' + type, { headers: { "AccessToken": authContext.token }, cancelToken: cancelToken });
        return response.data;
    } catch (error) {
        if (WebAPI.isCancel(error) === true) {
            console.log('getLinkedTypesList request cancelled.');
        }
        return null;
    }
}

export function getDefaultStateCode(authContext) {
    try {
        var stateCode = 0;
        if (!Utility.isNullOrUndefined(authContext.currentSubscriber)) {
            if (Utility.safeString(authContext.currentSubscriber.taxIdNumber).length > 2) {
                stateCode = Utility.safeInt(Utility.safeString(authContext.currentSubscriber.taxIdNumber).substring(0, 2));
            } else {
                var subscriberAddress = authContext.currentSubscriber.addressList[0];
                if (!Utility.isNullOrUndefined(subscriberAddress)) {
                    stateCode = subscriberAddress.stateId;
                }
            }
        }
        return stateCode;

    } catch (error) {
        return stateCode;
    }
}

export async function getNotificationCount(authContext, setNotificationCount) {
    setTimeout(async () => {
        try {
            var response = await getDataList(Common.ALETRS_ENTITY, "ListAlertsGrouped", { applicationId: config.APPLICATION_ID }, authContext, null, 'put');
            if (!Utility.isNullOrUndefined(response) && !Utility.isNullOrUndefined(response.code) && response.code > 0) {
                console.log(response.error);
            } else {
                setNotificationCount(response.length)
                // console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
    }, 10000);
}

// export async function getPushNotification(authContext, setPushNotification) {
//     setTimeout(async () => {
//         try {
//             var response = await getDataList(Common.ALETRS_ENTITY, "ListAlertsGrouped", { applicationId: config.APPLICATION_ID }, authContext, null, 'put');
//             if (!Utility.isNullOrUndefined(response) && !Utility.isNullOrUndefined(response.code) && response.code > 0) {
//                 console.log(response.error);
//             } else {
//                 setPushNotification(response);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }, 10000);
// }
