import { ButtonBase, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import AppIcon from "./AppIcons";
import useStyles from "../../../styles";

export default function ContextMenuItem({ name = "", caption = "", iconColor = "", iconName = "", iconSize = "20px", handleClick = (e) => { } }) {
    const classes = useStyles();
    return (
        <span name={name} onClick={handleClick}>
            <ListItem style={{ cursor: 'pointer' }} className={classes.contextItemHover}>
                <ListItemText primary={caption} style={{ marginRight: '21px' }} />
                <ListItemSecondaryAction>
                    <ButtonBase>
                        <AppIcon name={iconName} color={iconColor} style={{ color: iconColor, fontSize: iconSize }} />
                    </ButtonBase>
                </ListItemSecondaryAction>
            </ListItem>
        </span>
    )
};
