import { Avatar, Button, ButtonBase, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@material-ui/core";
import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import useStyles from "../../styles";
import useStore from "../Base/Store";
import AppIcon from "../UI/Controls/AppIcons";
import * as DataProvider from "../Base/DataProvider";
import * as Common from "../Base/Common";
import * as Utility from "../Base/Utils";
import AuthContext from "../Auth/AuthContext";
import { useHistory } from "react-router-dom";
import config from '../../config.json';

export default function Notifications() {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [notificationCount, setNotificationCount] = useStore(state => [state.notificationCount, state.setNotificationCount], shallow);


    const [alertsGrouped, setAltersGrouped] = useState(null);
    const showAlert = useStore(state => state.showAlert);
    const history = useHistory();
    const theme = useTheme();
    const [isInfoDrawerOpen, toggleInfoDrawer] = useStore(state => [state.isInfoDrawerOpen, state.toggleInfoDrawer], shallow);

    const getList = async (token) => {
        setWaiting(true);
        try {
            var response = await DataProvider.getDataList(Common.ALETRS_ENTITY, "ListAlertsGrouped", { applicationId: config.APPLICATION_ID }, authContext, token, 'put');
            if (!Utility.isNullOrUndefined(response) && !Utility.isNullOrUndefined(response.code) && response.code > 0) {
                showAlert(response.message, 'error');
            } else {
                setAltersGrouped(response);
                setNotificationCount(response.length)
                // console.log(response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }
    const handleAlertClick = async (alertTypeId) => {
        toggleInfoDrawer(false);                
        switch (Utility.safeInt(alertTypeId)) {
            case Common.ALERT_TYPE_LOW_STOCK:
                history.push("/lowstockitems");
                break;
            case Common.ALERT_TYPE_OUT_OF_STOCK:
                history.push("/outofstockitems");
                break;
            case Common.ALERT_TYPE_CUSTOMER_PAYMENT_DUE:
                history.push("/outofstockitems");
                break;
            // case Common.ALERT_TYPE_SUPPPLIER_PAYMENT_DUE:
            //     return "RupeeIcon";
            // case Common.ALERT_TYPE_CREDIT_LIMIT:
            // case Common.ALERT_TYPE_CREDIT_DAYS:
            // case Common.ALERT_TYPE_ITEMS_EXPIRED:
            // case Common.ALERT_TYPE_ITEMS_EXPIRING:
            //     return "Warning";
            default:
                break;
        }
    }

    function getListItemByAlertType(alert) {
        switch (Utility.safeInt(alert.alertTypeId)) {
            case Common.ALERT_TYPE_LOW_STOCK:
                return (
                    <ButtonBase onClick={() => handleAlertClick(alert.alertTypeId)}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarWarning}>
                                    <AppIcon name="ProductsIcon" style={{ color: "#ff9800" }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography style={{ color: "#ff9800" }} variant="subtitle1">{alert.alertTypeName + ' (' + alert.alertCount + ')'}</Typography>} secondary={<span className={classes.linkButton} variant="text" color="primary">{alert.alertCount + ' item(s) currently running low stock. Click to view items.'}</span>} />
                        </ListItem>
                    </ButtonBase>

                );
            case Common.ALERT_TYPE_OUT_OF_STOCK:
                return (
                    <ButtonBase onClick={() => handleAlertClick(alert.alertTypeId)}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarError}>
                                    <AppIcon name="ProductsIcon" color="error" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Typography color="error" variant="subtitle1">{alert.alertTypeName + ' (' + alert.alertCount + ')'}</Typography>} secondary={<span className={classes.linkButton} variant="text" color="primary">{alert.alertCount + ' item(s) currently out of stock. Click to view items.'}</span>} />
                        </ListItem>
                    </ButtonBase>

                );
            // case Common.ALERT_TYPE_CUSTOMER_PAYMENT_DUE:
            // case Common.ALERT_TYPE_SUPPPLIER_PAYMENT_DUE:
            //     return "RupeeIcon";
            // case Common.ALERT_TYPE_CREDIT_LIMIT:
            // case Common.ALERT_TYPE_CREDIT_DAYS:
            // case Common.ALERT_TYPE_ITEMS_EXPIRED:
            // case Common.ALERT_TYPE_ITEMS_EXPIRING:
            //     return "Warning";
            default:
                return (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarError}>
                                <AppIcon name="Notifications" color="error" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<Typography color="error" variant="subtitle1">{alert.alertTypeName + ' (' + alert.alertCount + ')'}</Typography>} secondary={<ButtonBase className={classes.linkButton} variant="text" color="primary">{alert.alertCount + ' ' + alert.alertTypeName + '. Click to view items.'}</ButtonBase>} />
                    </ListItem>
                );
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            await getList(source.token)
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);

    return (
        <div>
            <List>

                {!Utility.isNullOrUndefined(alertsGrouped) && alertsGrouped.map((alert, index) => {
                    return (
                        <div key={index}>
                            {
                                getListItemByAlertType(alert)
                            }
                            <Divider />
                        </div>
                    )
                })}
            </List>
        </div>
    );
};
