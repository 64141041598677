import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Popover, TextField, Typography, Tooltip, Badge } from '@material-ui/core';
import AppIcon from '../UI/Controls/AppIcons';
import axios from 'axios';
import useStore from '../Base/Store';
import shallow from 'zustand/shallow';
import { getEntityList } from '../Base/DataProvider';
import { filterOptions, isNullOrUndefined, safeBool, safeInt, safeString } from '../Base/Utils';
import { Autocomplete } from '@material-ui/lab';
import { CUSTOMER_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY } from '../Base/Common';
import AuthContext from '../Auth/AuthContext';
import { DateInput, TextInput } from '../UI/Controls/InputFields';
import useStyles from '../../styles';
import { getEmployees } from '../Employee/EmployeeHelper';
import { getProjectList } from '../Business/Project/ProjectBusiness';

const OrderListViewFilter = ({ buttonLabel, referenceType, setFieldValue, values, iconName, submitFormData, buttonStyle, buttonClass, entityName }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [parties, setParties] = useState(null);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    const [filters, setFilters] = useState([
        `From Date: ${values?.fromDate}`,
        `To Date: ${values?.toDate}`,
    ]);
    const authContext = useContext(AuthContext);
    const salesPersonModuleEnabled = safeBool(authContext.settings.enableSalesPersonModule);
    const showAlert = useStore(state => state.showAlert);
    const classes = useStyles();
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);

    const handleSortClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const filterOpen = Boolean(filterAnchorEl);
    const filterId = filterOpen ? 'filter-popover' : undefined;

    const getList = async (token = null) => {
        try {
            setWaiting(true);
            const partyList = await getEntityList(referenceType, authContext, token, true);
            if (entityName === INVOICE_ENTITY && salesPersonModuleEnabled) {
                const employeeList = await getEmployees(authContext, showAlert, setEmployees, token);
                if (!isNullOrUndefined(employeeList.code) && employeeList.code > 0) {
                    showAlert(employeeList.message, 'error');
                } else {
                    setEmployees(employeeList);
                }
                if (safeInt(values?.employeeId) > 0) {
                    let employee = employeeList.find(p => p.id === safeInt(values?.employeeId));
                    setSelectedEmployee(employee);
                } else {
                    setSelectedEmployee(null);
                }
            }
            if (entityName === INVOICE_ENTITY || entityName === PURCHASE_BILL_ENTITY) {
                const projectList = await getProjectList(authContext);
                if (!isNullOrUndefined(projectList.code) && projectList.code > 0) {
                    showAlert(projectList.message, 'error');
                } else {
                    setProjectList(projectList);
                }
                if (safeInt(values?.projectId) > 0) {
                    let project = projectList.find(p => p.id === safeInt(values?.projectId));
                    setSelectedProject(project);
                } else {
                    setSelectedProject(null);
                }
            }
            if (!isNullOrUndefined(partyList.code) && partyList.code > 0) {
                showAlert(partyList.message, 'error');
            } else {
                setParties(partyList);
                if (safeInt(values?.referenceId) > 0) {
                    let party = partyList.find(p => p.id === safeInt(values?.referenceId));
                    setSelectedParty(party);
                } else {
                    setSelectedParty(null);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            await getList(source.token);
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);

    const handlePartySelectionChange = async (values, setFieldValue, value) => {
        if (!isNullOrUndefined(value)) {
            setSelectedParty(value);
            setFieldValue('selectedParty', value);
            setFieldValue('referenceId', safeInt(value.id));
            setFilters([
                `From Date: ${values?.fromDate}`,
                `To Date: ${values?.toDate}`,
                `Name: ${referenceType === CUSTOMER_ENTITY ? value?.legalName : values.name}`
            ]);
        } else {
            setSelectedParty(null);
            setFieldValue('referenceId', 0);
            setFieldValue('referenceName', '');
            setFilters([]);
            setFieldValue('selectedParty', null);
        }
    }

    const handleSubmit = (values) => {
        submitFormData(values);
        setFilters([
            `From Date: ${values?.fromDate}`,
            `To Date: ${values?.toDate}`,
            !isNullOrUndefined(values?.selectedParty) ? `Name: ${safeString(referenceType === CUSTOMER_ENTITY ? values?.selectedParty?.legalName : values?.selectedParty?.name)}` : "",
            !isNullOrUndefined(selectedEmployee)
                ? `Sales Person: ${entityName === INVOICE_ENTITY ? safeString(selectedEmployee.fullName) : ""}`
                : "",
            !isNullOrUndefined(selectedProject)
                ? `Project: ${(entityName === INVOICE_ENTITY || entityName === PURCHASE_BILL_ENTITY) ? safeString(selectedProject.projectName) : ""}`
                : ""

        ]);
        handleSortClose();
    }

    return (
        <>
            <Tooltip title={filters.join(', ')} aria-label="filter details">
                <Badge
                    badgeContent={"!"}
                    color="secondary"
                    overlap="rectangular"
                    style={{ position: 'relative', marginRight: '16px', cursor: "pointer" }}
                >
                    <Button
                        style={buttonStyle}
                        className={buttonClass}
                        variant="text"
                        color="primary"
                        size="small"
                        endIcon={<AppIcon name={iconName} size="small" />}
                        onClick={handleSortClick}
                    >
                        {buttonLabel}
                    </Button>
                </Badge>
            </Tooltip>


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleSortClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container spacing={2} style={{ padding: '16px', maxWidth: '350px', overflowX: 'hidden', margin: '0px' }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <DateInput name="fromDate" label="From date" required compact="true" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <DateInput name="toDate" label="To date" required compact="true" />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            filterOptions={filterOptions}
                            size="small"
                            id="referenceId"
                            name="referenceId"
                            style={{ marginBottom: '10px' }}
                            options={parties}
                            getOptionLabel={(option) =>
                                !isNullOrUndefined(option)
                                    ? referenceType === CUSTOMER_ENTITY
                                        ? `${option.legalName}`
                                        : option.name
                                    : ''
                            }
                            value={selectedParty}
                            onChange={async (event, val) => {
                                await handlePartySelectionChange(values, setFieldValue, val);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    name="referenceName"
                                    {...params}
                                    label={`Select ${referenceType}`}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    {
                        salesPersonModuleEnabled && entityName === INVOICE_ENTITY &&
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                filterOptions={filterOptions}
                                size="small"
                                id="referenceId"
                                name="referenceId"
                                style={{ marginBottom: '10px' }}
                                options={employees}
                                getOptionLabel={(option) =>
                                    !isNullOrUndefined(option) &&
                                    safeString(option?.fullName)
                                }
                                value={selectedEmployee}
                                onChange={(event, newValue) => {
                                    if (!isNullOrUndefined(newValue)) {
                                        setSelectedEmployee(newValue);
                                        setFieldValue('employeeName', safeString(newValue.fullName));
                                        setFieldValue('employeeId', safeInt(newValue.id));
                                    } else {
                                        setSelectedEmployee(null);
                                        setFieldValue('employeeId', 0);
                                        setFieldValue('employeeName', '');
                                    }
                                }}
                                renderInput={(props) =>
                                    <TextInput fullWidth name="employeeName"  {...props} label="Sales person" variant="outlined" />
                                }
                            />
                        </Grid>
                    }
                    {
                        (entityName === INVOICE_ENTITY || entityName === PURCHASE_BILL_ENTITY) &&
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                filterOptions={filterOptions}
                                size="small"
                                id="referenceId"
                                name="referenceId"
                                style={{ marginBottom: '10px' }}
                                options={projectList}
                                getOptionLabel={(option) =>
                                    !isNullOrUndefined(option) &&
                                    safeString(option?.projectName)
                                }
                                value={selectedProject}
                                onChange={(event, newValue) => {
                                    if (!isNullOrUndefined(newValue)) {
                                        setSelectedProject(newValue);
                                        setFieldValue('projectName', safeString(newValue.projectName));
                                        setFieldValue('projectId', safeInt(newValue.id));
                                    } else {
                                        setSelectedProject(null);
                                        setFieldValue('projectId', 0);
                                        setFieldValue('projectName', '');
                                    }
                                }}
                                renderInput={(props) =>
                                    <TextInput fullWidth name="projectName"  {...props} label="Project" variant="outlined" />
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={12} className={classes.pullLeft} style={{ marginTop: '10px' }}>
                        <Button variant="contained" className={classes.mr1} color="primary" onClick={() => handleSubmit(values)}>Ok</Button>
                        <Button variant="outlined" color="primary" onClick={() => handleSortClose()}>Cancel</Button>
                    </Grid>
                </Grid>
            </Popover>

            <Popover
                id={filterId}
                open={filterOpen}
                anchorEl={filterAnchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography style={{ padding: '16px' }}>
                    {filters.length > 0 ? filters.join(', ') : 'No filters applied'}
                </Typography>
            </Popover>
        </>
    );
};

OrderListViewFilter.defaultProps = {
    iconName: 'FilterList',
    buttonStyle: { background: '#FAFAFA' },
    buttonClass: '',
};

export default OrderListViewFilter;
