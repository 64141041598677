import { CREDIT_NOTE_ENTITY, DELIVERY_CHALLAN_ENTITY, ERROR_INVALID_DECIMAL, ERROR_UX_UNHANDLED, INVOICE_ENTITY, ORDER_TYPE_INVOICE, ORDER_TYPE_PURCHASE_BILL, QUOTATION_ENTITY, REGEX_VALID_DECIMAL, SALES_ORDER_ENTITY } from "../Base/Common";
import { getAmountExcludingTax, getAmountIncludingTax, isNullOrUndefined, lowerCaseFirstLetter, safeAmount, safeBool, safeFloat, safeInt, safeString, setInputFocus } from "../Base/Utils";
import { getLastProductPrices } from "../Business/Product/ProductBusiness";



export const isSalesVoucher = (entityName) => {
    return (entityName === INVOICE_ENTITY
        || entityName === DELIVERY_CHALLAN_ENTITY
        || entityName === SALES_ORDER_ENTITY
        || entityName === QUOTATION_ENTITY
        || entityName === CREDIT_NOTE_ENTITY)
}
export const addOrderItem = (arrayHelpers, defaultWarehouseId, taxInclusivePricing) => {
    arrayHelpers.push({
        "id": 0,
        "orderId": 0,
        "selectedProduct": null,
        "productId": 0,
        "productType": "",
        "productDescription": "",
        "hsnCode": "",
        "quantity": 0,
        "freeQuantity": 0,
        "unitOfMeasurement": "",
        "baseUnitOfMeasurement": "",
        "conversionFactor": 1,
        "maximumRetailPrice": 0,
        "unitPrice": 0,
        "unitPriceIncludingTax": 0,
        "isUnitPriceIncludingTax": taxInclusivePricing,
        "taxableAmount": 0,
        "lineTotal": 0,
        "stateTaxAmount": 0,
        "centralTaxAmount": 0,
        "interStateTaxAmount": 0,
        "taxRate": 0,
        "isDiscountInPercentage": true,
        "discount": 0,
        "discountPercent": 0,
        "discountAmount": 0,
        "cessPercent": 0,
        "cessAmount": 0,
        "batchItems": [],
        "serialNumbers": "",
        "useSerialTracking": false,
        "validateBatchSerialNumber": true,
        "orderDetailInfoText": "",
        "warehouseId": defaultWarehouseId,
        "warehouseName": "",
        "showBatchSerialNumber": false
    });
}

export const handleBlur = (e, values, item, setFieldValue, taxInclusivePricing, decimalPlaces, showAlert, prefix = '', entityName = 'Invoice', roundOffOrderTotal = true) => {
    try {
        let taxableAmount = 0;
        let lineTotal = 0;
        let hasError = false;               

        if (taxInclusivePricing) {
            let unitPrice = getAmountExcludingTax(item.unitPriceIncludingTax, item.taxRate);
            setFieldValue(prefix + 'unitPrice', unitPrice);
        }

        // if (e.currentTarget.name === prefix + 'discount') {
        let discount = safeFloat(item.discount);
        if (safeBool(item.isDiscountInPercentage)) {
            item.discountPercent = discount;
            item.discountAmount = 0;
            setFieldValue(prefix + 'discountPercent', discount);
            setFieldValue(prefix + 'discountAmount', 0);
        } else {
            item.discountAmount = safeFloat(item.discount);
            item.discountPercent = 0;
            setFieldValue(prefix + 'discountPercent', 0);
            setFieldValue(prefix + 'discountAmount', discount);
        }
        // }

        if (taxInclusivePricing) {
            let total = safeAmount(item.quantity) * safeAmount(item.unitPriceIncludingTax);
            taxableAmount = getAmountExcludingTax(total, safeFloat(item.taxRate));
        } else {
            taxableAmount = safeAmount(item.quantity) * safeAmount(item.unitPrice);
        }

        lineTotal = getLineTotal(item, taxInclusivePricing);
        setFieldValue(prefix + 'taxableAmount', taxableAmount);
        setFieldValue(prefix + 'lineTotal', lineTotal);

        if (e.currentTarget.name === prefix + 'quantity') {
            if (REGEX_VALID_DECIMAL.test(item.quantity) === false) {
                hasError = true;
                setInputFocus(prefix + "quantity");
                showAlert(ERROR_INVALID_DECIMAL, 'error');
            }
        }
        if (!hasError) {
            calculateOrderTotals(entityName, values, setFieldValue, taxInclusivePricing, decimalPlaces, roundOffOrderTotal);
        }
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }

}

function getLineTotal(item, taxInclusivePricing = false) {
    let lineTotal = 0;
    let taxableAmount = 0;
    let discountAmount = 0;
    let taxAmount = 0;
    let cessAmount = 0;

    if (taxInclusivePricing) {
        let total = safeAmount(item.quantity) * safeAmount(item.unitPriceIncludingTax);
        taxableAmount = getAmountExcludingTax(total, safeFloat(item.taxRate));
    } else {
        taxableAmount = safeAmount(item.quantity) * safeAmount(item.unitPrice);
    }
    if (safeFloat(item.discountPercent) > 0) {
        discountAmount = taxableAmount * (item.discountPercent / 100);
        taxableAmount -= discountAmount;
    }
    if (safeFloat(item.discountAmount) > 0) {
        discountAmount = item.discountAmount;
        taxableAmount -= discountAmount;
    }
    if (safeFloat(item.taxRate) > 0) {
        taxAmount = taxableAmount * (item.taxRate / 100);
    }
    if (safeFloat(item.cessPercent) > 0) {
        cessAmount = taxableAmount * (item.cessPercent / 100);
    }
    lineTotal = taxableAmount + taxAmount + cessAmount;

    return safeAmount(lineTotal, 2);
}

export const calculateOrderTotals = (entityName = 'Invoice', values, setFieldValue, taxInclusivePricing, decimalPlaces = 2, roundOffOrderTotal = true) => {
    let grandTotal = 0;
    let taxableTotal = 0;
    let taxTotal = 0;
    let cessTotal = 0;
    let roundOffAmount = 0;

    const entity = lowerCaseFirstLetter(entityName);
    let itemDiscounted = false;
    for (let i = 0; i < values[`${entity}Details`].length; i++) {
        const item = values[`${entity}Details`][i];
        let lineTotal = 0;
        let taxableAmount = 0;
        let discountAmount = 0;
        let taxAmount = 0;
        let cessAmount = 0;

        if (taxInclusivePricing) {
            let total = safeAmount(item.quantity) * safeAmount(item.unitPriceIncludingTax);
            taxableAmount = getAmountExcludingTax(total, safeFloat(item.taxRate));
        } else {
            taxableAmount = safeAmount(item.quantity) * safeAmount(item.unitPrice);
        }

        if (safeFloat(item.discountPercent) > 0 || safeFloat(item.discountAmount) > 0) {
            if (safeFloat(item.discountAmount) > 0) {
                item.discountPercent = 0;
                itemDiscounted = true;
                discountAmount = safeFloat(item.discountAmount);
                taxableAmount -= discountAmount;
            } else {
                item.discountAmount = 0;
                itemDiscounted = true;
                discountAmount = taxableAmount * (item.discountPercent / 100);
                taxableAmount -= discountAmount;
            }
        }

        if (safeFloat(item.taxRate) > 0) {
            taxAmount = taxableAmount * (item.taxRate / 100);
        }
        if (safeFloat(item.cessPercent) > 0) {
            cessAmount = taxableAmount * (item.cessPercent / 100);
        }
        lineTotal = taxableAmount + taxAmount + cessAmount;

        taxTotal += taxAmount;
        cessTotal += cessAmount;
        taxableTotal += taxableAmount;
        grandTotal += lineTotal;
    }
    setFieldValue('orderItemDiscounted', itemDiscounted);
    setFieldValue('taxAmount', safeAmount(taxTotal));
    if (safeBool(values?.isIGST)) {
        setFieldValue('interStateTaxAmount', safeAmount(taxTotal));
    } else {
        setFieldValue('stateTaxAmount', safeAmount(taxTotal / 2));
        setFieldValue('centralTaxAmount', safeAmount(taxTotal / 2));
    }
    setFieldValue('cessAmount', safeAmount(cessTotal));
    setFieldValue('orderTotal', safeAmount(taxableTotal));

    let orderDiscountAmount = safeFloat(values?.orderDiscountAmount);
    let orderDiscountPercent = safeFloat(values?.orderDiscountPercent);
    if (orderDiscountPercent > 0 && orderDiscountAmount === 0) {
        orderDiscountAmount = grandTotal * orderDiscountPercent / 100;
    }
    setFieldValue('orderDiscountPercent', orderDiscountPercent);
    setFieldValue('orderDiscountAmount', orderDiscountAmount);

    grandTotal -= orderDiscountAmount;

    let tdstcsAmount = safeAmount(values?.tdstcsAmount);
    let tdstcsPercent = values?.tdstcsPercentage;
    if (tdstcsPercent > 0) {
        tdstcsAmount = (tdstcsPercent / 100) * taxableTotal;
        setFieldValue('tdstcsPercentage', tdstcsPercent);
        setFieldValue('tdstcsAmount', tdstcsAmount.toFixed(decimalPlaces));
    }
    if (tdstcsAmount > 0) {
        if(safeString(values?.addOrLesstdstcs) === "-") {
            grandTotal -= safeAmount(tdstcsAmount);
        } else {
            grandTotal += safeAmount(tdstcsAmount);
        }        
    }
    
    // Order adjustments
    let orderAdjustmentAmount = safeAmount(values?.adjustmentDetailAmount);
    if(orderAdjustmentAmount > 0) {
        if(values?.addOrLess === '-') {
            orderAdjustmentAmount *= -1;
        }
        grandTotal += orderAdjustmentAmount;
    }

    if (roundOffOrderTotal) {
        roundOffAmount = Math.round(grandTotal) - grandTotal;
        grandTotal = Math.round(grandTotal);
    }
    setFieldValue('roundOffAmount', safeAmount(roundOffAmount));
    setFieldValue('grandTotal', safeAmount(grandTotal));
}

export async function handleProductSelectionChange(authContext, selectedParty, val, setFieldValue, entityName = 'Invoice', prefix = "") {
    if (!isNullOrUndefined(val) && authContext.settings['useLast' + entityName + 'Price'] && safeInt(selectedParty?.id) > 0) {
        var orderTypeId = ORDER_TYPE_INVOICE;
        if (!isSalesVoucher(entityName)) {
            orderTypeId = ORDER_TYPE_PURCHASE_BILL;
        }
        const result = await getLastProductPrices(val?.id, orderTypeId, selectedParty?.id, authContext)
        if (isNullOrUndefined(result)) {
            setOrderItemValues(val, setFieldValue, 0, entityName, prefix);
        } else {
            setOrderItemValues(val, setFieldValue, result, entityName, prefix);
        }
        if (safeBool(val?.useBatchTracking) === true && authContext.settings.useBatchSerialNoTracking) {
            setFieldValue(prefix + 'showBatchSerialNumber', true);
        } else {
            setFieldValue(prefix + 'showBatchSerialNumber', false);
        }
    } else {
        setOrderItemValues(val, setFieldValue, 0, entityName, prefix);
    }
}

export function setOrderItemValues(value, setFieldValue, response = 0, entityName = 'Invoice', prefix = "") {
    if (!isNullOrUndefined(value)) {
        setFieldValue(prefix + 'selectedProduct', value);
        setFieldValue(prefix + 'productId', safeInt(value.id));
        setFieldValue(prefix + 'productType', safeString(value.productType));
        setFieldValue(prefix + 'productDescription', value.description);
        setFieldValue(prefix + 'useUnitConversion', safeBool(value.useUnitConversion));
        let unitPrice = 0;
        let unitPriceIncludingTax = 0;
        let maximumRetailPrice = 0;
        let pts = 0;
        let ptr = 0;
        let lastSaleDiscountPercent = 0;

        if (isNaN(response)) {
            if (safeFloat(response.unitPrice) === 0) {
                if (isSalesVoucher(entityName)) {
                    unitPrice = parseFloat(safeFloat(value.sellPrice).toFixed(2));
                    unitPriceIncludingTax = safeAmount(value.sellPriceIncludingTax);
                } else {
                    unitPrice = parseFloat(safeFloat(value.buyPrice).toFixed(2));
                }
            } else {
                unitPrice = parseFloat(safeFloat(response.unitPrice).toFixed(2));
                unitPriceIncludingTax = safeAmount(response.unitPriceIncludingTax);
                lastSaleDiscountPercent = safeAmount(response.discountPercent);
            }
            if (safeFloat(response.maximumRetailPrice) === 0) {
                maximumRetailPrice = parseFloat(safeFloat(value.maximumRetailPrice).toFixed(2));
            } else {
                maximumRetailPrice = parseFloat(safeFloat(response.maximumRetailPrice).toFixed(2));
            }
            if (safeFloat(response.pts) === 0) {
                pts = parseFloat(safeFloat(value.pts).toFixed(2));
            } else {
                pts = parseFloat(safeFloat(response.pts).toFixed(2));
            }
            if (safeFloat(response.ptr) === 0) {
                ptr = parseFloat(safeFloat(value.ptr).toFixed(2));
            } else {
                ptr = parseFloat(safeFloat(response.ptr).toFixed(2));
            }            
        } else {
            if (response === 0) {
                if (entityName === 'PurchaseBill' || entityName === 'PurchaseOrder' || entityName === 'DebitNote') {
                    unitPrice = parseFloat(safeFloat(value.buyPrice).toFixed(2));
                } else {
                    unitPrice = parseFloat(safeFloat(value.sellPrice).toFixed(2));
                    unitPriceIncludingTax = parseFloat(safeFloat(value.sellPriceIncludingTax).toFixed(2));
                }
                maximumRetailPrice = parseFloat(safeFloat(value.maximumRetailPrice).toFixed(2));
                pts = parseFloat(safeFloat(value.pts).toFixed(2));
                ptr = parseFloat(safeFloat(value.ptr).toFixed(2));
            } else {
                unitPrice = parseFloat(safeFloat(response).toFixed(2));
            }
        }
        setFieldValue(prefix + 'unitPrice', unitPrice);
        setFieldValue(prefix + 'unitPriceIncludingTax', unitPriceIncludingTax);
        setFieldValue(prefix + 'maximumRetailPrice', maximumRetailPrice);
        setFieldValue(prefix + 'pts', pts);
        setFieldValue(prefix + 'ptr', ptr);

        let lineTotal = getAmountIncludingTax(unitPrice, value.taxRate);
        let cessAmount = 0;
        if (safeFloat(value.cessPercent) > 0) {
            cessAmount = unitPrice * (value.cessPercent / 100);
            lineTotal += cessAmount;
        }
        setFieldValue(prefix + 'lineTotal', safeAmount(lineTotal, 2));
        setFieldValue(prefix + 'quantity', 1);
        setFieldValue(prefix + 'hsnCode', value.hsnCode);
        setFieldValue(prefix + 'unitOfMeasurement', value.unitOfMeasurement);
        setFieldValue(prefix + 'taxRate', value.taxRate);
        setFieldValue(prefix + 'cessPercent', value.cessPercent);
        setFieldValue(prefix + 'isDiscountInPercentage', true);        
        
        if (lastSaleDiscountPercent > 0) {
            setFieldValue(prefix + 'discount', lastSaleDiscountPercent);
            setFieldValue(prefix + 'discountPercent', lastSaleDiscountPercent);
        } else {
            setFieldValue(prefix + 'discount', safeFloat(value.standardDiscount));
            setFieldValue(prefix + 'discountPercent', safeFloat(value.standardDiscount));
        }

        if (safeBool(value.useBatchTracking) === true) {
            setFieldValue(prefix + 'showBatchSerialNumber', true);
        } else {
            setFieldValue(prefix + 'showBatchSerialNumber', false);
        }
        setFieldValue(prefix + "useSerialTracking", safeBool(value.useSerialTracking));

        if (value?.productType !== 'Miscellaneous') {
            setInputFocus(prefix + "quantity");
        } else {
            setInputFocus(prefix + "unitPrice");
        }
    } else {
        clearOrderItemValues(setFieldValue, prefix);
    }
}

export function clearOrderItemValues(setFieldValue, prefix = "") {
    setFieldValue(prefix + 'selectedProduct', null);
    setFieldValue(prefix + 'productId', 0);
    setFieldValue(prefix + 'productDescription', '');
    setFieldValue(prefix + 'orderDetailInfoText', '');
    setFieldValue(prefix + 'quantity', '');
    setFieldValue(prefix + 'quantityDescription', '');
    setFieldValue(prefix + 'freeQuantity', '');
    setFieldValue(prefix + 'ptsptr', '');
    setFieldValue(prefix + 'pts', '');
    setFieldValue(prefix + 'ptr', '');

    setFieldValue(prefix + 'unitPrice', 0);
    setFieldValue(prefix + 'unitPriceIncludingTax', 0);
    setFieldValue(prefix + 'maximumRetailPrice', 0);
    setFieldValue(prefix + 'taxRate', 0);
    setFieldValue(prefix + 'discountAmount', 0.00);
    setFieldValue(prefix + 'discount', 0);
    setFieldValue(prefix + 'discountPercent', 0);
    setFieldValue(prefix + 'lineTotal', 0);
    setFieldValue(prefix + 'unitOfMeasurement', '');
    setFieldValue(prefix + 'conversionFactor', 1);
    setFieldValue(prefix + 'batchItems', []);
    setFieldValue(prefix + 'mfgDate', '');
    setFieldValue(prefix + 'expiryDate', '');
    setFieldValue(prefix + 'batchNumber', '');
    setFieldValue(prefix + 'showBatchSerialNumber', false);
    setFieldValue(prefix + 'serialNumbers', '');
}