import React from 'react';
import { Menu, Divider } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { CREDIT_NOTE_ENTITY, DEBIT_NOTE_ENTITY, DELIVERY_CHALLAN_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY, PURCHASE_ORDER_ENTITY, QUOTATION_ENTITY } from '../Base/Common';
import { ContextOrderMobileMenuItem } from '../UI/Controls/Controls';

const OrderListViewMobileMenu = ({
    entityName,
    customerMenu,
    handleCustomerMenuClose,
    handleMenuItemClick,
    paymentAccess,
    moduleAccess,
    mobileDevice,
}) => {
    if (!mobileDevice) return null;

    const renderMenuItems = () => {
        const items = [];

        switch (entityName) {
            case INVOICE_ENTITY:
                items.push(
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit the invoice."
                        style={{ cursor: 'pointer', paddingLeft: '8px' }}
                    >
                        <ContextOrderMobileMenuItem label="View/Edit Invoice" iconname="Edit" />
                    </div>
                );
                items.push(<Divider key="divider1" />);
                items.push(
                    <div key="send-options">
                        <div
                            onClick={() => handleMenuItemClick('sendWhatsApp')}
                            title="Send on WhatsApp"
                            style={{ cursor: 'pointer', paddingLeft: '8px' }}
                        >
                            <ContextOrderMobileMenuItem label="Send On WhatsApp" iconColor="green"
                                iconname="WhatsappIcon" iconSize="20px" />
                        </div>
                        <div
                            onClick={() => handleMenuItemClick('sendSMS')}
                            title="Send on SMS"
                            style={{ cursor: 'pointer', paddingLeft: '8px' }}
                        >
                            <ContextOrderMobileMenuItem label="Send On SMS" iconColor="#2D90DD"
                                iconname="SMS" iconSize="20px" />
                        </div>
                        <div
                            onClick={() => handleMenuItemClick('sendEmail')}
                            title="Send on Email"
                            style={{ cursor: 'pointer', paddingLeft: '8px' }}
                        >
                            <ContextOrderMobileMenuItem label="Send On Email" iconColor="blue"
                                iconname="Email" iconSize="20px" />
                        </div>
                        <div
                            onClick={() => handleMenuItemClick('downloadPDF')}
                            title="Download"
                            style={{ cursor: 'pointer', paddingLeft: '8px' }}
                        >
                            <ContextOrderMobileMenuItem label="Download" iconColor="steelblue"
                                iconname="PDF" iconSize="20px" />
                        </div>
                    </div>
                );
                items.push(<Divider key="divider2" />);
                items.push(
                    <div key="payments-options">
                        <div
                            onClick={() => handleMenuItemClick('Payments')}
                            title="Click to add payment received against invoice."
                            style={{
                                cursor: paymentAccess.allowAdd && paymentAccess.allowEdit ? 'pointer' : 'default',
                                color: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                                pointerEvents: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'none' : 'auto',
                                paddingLeft: '8px'
                            }}
                        >
                            <ContextOrderMobileMenuItem label="Invoice Payments" iconname="RupeeIcon" labelcolor="success" iconSize="10px" />
                            {(!paymentAccess.allowAdd || !paymentAccess.allowEdit) && <LockIcon />}
                        </div>
                        <div
                            onClick={() => handleMenuItemClick('requestPayment')}
                            title="Click to send UPI payment link."
                            style={{
                                cursor: paymentAccess.allowAdd ? 'pointer' : 'default',
                                color: !paymentAccess.allowAdd ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                                pointerEvents: !paymentAccess.allowAdd ? 'none' : 'auto',
                                paddingLeft: '8px'
                            }}
                        >
                            <ContextOrderMobileMenuItem label="Send Payment Link" iconname="RupeeIcon" labelcolor="success" />
                            {(!paymentAccess.allowAdd) && <LockIcon />}
                        </div>
                    </div>
                );
                items.push(<Divider key="divider3" />);
                items.push(
                    <div key="recurring-copy">
                        <div
                            onClick={() => handleMenuItemClick('Recurring')}
                            title="Click to create a Recurring invoice."
                            style={{ cursor: 'pointer', paddingLeft: '8px' }}
                        >
                            <ContextOrderMobileMenuItem label="Create Recurring" iconname="Recurring" />
                        </div>
                        <div
                            onClick={() => handleMenuItemClick('Copy')}
                            title="Click to copy & create a new sales invoice with the same item list."
                            style={{
                                cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                                color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                                pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                                paddingLeft: '8px'
                            }}
                        >
                            <ContextOrderMobileMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                            {(!moduleAccess.allowAdd) && <LockIcon />}
                        </div>
                    </div>
                );
                items.push(<Divider key="divider5" />);
                items.push(
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                break;
            case PURCHASE_BILL_ENTITY:
                items.push(
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit"
                        style={{ cursor: 'pointer', paddingLeft: '8px' }}
                    >
                        <ContextOrderMobileMenuItem label="View/Edit" iconname="Edit" />
                    </div>
                );
                items.push(<Divider key="divider4" />);
                items.push(
                    <div key="payments-refund">
                        <div
                            onClick={() => handleMenuItemClick('BillPayable')}
                            title="Click to add payment received against Purchase bill."
                            style={{
                                cursor: paymentAccess.allowAdd && paymentAccess.allowEdit ? 'pointer' : 'default',
                                color: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                                pointerEvents: !paymentAccess.allowAdd || !paymentAccess.allowEdit ? 'none' : 'auto',
                                paddingLeft: '8px'
                            }}
                        >
                            <ContextOrderMobileMenuItem label="Payments" iconname="RupeeIcon" labelcolor="success" iconSize="10px" />
                            {(!paymentAccess.allowAdd || !paymentAccess.allowEdit) && <LockIcon />}
                        </div>
                        <div
                            onClick={() => handleMenuItemClick('Refund')}
                            title="Click to copy & create a new purchase bill with the same item list."
                            style={{
                                cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                                color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                                pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                                paddingLeft: '8px'
                            }}
                        >
                            <ContextOrderMobileMenuItem label="Refund" iconname="Undo" />
                            {(!moduleAccess.allowAdd) && <LockIcon />}
                        </div>
                    </div>
                );
                items.push(<Divider key="divider5" />);
                items.push(
                    <div key="copy">
                        <div
                            onClick={() => handleMenuItemClick('Copy')}
                            title="Click to copy & create a new sales invoice with the same item list."
                            style={{
                                cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                                color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                                pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                                paddingLeft: '8px'
                            }}
                        >
                            <ContextOrderMobileMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                            {(!moduleAccess.allowAdd) && <LockIcon />}
                        </div>
                    </div>
                );
                items.push(<Divider key="divider5" />);
                items.push(
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                break;
            case QUOTATION_ENTITY:
                items.push(
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit"
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMobileMenuItem label="View/Edit" iconname="Edit" />
                    </div>
                );
                items.push(<Divider key="divider6" />);
                items.push(<div
                    key="copy"
                    onClick={() => handleMenuItemClick('Copy')}
                    title="Click to copy & create a new quotation with the same item list."
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>);
                items.push(<Divider key="divider7" />);
                items.push(<div
                    key="convertQuotationtoInvoice"
                    onClick={() => handleMenuItemClick('Convert')}
                    title="Click to Convert to Invoice."
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Convert to Invoice." iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>);
                items.push(<Divider key="divider16" />);
                items.push(<div
                    key="convertQuotationToDC"
                    onClick={() => handleMenuItemClick('convertToDC')}
                    title="Click to Convert to Delivery Challan."
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Convert to D.C." iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>
                );
                items.push(<Divider key="divider5" />);
                items.push(
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                break;

            case DELIVERY_CHALLAN_ENTITY:
                items.push(
                    <div
                        key="edit"
                        onClick={() => handleMenuItemClick('Edit')}
                        title="Click to view/edit"
                        style={{ cursor: 'pointer' }}
                    >
                        <ContextOrderMobileMenuItem label="View/Edit" iconname="Edit" />
                    </div>
                );
                items.push(<Divider key="divider8" />);
                items.push(<div
                    key="copy"
                    onClick={() => handleMenuItemClick('Copy')}
                    title="Click to copy & create a new quotation with the same item list."
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>);
                items.push(<Divider key="divider9" />);
                items.push(<div
                    key="convertDCToInvoice"
                    onClick={() => handleMenuItemClick('Convert')}
                    title="Click to Convert to Invoice."
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Convert" iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>);
                 items.push(<Divider key="divider5" />);
                 items.push(
                     <div
                         key="deleteOrder"
                         onClick={() => handleMenuItemClick('deleteOrder')}
                         title="Click to delete order."
                         style={{
                             cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                             color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                             pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                         }}
                     >
                         <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                         {(!moduleAccess.allowAdd) && <LockIcon />}
                     </div>
                 );
                break;
            case CREDIT_NOTE_ENTITY:
                items.push(<div
                    key="edit"
                    onClick={() => handleMenuItemClick('Edit')}
                    title="Click to view/edit."
                    style={{ cursor: 'pointer' }}
                >
                    <ContextOrderMobileMenuItem label="View/Edit" iconname="Edit" />
                </div>);

                items.push(<Divider key="divider10" />);
                items.push(
                    <div
                        key="refund"
                        onClick={() => handleMenuItemClick('Refund')}
                        title="Refund"
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Refund" iconname="Undo" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                items.push(<Divider key="divider5" />);
                items.push(
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                break;
            case DEBIT_NOTE_ENTITY:
                items.push(<div
                    key="edit"
                    onClick={() => handleMenuItemClick('Edit')}
                    title="Click to view/edit."
                    style={{ cursor: 'pointer' }}
                >
                    <ContextOrderMobileMenuItem label="View/Edit" iconname="Edit" />
                </div>);

                items.push(<Divider key="divider11" />);
                items.push(
                    <div
                        key="refund"
                        onClick={() => handleMenuItemClick('Refund')}
                        title="Refund"
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Refund" iconname="Undo" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                items.push(<Divider key="divider5" />);
                items.push(
                    <div
                        key="deleteOrder"
                        onClick={() => handleMenuItemClick('deleteOrder')}
                        title="Click to delete order."
                        style={{
                            cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                            color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                            pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                        }}
                    >
                        <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                        {(!moduleAccess.allowAdd) && <LockIcon />}
                    </div>
                );
                break;
            case PURCHASE_ORDER_ENTITY:
                items.push(<div
                    key="edit"
                    onClick={() => handleMenuItemClick('Edit')}
                    title="Click to view/edit."
                    style={{ cursor: 'pointer' }}
                >
                    <ContextOrderMobileMenuItem label="View/Edit" iconname="Edit" />
                </div>);
                items.push(<Divider key="divider12" />);

                items.push(<div
                    key="ConvertToPO"
                    onClick={() => handleMenuItemClick('ConvertToPO')}
                    title="Convert P.O. to purchase bill"
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Convert" iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>);
                items.push(<Divider key="divider13" />);
                items.push(<div
                    key="edit"
                    onClick={() => handleMenuItemClick('fullfillmentReport')}
                    title="Fullfillment Report"
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Fullfillment Report" iconname="ReportsIcon" />
                </div>);
                items.push(<Divider key="divider14" />);
                items.push(<div
                    key="copy"
                    onClick={() => handleMenuItemClick('Copy')}
                    title="Click to copy & create a new quotation with the same item list."
                    style={{
                        cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                        color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                        pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                    }}
                >
                    <ContextOrderMobileMenuItem label="Copy/Clone" iconname="CopyInvoice" />
                    {(!moduleAccess.allowAdd) && <LockIcon />}
                </div>);
                 items.push(<Divider key="divider5" />);
                 items.push(
                     <div
                         key="deleteOrder"
                         onClick={() => handleMenuItemClick('deleteOrder')}
                         title="Click to delete order."
                         style={{
                             cursor: moduleAccess.allowAdd && moduleAccess.allowEdit ? 'pointer' : 'default',
                             color: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
                             pointerEvents: !moduleAccess.allowAdd || !moduleAccess.allowEdit ? 'none' : 'auto',
                         }}
                     >
                         <ContextOrderMobileMenuItem label="Delete Order" iconname="Delete" />
                         {(!moduleAccess.allowAdd) && <LockIcon />}
                     </div>
                 );
                break;
            default:
                return null;
        }

        return items;
    };

    return (
        <Menu
            anchorEl={customerMenu}
            keepMounted
            open={Boolean(customerMenu)}
            onClose={handleCustomerMenuClose}
            elevation={1}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                style: {
                    width: '250px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    boxSizing: 'border-box',
                },
            }}
        >
            {renderMenuItems()}
        </Menu>
    );
};

export default OrderListViewMobileMenu;
