import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import useStyles from '../../../styles';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import AppIcon from './AppIcons';
import { isNullOrUndefined } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageUploadController = ({ heading, buttonCaption, values, setFieldValue, fieldNamePreview, fieldName, handleUploadImage, buttonTxt, altTxt }) => {
    const authContext = useContext(AuthContext);
    const [croppedImage, setCroppedImage] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        switch (fieldName) {
            case "subscriberLogo":
                if (!isNullOrUndefined(values.subscriberLogo)) {
                    setCroppedImage(values.subscriberLogo);
                } else {
                    setCroppedImage(null);
                }
                break;
            case "signatureAndSeal":
                if (!isNullOrUndefined(values.signatureAndSeal)) {
                    setCroppedImage(values.signatureAndSeal);
                } else {
                    setCroppedImage(authContext.currentSubscriber.signatureAndSeal);
                }
                break;
            default:
                setCroppedImage(null);
                break;
        }
    }, [])


    const [open, setOpen] = useState(false);
    const cropperRef = useRef(null);
    const inputRef = useRef(null);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                setOpen(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCrop = useCallback(() => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
            setCroppedImage(croppedDataUrl);
            handleSave(croppedDataUrl);
            setOpen(false);
        }
    }, []);

    const handleSave = async (croppedDataUrl) => {
        setFieldValue(fieldName, croppedDataUrl);
        setFieldValue(fieldNamePreview, croppedDataUrl);
        setImage(null);
        await handleUploadImage(croppedDataUrl);
    };

    const handleRemove = async () => {
        setFieldValue(fieldName, "");
        setFieldValue(fieldNamePreview, "");
        setImage(null);
        setCroppedImage(null);
        await handleUploadImage("");

    };

    return (
        <>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Box className="logocontainer" p={2}>
                        <Card elevation={3} sx={{ margin: 'auto', padding: 2 }}>
                            <CardContent>
                                <Grid container spacing={4} alignItems="flex-start">
                                    {/* Upload Section */}
                                    <Grid item xs={12} md={8} display="flex" justifyContent="center">
                                        <Box
                                            className="drop_box"
                                            onDrop={e => {
                                                e.preventDefault();
                                                const file = e.dataTransfer.files[0];
                                                if (file) {
                                                    handleFileChange({ target: { files: [file] } });
                                                }
                                            }}
                                            onDragOver={e => e.preventDefault()}
                                            sx={{
                                                border: '2px dashed #3f51b5',
                                                borderRadius: '8px',
                                                padding: '20px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                width: '100%',
                                                maxWidth: '400px',
                                                '&:hover': { borderColor: '#ccc' }
                                            }}
                                        >
                                            <Typography variant="h6" component="header" gutterBottom>
                                                {heading}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Supported format: image/jpeg, image/png
                                            </Typography>
                                            <input
                                                accept="image/jpeg, image/png"
                                                type="file"
                                                ref={inputRef}
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                                id="fileUploadButton"
                                            />
                                            <label htmlFor="fileUploadButton">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component="span"
                                                    startIcon={<AppIcon name="BrowseFolder" />}
                                                    sx={{ marginTop: '10px' }}
                                                >
                                                    {buttonCaption}
                                                </Button>
                                            </label>
                                            <Typography variant="body2" sx={{ color: 'blue', fontStyle: 'italic', marginTop: '10px' }}>
                                                Max image size allowed: 1MB
                                            </Typography>
                                            <Typography variant="caption" sx={{ color: 'red', marginTop: '10px' }}>
                                                Preferably only square images (Dimensions: 1080 X 1080)
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* Preview Section */}
                                    <Grid item xs={12} md={4} display="flex" justifyContent="center">
                                        {croppedImage ? (
                                            <Box>
                                                <Typography variant="body1" gutterBottom color='secondary'>
                                                    Image Preview
                                                </Typography>
                                                <Box
                                                    component="img"
                                                    src={croppedImage}
                                                    alt={altTxt}
                                                    sx={{
                                                        maxWidth: '100%',
                                                        maxHeight: '220px',
                                                        border: '1px solid lightsteelblue',
                                                        borderRadius: '5px',
                                                        marginTop: '10px'
                                                    }}
                                                />
                                                <Button
                                                    variant="text"
                                                    style={{ color: 'red' }}
                                                    startIcon={<AppIcon name="Cancel" />}
                                                    onClick={handleRemove}
                                                    sx={{ marginTop: '10px' }}
                                                >
                                                    Remove image
                                                </Button>
                                            </Box>
                                        ) :
                                            (
                                                <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
                                                    No Image
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>

            {/* Cropper Dialog */}
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{buttonTxt}</DialogTitle>
                <DialogContent>
                    <Cropper
                        ref={cropperRef}
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleCrop} color="primary" variant="contained">
                        Crop
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImageUploadController;
