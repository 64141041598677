import { ButtonBase, Button, Grid, Tooltip, IconButton, Chip } from "@material-ui/core";
import useStyles from "../../styles"

import { EBPopper, PageHeader, ProductDescription } from "../UI/Controls/Controls";
import { NumberInput, SelectInput, CurrencyInput, TextInput, DateInput, QuantityInput } from "../UI/Controls/InputFields";
import { Formik, Form, FieldArray, isEmptyArray } from 'formik';
import * as yup from 'yup';
import { useContext, useEffect, useState } from "react";
import AppIcon from "../UI/Controls/AppIcons";
import AuthContext from "../Auth/AuthContext";
import WebAPI from "../Base/WebAPI";
import useStore from "../Base/Store";
import axios from "axios";
import shallow from 'zustand/shallow';
import { Autocomplete } from '@material-ui/lab';
import * as Common from '../Base/Common';

import * as OrderHelper from '../Shared/OrderHelper';
import * as DataProvider from '../Base/DataProvider';
import UOMSelect from "./Orders/UOMSelect";
import { useConfirm } from 'material-ui-confirm';
import Popup from "../UI/Controls/Popup";
import { safeInt, safeString, setInputFocus, safeFloat, getLocaleDate, isNullOrUndefined, getDatePart, formatToCurency, filterOptions, safeBool, isEmptyObjectArray, isNullOrEmpty, getSumOfArrayItem, findDuplicatesInArray, getConfirmOptions, getAmountExcludingTax, getAmountIncludingTax, formatCurrencyAmount, safeAmount, ifNullOrEmpty, } from "../Base/Utils";
import { getWarehouseList, getWarehouseWiseInventory } from "../Warehouse/WarehouseHelper";
import SelectWarehouse from "../UI/Controls/Business/SelectWarehouse";

export default function Item({ allowBatchDetails, taxInclusivePricing = false, orderId, itemId, selectedParty, validateStock = false, entityName = 'Invoice' }) {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const confirm = useConfirm();
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const showAlert = useStore(state => state.showAlert);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [captions, setCaptions] = useState({});
    const [batchTrackingEnabled, setBatchtrackingEnabled] = useState(false);
    const [batchNumbers, setBatchNumbers] = useState([]);
    const taxRateData = authContext.masterData.filter(md => md.type === 'TaxRate');
    const [popoverUOM, setPopoverUOM] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [quantityToAdd, setQuantityToAdd] = useState(0);
    const useMRP = safeBool(authContext.settings.useMaximumRetailPrice);
    const enableCess = safeBool(authContext.settings.enableCess);

    const [fieldValues, setFieldValues] = useState({
        "id": 0,
        "orderId": 0,
        "productId": 0,
        "hsnCode": '',
        "productType": 'Goods',
        "productDescription": "",
        "orderDetailInfoText": "",
        "quantity": 0,
        "quantityDescription": "",
        "freeQuantity": 0,
        "ptsptr": 0,
        "pts": 0,
        "ptr": 0,
        "unitOfMeasurement": "",
        "useUnitConversion": false,
        "conversionFactor": 1,
        "baseUnitOfMeasurement": "",
        "unitPrice": 0.00,
        "unitPriceIncludingTax": 0.00,
        "maximumRetailPrice": 0.00,
        "taxableAmount": 0.00,
        "cessPercent": 0,
        "cessAmount": 0,
        "lineTotal": 0.00,
        "stateTaxAmount": 0.00,
        "centralTaxAmount": 0.00,
        "interStateTaxAmount": 0.00,
        "taxRate": 0,
        "discountPercent": 0,
        "discountAmount": 0,
        "batchItems": [{ id: 0, orderDetailsId: 0, quantity: 0, batchNumber: '', mfgDate: '', expiryDate: '', active: true }],
        "serialNumbers": "",
        "useSerialTracking": false,
        "useBatchTracking": false,
        "validateBatchSerialNumber": false,
        "warehouseId": authContext.defaultWarehouseId,
        "warehouseName": "",
        "active": true
    });
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showProductInfo, setShowProductInfo] = useState(true);
    const [showBatchDetails, setShowBatchDetails] = useState(false);
    const [showSerialNumberDetails, setShowSerialNumberDetails] = useState(false);
    const [availableSerialNumbers, setAvailableSerialNumbers] = useState([]);
    const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);
    const [showProductWarehouse, setShowProductWarehouse] = useState(true);

    const [warehouseList, setWarehouseList] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const isMultiWarehouse = safeBool(authContext.settings.isMultiWarehouse);


    const toggleProductInfoVisiblity = () => {
        setShowProductInfo(!showProductInfo);
        setInputFocus("orderDetailInfoText");
    }
    const toggleBatchDetailsVisiblity = () => {
        setShowBatchDetails(!showBatchDetails);
    }

    const toggleSerialNumberVisibility = () => {
        setShowSerialNumberDetails(!showSerialNumberDetails);
    }

    const handlePopoverClosed = (name, response, setFieldValue, values = null) => {
        try {
            switch (name) {
                case "UOM":
                    if (!isNullOrUndefined(response) && !isNullOrUndefined(setFieldValue)) {
                        const conversionFactor = safeFloat(response.conversionFactor, 1);
                        if (entityName === 'PurchaseBill' || entityName === 'PurchaseOrder' || entityName === 'DebitNote') {
                            setFieldValue('unitPrice', safeFloat(conversionFactor * safeFloat(values.unitPrice)));
                        } else {
                            let unitPrice = safeFloat(conversionFactor * safeAmount(values.unitPrice));
                            setFieldValue('unitPrice', unitPrice);
                            if (taxInclusivePricing && safeAmount(values.unitPriceIncludingTax === 0)) {
                                unitPrice = getAmountIncludingTax(unitPrice, selectedProduct.taxRate)
                                setFieldValue('unitPriceIncludingTax', unitPrice);
                            }
                        }
                        setFieldValue('conversionFactor', conversionFactor);
                        setFieldValue('unitOfMeasurement', safeString(response.uqc));
                        setFieldValue('baseUnitOfMeasurement', safeString(response.primaryUOM));
                    }
                    break;
                case "BatchDetails":
                    if (!isNullOrUndefined(response) && !isNullOrUndefined(setFieldValue)) {
                    }
                    break;
                default:
                    break;
            }

        } finally {
            setPopoverUOM(null);
            setDialogResponse(null);
        }

    }
    const handleBatchBlur = (values) => {
        const qtyRemaining = (values.quantity + values.freeQuantity) - getSumOfArrayItem(values.batchItems, 'quantity');
        if (qtyRemaining < 0) {
            setHasError(true);
            showAlert('Batch number quantity cannot exceed selected quantity.', 'error');
        } else {
            setHasError(false);
            setQuantityToAdd(qtyRemaining);
        }
    }
    const handleBlur = (e, values, setFieldValue) => {
        if (OrderHelper.isSalesVoucher(entityName) && taxInclusivePricing) {
            setFieldValue('unitPrice', getAmountExcludingTax(values.unitPriceIncludingTax, values.taxRate));
            setFieldValue('lineTotal', OrderHelper.getLineTotal(values, false, taxInclusivePricing));
        } else {
            setFieldValue('lineTotal', OrderHelper.getLineTotal(values, true).toFixed(2));
        }

        if (allowBatchDetails && !isNullOrUndefined(selectedProduct) && safeBool(selectedProduct.useBatchTracking)) {
            const qtyRemaining = (values.quantity + values.freeQuantity) - getSumOfArrayItem(values.batchItems, 'quantity');
            if (qtyRemaining < 0) {
                setHasError(true);
                showAlert('Batch number quantity cannot exceed selected quantity.', 'error');
            } else {
                setHasError(false);
                setQuantityToAdd(qtyRemaining);
            }
        }
    }

    const getSerialNumbers = async (source, productId) => {
        try {
            setWaiting(true);
            const productSerialNos = await DataProvider.getData("Product", "GetProductSerialNumbers", authContext, source.token, safeInt(productId));
            var slNos = [];
            if (!isNullOrUndefined(productSerialNos[0])) {
                if (entityName === Common.CREDIT_NOTE_ENTITY) {
                    if (!isNullOrEmpty(productSerialNos[0].serialNumbersUsed)) {
                        slNos = slNos.concat(productSerialNos[0].serialNumbersUsed.split(","));
                    }
                } else {
                    if (!isNullOrEmpty(productSerialNos[0].serialNumbersAvailable)) {
                        slNos = slNos.concat(productSerialNos[0].serialNumbersAvailable.split(","));
                    }
                }
                if (slNos.length > 0) {
                    slNos = slNos.filter(s => safeString(s) !== "");
                }
            }

            setAvailableSerialNumbers(slNos);
            setSelectedSerialNumbers([]);
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const fetchData = async (source) => {
        try {
            setWaiting(true);
            var productResponse = await DataProvider.getEntityList(Common.PRODUCT_ENTITY, authContext, source.token);
            if (!!productResponse.code && productResponse.code > 0) {
                showAlert(productResponse.message, 'error');
            } else {
                if (!isNullOrUndefined(productResponse) && productResponse.length === 0) {
                    productResponse.unshift({ id: 0, description: '', descriptionDisplay: '' });
                }
                setProducts(productResponse);
            }

            if (safeInt(itemId) > 0) {
                const response = await WebAPI.get('/Item/GetItemById/' + itemId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                if (!!response.data) {
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                        return;
                    }
                    var itemDetails = { ...fieldValues };
                    Object.getOwnPropertyNames(itemDetails).map(p => {
                        itemDetails[p] = response.data[p];
                    });
                    if (taxInclusivePricing && safeAmount(itemDetails.unitPriceIncludingTax) === 0) {
                        itemDetails.unitPriceIncludingTax = getAmountIncludingTax(itemDetails.unitPrice, itemDetails.taxRate);
                    }

                    if (isMultiWarehouse) {
                        if (entityName === Common.PURCHASE_BILL_ENTITY) {
                            getWarehouseList(setWaiting, authContext, showAlert, source.token).then(
                                (response) => {
                                    setWarehouseList(response);
                                    let defaultWarehouse = response.find(w => w.id === itemDetails.warehouseId)
                                    setSelectedWarehouse(defaultWarehouse);
                                }
                            );
                        } else {
                            getWarehouseWiseInventory(itemDetails?.productId, setWaiting, authContext, showAlert, source.token).then(
                                (response) => {
                                    setWarehouseList(response);
                                    let defaultWarehouse = response.find(w => w.warehouseId === itemDetails.warehouseId)
                                    setSelectedWarehouse(defaultWarehouse);
                                }
                            );
                        }
                    }

                    if (!isEmptyObjectArray(itemDetails.batchItems)) {
                        for (let i = 0; i < itemDetails.batchItems.length; i++) {
                            const batchItem = itemDetails.batchItems[i];
                            batchItem.id = 0;
                            batchItem.mfgDate = getDatePart(batchItem.mfgDate);
                            batchItem.expiryDate = getDatePart(batchItem.expiryDate);
                            batchItem.selectedBatch = { batchNumber: batchItem.batchNumber, mfgdate: getDatePart(batchItem.mfgDate), expiryDate: getDatePart(batchItem.expiryDate) }
                        }
                        setShowBatchDetails(safeBool(itemDetails.useBatchTracking));
                    } else {
                        itemDetails['batchItems'] = [{ id: 0, orderDetailsId: 0, quantity: 0, batchNumber: '', mfgDate: '', expiryDate: '', active: true }];
                    }
                    if (safeBool(itemDetails.useSerialTracking)) {
                        const productSerialNos = await DataProvider.getData("Product", "GetProductSerialNumbers", authContext, source.token, safeInt(itemDetails.productId));
                        var slNos = [];
                        var selectedSlNos = [];
                        if (!isNullOrUndefined(productSerialNos[0])) {
                            if (!isNullOrEmpty(productSerialNos[0].serialNumbersAvailable)) {
                                slNos = slNos.concat(productSerialNos[0].serialNumbersAvailable.split(","));
                            }
                            if (slNos.length > 0) {
                                slNos = slNos.filter(s => safeString(s) !== "");
                            }
                            if (safeString(itemDetails.serialNumbers).length > 0) {
                                selectedSlNos = itemDetails.serialNumbers.split(",").filter(s => safeString(s) !== "");
                                slNos = slNos.concat(selectedSlNos);
                                if (selectedSlNos.length > 0) {
                                    setShowSerialNumberDetails(true);
                                }
                            }
                            setAvailableSerialNumbers(slNos);
                            setSelectedSerialNumbers(selectedSlNos);
                        }
                    }
                    var product = productResponse.find(p => p.id === itemDetails.productId)
                    if (!isNullOrUndefined(product)) {
                        setSelectedProduct(product);
                        itemDetails.useUnitConversion = product.useUnitConversion;
                        if (safeBool(product.useBatchTracking) === true) {
                            itemDetails.useBatchTracking = true;
                            if (entityName !== 'PurchaseBill') {
                                let source = axios.CancelToken.source();
                                var inventoryListResponse = await DataProvider.getDataList("Inventory", "InventoryListBatchNumbersByProductId", product.id, authContext, source.token, 'put');
                                setBatchNumbers(inventoryListResponse);
                            }
                        }

                        if (safeBool(product.useSerialTracking)) {
                            itemDetails.useSerialTracking = true;
                        }
                        setFieldValues(itemDetails);
                    }
                } else {
                    if (isMultiWarehouse) {
                        if (entityName === Common.PURCHASE_BILL_ENTITY) {
                            getWarehouseList(setWaiting, authContext, showAlert, source.token).then(
                                (response) => {
                                    setWarehouseList(response);
                                    let defaultWarehouse = response.find(w => w.id === itemDetails.warehouseId)
                                    setSelectedWarehouse(defaultWarehouse);
                                }
                            );
                        }
                    }
                }
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Request cancelled.');
            } else {
                console.log(error);
                showAlert('Failed to get item details. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const submitFormData = async (values) => {
        try {
            setWaiting(true);
            values.taxableAmount = OrderHelper.getLineTotal(values, false);
            values.description = safeString(values.productDescription);
            values.orderDetailInfoText = safeString(values.orderDetailInfoText);
            values.taxRateDisplay = safeString(values.taxRate) + "%";
            values.discountPercentDisplay = OrderHelper.getDiscountPercentDisplay(values.discountPercent);
            values.cessPercentDisplay = safeAmount(values.cessPercent) > 0 ? values.cessPercent + "%" : "-";
            // values.mfgDate = getLocaleDate(values.mfgDate);
            // values.expiryDate = getLocaleDate(values.expiryDate);
            if (isMultiWarehouse) {
                values.warehouseId = ifNullOrEmpty(selectedWarehouse?.id, selectedWarehouse?.warehouseId);
                values.warehouseName = ifNullOrEmpty(selectedWarehouse?.name, selectedWarehouse?.warehouseName);
            } else {
                values.warehouseId = authContext.defaultWarehouseId;
            }

            if (taxInclusivePricing) {
                values.unitPrice = getAmountExcludingTax(values.unitPriceIncludingTax, values.taxRate);
                values.lineTotal = OrderHelper.getLineTotal(values, false, taxInclusivePricing);
            }

            let stockInHandQty = 0;
            stockInHandQty = safeFloat(selectedProduct.stockInHandQty);

            if (useMRP) {
                if (selectedProduct.productType !== 'Miscellaneous' && (!values.maximumRetailPrice || values.maximumRetailPrice === 0 || values.maximumRetailPrice < 0)) {
                    showAlert('Please fill in a valid item MRP', 'error');
                    setInputFocus("maximumRetailPrice");
                    return;
                }
            }

            var validPrice = true;
            if (!values.unitPrice || values.unitPrice === 0) {
                validPrice = false;
            }

            if (selectedProduct.productType !== 'Miscellaneous' && values.unitPrice < 0) {
                validPrice = false;
            }

            if (!validPrice) {
                if (selectedProduct.productType !== 'Miscellaneous') {
                    showAlert('Please fill in a valid item price', 'error');
                    setInputFocus("unitPrice");
                    return;
                }                
            }

            if (allowBatchDetails && safeBool(selectedProduct.useBatchTracking)) {
                values.useBatchTracking = true;
                var batches = values.batchItems.map(function (item) { return item.batchNumber.toUpperCase() });
                var duplicates = findDuplicatesInArray(batches);
                if (!isEmptyArray(duplicates)) {
                    showAlert('Batch number(s) - ' + duplicates + '  have been entered more than once. Please combine the batches or verify batch numbers.', 'error');
                    return;
                }

                var batchQuantity = getSumOfArrayItem(values.batchItems, 'quantity');
                if ((safeFloat(values.quantity) + safeFloat(values.freeQuantity)) !== safeFloat(batchQuantity)) {
                    showAlert('Batch number quantity and selected quantity does not match.', 'error');
                    return;
                }

                var dateMismatch = false;
                var mismatchIndex = 0;
                for (let i = 0; i < values.batchItems.length; i++) {
                    const item = values.batchItems[i];
                    const expiryDate = new Date(item.expiryDate);
                    const mfgDate = new Date(item.mfgDate);
                    if (expiryDate < mfgDate) {
                        mismatchIndex = i;
                        dateMismatch = true;
                        break;
                    }
                }
                if (dateMismatch) {
                    setInputFocus('batchItems.' + mismatchIndex + '.expiryDate');
                    showAlert('Expiry date cannot be less than Mfg. date', 'error');
                    return;
                }

                values.batchItems.forEach(element => {
                    element.batchNumber = element.batchNumber.toUpperCase();
                });
            }

            if (safeBool(selectedProduct.useSerialTracking)) {
                values.useSerialTracking = true;
                if (!isEmptyObjectArray(selectedSerialNumbers)) {
                    values.serialNumbers = selectedSerialNumbers.join(",");
                }
            }

            if (entityName === 'Invoice' || entityName === 'DeliveryChallan') {
                if (safeBool(authContext.settings.preventNegativeStock) && safeBool(selectedProduct.useInventory) && (stockInHandQty < (values.quantity + values.freeQuantity))) {
                    showAlert('Negative stock warning! Your settings prevent you from adding items that are out of stock.', 'error');
                    return;
                }
            }

            if (safeInt(itemId) === 0 && validateStock && !safeBool(authContext.settings.suppressStockWarnings) && safeBool(selectedProduct.useInventory) && (stockInHandQty < values.quantity)) {
                confirm(getConfirmOptions('Stock warning', "Selected quantity (" + safeFloat(values.quantity + values.freeQuantity) + "), is more than the available quantity (" + stockInHandQty + "). Are you sure to continue?. "))
                    .then(async () => {
                        setDialogResponse(values);
                        closeDialog();
                    }).catch(() => { });
            } else {
                setDialogResponse(values);
                closeDialog();
            }

        } catch (error) {
            showAlert("Failed to save item." + error.response, "error");
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            if (authContext.token != null) {
                if (safeBool(allowBatchDetails) === true) {
                    setBatchtrackingEnabled(authContext.settings.useBatchSerialNoTracking);
                }
                await fetchData(source);
            }
            let captionsInit = { saveButton: 'Add item', saveButtonIcon: 'AddCircle' };
            if (safeInt(itemId) > 0) {
                setInputFocus('quantity');
                captionsInit.saveButton = 'Update item';
                captionsInit.saveButtonIcon = 'Edit';
            } else {
                setInputFocus('productDescription');
            }
            setCaptions(captionsInit);
        }
        init();

        return () => {
            source.cancel();
        };
    }, []);

    const toggleProductWarehouseVisiblity = () => {
        setShowProductWarehouse(!showProductWarehouse)
    }


    return (
        <>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                validationSchema={yup.object({
                    quantity: yup.number(),
                    discountPercent: yup.number().positive().min(0, '%age cannot be -ve').max(100, '%age cannot be > 100'),
                    // unitPrice: yup.number()
                })}
                onSubmit={
                    async (values) => {
                        await submitFormData(values);
                    }
                }
            >
                {({ isSubmitting, values, setFieldValue, setValues }) => (
                    <Form>
                        <PageHeader heading={(safeInt(itemId) > 0) ? 'Item details' : 'New item'}>
                            <AppIcon name="ProductsIcon" color="primary" />
                        </PageHeader>

                        <div className={classes.page}>

                            <Grid container spacing={1} className={classes.itemToolBar}>

                                <Grid item xs={12} sm={useMRP ? 3 : 4}>
                                    <Autocomplete
                                        fullWidth
                                        filterOptions={filterOptions}
                                        PopperComponent={EBPopper}
                                        size="small"
                                        id="productId"
                                        name="productId"
                                        options={products}
                                        getOptionLabel={(option) => !isNullOrUndefined(option) ? (safeString(option.description) + " " + safeString(option.productCode) + " " + safeString(option.barCode)) : ""}
                                        renderOption={(props, option) => {
                                            return (
                                                <ProductDescription product={props} taxInclusivePricing={taxInclusivePricing} />
                                            );
                                        }}
                                        value={selectedProduct}
                                        onChange={async (e, val) => {
                                            try {
                                                let source = axios.CancelToken.source();
                                                setWaiting(true);
                                                if (isMultiWarehouse && entityName !== Common.PURCHASE_BILL_ENTITY) {
                                                    getWarehouseWiseInventory(val.id, setWaiting, authContext, showAlert, source.token).then(
                                                        (response) => {
                                                            setWarehouseList(response);
                                                            let defaultWarehouse = response.find(w => safeInt(w.warehouseId) === safeInt(authContext.defaultWarehouseId));
                                                            setSelectedWarehouse(defaultWarehouse);
                                                        }
                                                    )
                                                } else {
                                                    getWarehouseList(setWaiting, authContext, showAlert, source.token).then(
                                                        (response) => {
                                                            setWarehouseList(response);
                                                            let defaultWarehouse = response.find(w => w.id === safeInt(authContext.defaultWarehouseId))
                                                            setSelectedWarehouse(defaultWarehouse);
                                                        }
                                                    );
                                                }
                                                await OrderHelper.handleItemSelectionChange(authContext, selectedParty, val, setFieldValue, setSelectedProduct, entityName);
                                                if (safeBool(allowBatchDetails) === true) {
                                                    if (val.useBatchTracking === true) {
                                                        var response = await DataProvider.getDataList("Inventory", "InventoryListBatchNumbersByProductId", val.id, authContext, source.token, 'put');
                                                        setBatchNumbers(response);
                                                        setShowBatchDetails(true);
                                                    } else {
                                                        setShowBatchDetails(false);
                                                    }
                                                    if (val.useSerialTracking === true) {
                                                        await getSerialNumbers(axios.CancelToken.source(), val.id);
                                                        setShowSerialNumberDetails(true);
                                                    } else {
                                                        setShowSerialNumberDetails(false);
                                                    }
                                                }
                                            } catch (error) {
                                                console.log(error);
                                            } finally {
                                                setWaiting(false);
                                            }
                                        }}
                                        renderInput={(props) =>
                                            <TextInput fullWidth name="productDescription"  {...props} label="Item" variant="outlined" required />
                                        }
                                    />
                                    <Tooltip title="Click to add additional product info" className={classes.ml1}>
                                        <ButtonBase onClick={toggleProductInfoVisiblity} className={classes.mr1}>
                                            <AppIcon name="AddMore" color="primary" /> &nbsp;Add info
                                        </ButtonBase>
                                    </Tooltip>
                                    {(isMultiWarehouse && !isNullOrUndefined(selectedProduct)) &&
                                        <Tooltip title="Click to add additional product info" className={classes.ml1}>
                                            <ButtonBase onClick={toggleProductWarehouseVisiblity} className={classes.mr1}>
                                                <AppIcon name="Warehouse" color="primary" /> &nbsp;{ifNullOrEmpty(selectedWarehouse?.name, selectedWarehouse?.warehouseName)}
                                            </ButtonBase>
                                        </Tooltip>}
                                </Grid>
                                <Grid item container xs={12} sm={useMRP ? 5 : 4} spacing={1}>

                                    {
                                        useMRP &&
                                        <Grid item xs={12} sm={3}>
                                            <NumberInput label="MRP" name="maximumRetailPrice" placeholder="" compact="true"
                                                inputProps={{ step: 0.001, min: 0, max: Common.CURRENCY_MAX_VALUE }}
                                                onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                        </Grid>
                                    }

                                    <Grid item xs={12} sm={useMRP ? 2 : 3} className={classes.pl1}>
                                        <QuantityInput label="Qty"
                                            handleClick={
                                                (e) => {
                                                    if (values.useUnitConversion) {
                                                        setPopoverUOM(e.currentTarget)
                                                    }
                                                }
                                            }
                                            name="quantity" placeholder="" compact="true"
                                            uom={values.unitOfMeasurement}
                                            required
                                            inputProps={{ step: 0.001, min: 0, max: Common.CURRENCY_MAX_VALUE }}
                                            showaslink={values.useUnitConversion}
                                            onBlur={(e) => handleBlur(e, values, setFieldValue)}
                                            disabled={values.productType === 'Miscellaneous'}
                                        />
                                        {
                                            safeBool(allowBatchDetails) && !isNullOrUndefined(selectedProduct) && selectedProduct.useBatchTracking &&
                                            <Tooltip title="Click to add batch number details" className={classes.ml1}>
                                                <ButtonBase onClick={toggleBatchDetailsVisiblity} className={classes.mr1}>
                                                    <AppIcon name="AddMore" color="primary" /> &nbsp;Batches
                                                </ButtonBase>
                                            </Tooltip>
                                        }
                                        {
                                            safeBool(allowBatchDetails) && !isNullOrUndefined(selectedProduct) && selectedProduct.useSerialTracking &&
                                            <Tooltip title="Click to add serial numbers" className={classes.ml1}>
                                                <ButtonBase onClick={toggleSerialNumberVisibility} className={classes.mr1}>
                                                    <AppIcon name="AddMore" color="primary" /> &nbsp;Serial #
                                                </ButtonBase>
                                            </Tooltip>
                                        }
                                    </Grid>

                                    {
                                        !taxInclusivePricing ?
                                            <>
                                                <Grid item xs={12} sm={useMRP ? 4 : 5} className={classes.pl1}>
                                                    <NumberInput label="Price (Excl. tax)" name="unitPrice" placeholder="" compact="true"
                                                        inputProps={{ step: 0.001, max: Common.CURRENCY_MAX_VALUE }}
                                                        required onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                                    {
                                                        (!isNullOrUndefined(selectedProduct) && safeFloat(selectedProduct.sellPrice) > 0) &&
                                                        <span className={classes.pullLeft}>
                                                            <Tooltip title="Product list rate" tabIndex={99}>
                                                                <span>
                                                                    {
                                                                        formatCurrencyAmount('inr', safeFloat(selectedProduct.sellPrice), 'blue')
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid item xs={12} sm={useMRP ? 4 : 5} className={classes.pl1}>
                                                    <CurrencyInput currencyCode={currencyCode} label="Price (Incl. tax)" name="unitPriceIncludingTax" placeholder=""
                                                        inputProps={{ step: 0.001, max: Common.CURRENCY_MAX_VALUE }}
                                                        onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                                    {
                                                        (!isNullOrUndefined(selectedProduct) && safeFloat(selectedProduct.sellPrice) > 0) &&
                                                        <span className={classes.pullLeft}>
                                                            <Tooltip title="Product list rate" tabIndex={99}>
                                                                <span>
                                                                    {
                                                                        formatCurrencyAmount('inr', getAmountIncludingTax(selectedProduct.sellPrice, selectedProduct.taxRate), 'blue')
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                </Grid>
                                            </>
                                    }
                                    <Grid item xs={12} sm={useMRP ? 3 : 4} className={classes.pl1}>
                                        <NumberInput label="Disc. %" name="discountPercent" placeholder="" inputProps={{ step: 0.01, min: 0, max: 100 }}
                                            onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                        {(!!values.discountPercent && values.discountPercent > 0) &&
                                            <label className={classes.ml1}>{formatToCurency(OrderHelper.getDiscountAmount(values).toFixed(2), currencyCode)}</label>}
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={4} spacing={1}>
                                    <Grid item xs={12} sm={enableCess ? 4 : 5}>
                                        <SelectInput label="GST" name="taxRate"
                                            onBlur={(e) => handleBlur(e, values, setFieldValue)}>
                                            {
                                                taxRateData.map((item, index) => {
                                                    return (
                                                        <option key={item.code} value={item.code}>{item.description}</option>
                                                    )
                                                })
                                            }
                                        </SelectInput>
                                        {
                                            (!!values.taxRate && values.taxRate > 0) &&
                                            <label className={classes.ml1}>{formatToCurency(OrderHelper.getTaxAmount(values).toFixed(2), currencyCode)}</label>
                                        }
                                    </Grid>
                                    {
                                        enableCess &&
                                        <Grid item xs={12} sm={3} className={classes.pl1}>
                                            <NumberInput label="Cess %" name="cessPercent" placeholder="" inputProps={{ step: 0.01, min: 0 }}
                                                onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                            {(safeFloat(values?.cessPercent) > 0) &&
                                                <label>{formatToCurency(OrderHelper.getCessAmount(values), currencyCode)}</label>}
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={enableCess ? 5 : 7} className={classes.pl1}>
                                        <CurrencyInput currencyCode={currencyCode} label="Net amount" name="lineTotal" placeholder="" required disabled />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} hidden={showProductInfo}>
                                    <TextInput label="Item info" name="orderDetailInfoText" placeholder="" multiline inputProps={{ className: classes.textarea, maxLength: 2000 }} />
                                </Grid>
                                {
                                    (safeBool(authContext.settings.allowFreeQuantity) || safeBool(authContext.settings.includeReferencePrice) || authContext.settings.isPharmacy) ?
                                        <Grid item container xs={12} sm={useMRP ? 5 : 4} spacing={1}>
                                            {
                                                safeBool(authContext.settings.allowFreeQuantity) &&
                                                <Grid item xs={useMRP ? 2 : 3} sm={useMRP ? 2 : 3}>
                                                    <QuantityInput label="Free qty"
                                                        name="freeQuantity" placeholder="" compact="true"
                                                        uom={values.unitOfMeasurement}
                                                        showaslink={values.useUnitConversion}
                                                        inputProps={{ step: 0.001, min: 0, max: Common.CURRENCY_MAX_VALUE }}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                authContext.settings.isPharmacy &&
                                                <Grid item xs={4} sm={4} className={classes.pl1}>
                                                    <TextInput label="Pack" name="quantityDescription" placeholder="" />
                                                </Grid>
                                            }
                                            {
                                                safeBool(authContext.settings.includeReferencePrice) &&
                                                <>
                                                    <Grid item xs={6} sm={4} className={classes.pl1}>
                                                        <NumberInput label="PTS" name="pts" placeholder="" compact="true" title="Price to stockist"
                                                            inputProps={{ step: 0.001, min: 0, max: Common.CURRENCY_MAX_VALUE }}
                                                            onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} className={classes.pl1}>
                                                        <NumberInput label="PTR" name="ptr" placeholder="" compact="true" title="Price to retailer"
                                                            inputProps={{ step: 0.001, min: 0, max: Common.CURRENCY_MAX_VALUE }}
                                                            onBlur={(e) => handleBlur(e, values, setFieldValue)} />
                                                    </Grid>
                                                </>
                                            }

                                        </Grid> : null
                                }

                                <Grid item xs={12} sm={useMRP ? 12 : 8} hidden={!showSerialNumberDetails || !safeBool(allowBatchDetails)}>
                                    <Autocomplete
                                        multiple
                                        options={!isEmptyObjectArray(availableSerialNumbers) ? availableSerialNumbers.map((option) => option) : []}
                                        value={selectedSerialNumbers}
                                        freeSolo
                                        size="small"
                                        filterSelectedOptions
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip color="secondary" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        onChange={(e, val) => {
                                            if (!isNullOrUndefined(val)) {
                                                if (val.length > values.quantity) {
                                                    showAlert('Serial numbers cannot exceed selected quantity.', 'error');
                                                } else {
                                                    setSelectedSerialNumbers(val);
                                                    setQuantityToAdd((safeFloat(values.quantity) + safeFloat(values.freeQuantity)) - val.length);
                                                }
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextInput id="serialNumbers" name="serialNumbers" {...params} variant="outlined" label="Serial nos." placeholder="Enter serial nos." className={classes.toUpper} />
                                        )}

                                    />
                                </Grid>
                                {isMultiWarehouse && <Grid item xs={12} sm={3} hidden={showProductWarehouse}>
                                    <SelectWarehouse warehouseList={warehouseList} selectedWarehouse={selectedWarehouse}
                                        defaultWarehouseId={authContext?.defaultWarehouseId}
                                        includeInventory={entityName !== Common.PURCHASE_BILL_ENTITY}
                                        handleSelectionChanged={
                                            (e, val) => {
                                                if (entityName === Common.PURCHASE_BILL_ENTITY) {
                                                    setFieldValue('warehouseId', safeInt(val));
                                                    let itemWarehouse = warehouseList?.find(w => w.id === safeInt(val));
                                                    setSelectedWarehouse(itemWarehouse);
                                                } else {
                                                    if (!isNullOrEmpty(val)) {
                                                        setSelectedWarehouse(val);
                                                        setFieldValue('warehouseName', isNullOrEmpty(val.name) ? val.name : '');
                                                        setFieldValue('warehouseId', safeInt(val.id));
                                                    } else {
                                                        setSelectedWarehouse(null);
                                                        setFieldValue('warehouseName', '');
                                                        setFieldValue('warehouseId', '');
                                                    }
                                                }
                                            }
                                        }
                                    />
                                </Grid>
                                }

                                {
                                    showBatchDetails && safeBool(allowBatchDetails) &&
                                    <Grid item container spacing={1}>
                                        <Grid item xs={12}>
                                            <FieldArray name="batchItems"
                                                render={arrayHelpers => (
                                                    <>
                                                        {
                                                            values.batchItems.map((item, index) => (
                                                                <Grid container spacing={1} className={classes.mt1} key={index}>
                                                                    <Grid item xs={6} sm={2}>
                                                                        <QuantityInput label="Qty" name={`batchItems.${index}.quantity`} required tabIndex={-100000}
                                                                            uom={values.unitOfMeasurement}
                                                                            inputProps={{ step: 0.001, min: 0, max: Common.CURRENCY_MAX_VALUE }}
                                                                            onBlur={() => {
                                                                                handleBatchBlur(values);
                                                                            }}
                                                                        />
                                                                    </Grid>

                                                                    {
                                                                        (safeInt(itemId) > 0 || batchNumbers.length > 0) ?
                                                                            <Grid item xs={6} sm={3}>
                                                                                <Autocomplete
                                                                                    fullWidth
                                                                                    filterOptions={filterOptions}
                                                                                    freeSolo
                                                                                    size="small"
                                                                                    id={`batchItems.${index}.batchNumber`}
                                                                                    name={`batchItems.${index}.batchNumber`}
                                                                                    options={batchNumbers}
                                                                                    getOptionLabel={(option) => {
                                                                                        if (typeof option === 'string') {
                                                                                            return option;
                                                                                        }
                                                                                        return (!isNullOrUndefined(option) ? safeString(option.batchNumber) : "")
                                                                                    }}
                                                                                    renderOption={(props, option) => {
                                                                                        if (typeof option === 'string') {
                                                                                            return option;
                                                                                        }
                                                                                        return (
                                                                                            <span>
                                                                                                {props.batchNumber} <br />
                                                                                                <span style={{ fontSize: '12px' }}>
                                                                                                    <>{' Qty in stock: ' + safeFloat(props.stockInHandQty)}</>
                                                                                                    <br />
                                                                                                    <>{'Expiry: ' + getLocaleDate(props.expiryDate)}</>
                                                                                                </span>
                                                                                            </span>
                                                                                        );
                                                                                    }}
                                                                                    value={(!isNullOrUndefined(values.batchItems[index]) && !isNullOrUndefined(values.batchItems[index].selectedBatch)) ? values.batchItems[index].selectedBatch : ''}
                                                                                    onChange={async (e, val) => {
                                                                                        if (!isNullOrUndefined(val)) {
                                                                                            setFieldValue('batchItems.' + index + '.batchNumber', safeString(val.batchNumber));
                                                                                            setFieldValue('batchItems.' + index + '.mfgDate', getDatePart(val.mfgDate));
                                                                                            setFieldValue('batchItems.' + index + '.expiryDate', getDatePart(val.expiryDate));
                                                                                        } else {
                                                                                            setFieldValue('batchItems.' + index + '.batchNumber', '');
                                                                                            setFieldValue('batchItems.' + index + '.mfgDate', '');
                                                                                            setFieldValue('batchItems.' + index + '.expiryDate', '');
                                                                                        }
                                                                                    }}
                                                                                    renderInput={(properties) =>
                                                                                        <TextInput id={`batchItems.${index}.batchNumber`} fullWidth name={`batchItems.${index}.batchNumber`}  {...properties} label="Batch No." variant="outlined" required
                                                                                            onBlur={() => {
                                                                                                handleBatchBlur(values);
                                                                                            }} />
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            :
                                                                            <Grid item xs={6} sm={3}>
                                                                                <TextInput label="Batch no." name={`batchItems.${index}.batchNumber`} required className={classes.toUpper}
                                                                                    onBlur={() => {
                                                                                        handleBatchBlur(values);
                                                                                    }} />
                                                                            </Grid>

                                                                    }
                                                                    <Grid item xs={6} sm={3}>
                                                                        <DateInput label="Mfg. date" name={`batchItems.${index}.mfgDate`} required
                                                                            onBlur={() => {
                                                                                try {
                                                                                    handleBatchBlur(values);
                                                                                    if (!isNullOrUndefined(values.batchItems[index].expiryDate) && !isNullOrUndefined(values.batchItems[index].mfgDate)) {
                                                                                        const expiryDate = new Date(values.batchItems[index].expiryDate);
                                                                                        const mfgDate = new Date(values.batchItems[index].mfgDate);
                                                                                        if (expiryDate < mfgDate) {
                                                                                            setInputFocus('batchItems.' + index + '.expiryDate');
                                                                                            showAlert('Expiry date cannot be less than Mfg. date', 'error');
                                                                                        } else {
                                                                                            setHasError(false);
                                                                                        }
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.log(error);
                                                                                }
                                                                            }} />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={3}>
                                                                        <DateInput label="Expiry date" name={`batchItems.${index}.expiryDate`} required
                                                                            onBlur={() => {
                                                                                handleBatchBlur(values);
                                                                                const expiryDate = new Date(values.batchItems[index].expiryDate);
                                                                                const mfgDate = new Date(values.batchItems[index].mfgDate);
                                                                                if (expiryDate < mfgDate) {
                                                                                    setInputFocus('batchItems.' + index + '.expiryDate');
                                                                                    showAlert('Expiry date cannot be less than Mfg. date', 'error');
                                                                                } else {
                                                                                    setHasError(false);
                                                                                }
                                                                            }} />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={1}>
                                                                        {
                                                                            (index > 0) &&
                                                                            <IconButton className={classes.errorColor} variant="text" title="Remove"
                                                                                onClick={() => {
                                                                                    let newTotal = 0;
                                                                                    for (let i = 0; i < values.batchItems.length; i++) {
                                                                                        if (i !== index) {
                                                                                            const element = values.batchItems[i];
                                                                                            newTotal += element.quantity;
                                                                                        }
                                                                                    }
                                                                                    const qtyRemaining = (values.quantity + values.freeQuantity) - newTotal;
                                                                                    if (qtyRemaining < 0) {
                                                                                        setHasError(true);
                                                                                        showAlert('Batch number quantity cannot exceed selected quantity.', 'error');
                                                                                    } else {
                                                                                        setHasError(false);
                                                                                        setQuantityToAdd(qtyRemaining);
                                                                                    }
                                                                                    arrayHelpers.remove(index);
                                                                                }}>
                                                                                <AppIcon name="Cancel" />
                                                                            </IconButton>
                                                                        }
                                                                    </Grid>
                                                                    {
                                                                        (index === values.batchItems.length - 1) &&
                                                                        <>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Button varaint="text" className={classes.successColor} startIcon={<AppIcon name="Add" />}
                                                                                    onClick={() => {
                                                                                        if (!hasError && quantityToAdd > 0) {
                                                                                            arrayHelpers.push({
                                                                                                id: 0,
                                                                                                orderDetailsId: 0,
                                                                                                quantity: 0,
                                                                                                batchNumber: '',
                                                                                                mfgDate: '',
                                                                                                expiryDate: '',
                                                                                                active: true
                                                                                            });
                                                                                        } else {
                                                                                            showAlert('Batch number quantity cannot exceed selected quantity.', 'error');
                                                                                        }
                                                                                    }}>
                                                                                    Add batch
                                                                                </Button>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={8} className={classes.pullRight}>
                                                                                <span>{"Total Quanity: " + (safeFloat(values.quantity) + safeFloat(values.freeQuantity)) + " | Quantity to be added: " + safeFloat(quantityToAdd)}</span>
                                                                            </Grid>
                                                                        </>
                                                                    }

                                                                </Grid>
                                                            ))
                                                        }
                                                    </>
                                                )} />

                                        </Grid>
                                    </Grid>
                                }
                            </Grid>

                        </div>

                        <Grid container spacing={2} className={classes.mt1}>
                            <Grid item xs={12} sm={12}>
                                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">{captions.saveButton}</Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => closeDialog()}>Cancel</Button>
                            </Grid>
                        </Grid>

                        <Popup
                            element={popoverUOM}
                            handleClose={() => setPopoverUOM(null)}
                            component={
                                <UOMSelect code={values.unitOfMeasurement} baseUnitOfMeasurement={values.baseUnitOfMeasurement}
                                    conversionFactor={values.conversionFactor} isPopover={true}
                                    setPopover={(val) => handlePopoverClosed("UOM", val, setFieldValue, values)}
                                />
                            }
                        />
                    </Form>
                )}
            </Formik>
        </>

    )

};
