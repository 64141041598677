import React, { useContext, useEffect, useState } from 'react';
import JournalEntry from './JournalEntry';
import JournalEntryTable from './JournalEntryTable';
import { getConfirmOptions, getFinancialStartDate, getISODate, getLocaleDate, getLocaleDate2, getLocaleDateTime, getTodaysDate, isEmptyObjectArray, isNullOrEmpty, isNullOrUndefined, safeAmount, safeBool, safeDate, safeInt, safeString, setInputFocus } from '../../Base/Utils';
import WebAPI from '../../Base/WebAPI';
import axios from 'axios';
import AuthContext from '../../Auth/AuthContext';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import { Button, ButtonBase, Grid, Paper, Typography } from '@material-ui/core';
import { PageHeader } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import { TRANSACTION_ENTITY } from '../../Base/Common';
import useStyles from '../../../styles';
import { useParams, useHistory } from "react-router-dom";
import { useConfirm } from 'material-ui-confirm';

const JournalEntries = () => {
    const [transactionType, setTransactionType] = useState([]);
    const classes = useStyles();
    const confirm = useConfirm();
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const { transactionIdentifier } = useParams();
    const [bankList, setBankList] = useState(null);
    const history = useHistory();
    const [fromEntriesTableData, setFromEntriesTableData] = useState([]);
    const [toEntriesTableData, setToEntriesTableData] = useState([]);
    const showAlert = useStore(state => state.showAlert);
    const authContext = useContext(AuthContext);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    const [fieldValues, setFieldValues] = useState({
        branchId: 0,
        accountNumber: "",
        transactionType: "",
        narrative: "",
        referenceId: 0,
        referenceType: "",
        referenceName: "",
        description: "",
        amount: 0,
        valueDate: getTodaysDate(),
        currencyCode: "",
        bankId: 0,
        creditTransaction: null,
        bankName: ""
    });


    const populateData = async (source, token = null) => {
        // Populate all that is required during page load caled from useEffect init()
        try {
            if (!isNullOrUndefined(transactionIdentifier) && transactionIdentifier !== "") {
                var transactionIdentifierList = await WebAPI.get('/Transactions/GetTransactionEntriesByIdentifier/' + safeString(transactionIdentifier), { headers: { "AccessToken": authContext.token }, cancelToken: source.token });

                if (!isNullOrUndefined(transactionIdentifierList?.data) && safeInt(transactionIdentifierList?.data?.code) > 0) {
                    showAlert(transactionIdentifierList.error, 'error');
                } else {
                    const { fromEntries, toEntries } = transactionIdentifierList?.data;

                    if (fromEntries) {
                        setFromEntriesTableData(fromEntries);
                    }

                    if (toEntries) {
                        setToEntriesTableData(toEntries);
                    }
                }
            } else {
                var TransactionsTypeResponse = await WebAPI.get('/Transactions/GetTransactionTypeList', { headers: { "AccessToken": token } });
                if (!isNullOrUndefined(TransactionsTypeResponse)) {
                    setTransactionType(TransactionsTypeResponse?.data);
                }
                var responseBankList = await WebAPI.get('/BankDetails/GetBankDetailsList/' + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });

                if (!isNullOrUndefined(responseBankList) && safeInt(responseBankList.code) > 0) {
                    showAlert(responseBankList.error, 'error');
                } else {
                    setBankList(responseBankList.data);
                }
            }

        } catch (error) {
            showAlert('An error occurred while fetching employee details.' + error, 'error');
        } finally {
            setWaiting(false);
        }
    }


    const mapTransactionType = (transactionType) => {
        switch (transactionType) {
            case "CC":
                return 'Customer';
            case 'SS':
                return 'Supplier';
            case 'EE':
                return "Expense";
            case 'II':
                return 'Income'
            case 'JJ':
                return 'Journal'
            default:
                return '';
        }
    };

    const ValidatingDate = (values) => {
        const allEntries = [...fromEntriesTableData, ...toEntriesTableData];
        const isDateConsistent = allEntries.every(entry => entry.valueDate === values.valueDate);

        if (!isDateConsistent) {
            showAlert(`${safeString(values?.referenceName)} Date can't be different; it should be the same across all entries. Cannot add.`, "error");
            return false;
        }

        return true;
    };



    const submitFormData = (values, resetForm, setFieldValue, mode) => {
        try {
            let fromTempTableData = [...fromEntriesTableData];
            let toTempTableData = [...toEntriesTableData];
            if (values.transactionType === "CC" || values.transactionType === "SS") {
                if (values.referenceId === 0) {
                    showAlert(`${mapTransactionType(values.transactionType)} cannot be empty for this transaction type.`, "error");
                    return;
                }
            }
            if (values.transactionType === "JJ" || values.transactionType === "EE" || transactionType === "II") {
                if (values.accountNumber === "") {
                    showAlert(`Account number cannot be empty for this ${mapTransactionType(values.transactionType)} .`, "error");
                    return;
                }
            }

            if ((safeString(values.transactionType) === "JJ" || safeString(values.transactionType) === "EE" || safeString(values.transactionType) === "II")) {
                const isAccountNumberInFromEntries = fromTempTableData.some(entry => entry.accountNumber === values.accountNumber);
                const isAccountNumberInToEntries = toTempTableData.some(entry => entry.accountNumber === values.accountNumber);

                if (isAccountNumberInFromEntries) {
                    showAlert(` This Account number ${values.accountNumber} is already exists in Debit Entries.`, "error");
                    return;
                }

                if (isAccountNumberInToEntries) {
                    showAlert(`This Account number ${values.accountNumber} already exists in Credit Entries.`, "error");
                    return;
                }
            }

            if ((safeString(values.transactionType) === "SS" || safeString(values.transactionType) === "CC")) {
                const isPartyInFromEntries = fromTempTableData.some(entry => safeInt(entry.referenceId) === safeInt(values.referenceId));
                const isPartyInToEntries = toTempTableData.some(entry => safeInt(entry.referenceId) === safeInt(values.referenceId));

                if (isPartyInFromEntries) {
                    showAlert(`${safeString(values?.referenceName)} already exists in Debit Entries. Cannot add.`, "error");
                    return;
                }

                if (isPartyInToEntries) {
                    showAlert(`${safeString(values?.referenceName)} already exists in Credit Entries. Cannot add.`, "error");
                    return;
                }
            }

            if (!ValidatingDate(values)) {
                return;
            }

            if (mode === "Debit") {
                fromTempTableData.push({
                    // From side
                    "branchId": safeInt(values?.branchId),
                    "accountNumber": safeString(values.accountNumber),
                    "transactionType": safeString(values.transactionType),
                    "narrative": safeString(values.narrative),
                    "referenceId": safeInt(values.referenceId),
                    "referenceType": safeString(values.referenceType),
                    "amount": safeAmount(values.amount),
                    "referenceName": safeString(values.referenceName),
                    "valueDate": values.valueDate,
                    "currencyCode": safeString(values.currencyCode),
                    "bankId": safeInt(values.bankId),
                    "description": safeString(values.description),
                    "creditTransaction": safeBool(values.creditTransaction),
                    "bankName": safeString(values.bankName)
                })
            } else {
                toTempTableData.push({
                    // To side
                    "branchId": safeInt(values?.branchId),
                    "accountNumber": safeString(values.accountNumber),
                    "transactionType": safeString(values.transactionType),
                    "narrative": safeString(values.narrative),
                    "referenceId": safeInt(values.referenceId),
                    "referenceType": safeString(values.referenceType),
                    "amount": safeAmount(values.amount),
                    "referenceName": safeString(values.referenceName),
                    "valueDate": values.valueDate,
                    "currencyCode": safeString(values.currencyCode),
                    "bankId": safeInt(values.bankId),
                    "description": safeString(values.description),
                    "creditTransaction": safeBool(values.creditTransaction),
                    "bankName": safeString(values.bankName)
                })
            }
            setFromEntriesTableData(fromTempTableData);
            setToEntriesTableData(toTempTableData);

            if (resetForm) {
                resetForm();
            }

        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }

    }
    const buildSaveRequest = () => {
        let fromEntries = [];
        let toEntries = [];

        if (!isEmptyObjectArray(fromEntriesTableData)) {
            for (let i = 0; i < fromEntriesTableData.length; i++) {
                const entry = fromEntriesTableData[i];
                fromEntries.push({
                    "branchId": safeInt(entry?.branchId),
                    "accountNumber": safeString(entry.accountNumber),
                    "transactionType": safeString(entry.transactionType),
                    "narrative": safeString(entry.narrative),
                    "referenceId": safeInt(entry.referenceId),
                    "referenceType": mapTransactionType(entry.transactionType),
                    "amount": safeAmount(entry.amount),
                    "referenceName": safeString(entry.referenceName),
                    "valueDate": entry.valueDate,
                    "currencyCode": safeString(entry.currencyCode),
                    "bankId": safeInt(entry.bankId),
                    "description": safeString(entry.description),
                    "creditTransaction": false,
                    "bankName": safeString(entry.bankName)
                })
            }
        }


        if (!isEmptyObjectArray(toEntriesTableData)) {
            for (let i = 0; i < toEntriesTableData.length; i++) {
                const entry = toEntriesTableData[i];
                toEntries.push({
                    "branchId": safeInt(entry?.branchId),
                    "accountNumber": safeString(entry.accountNumber),
                    "transactionType": safeString(entry.transactionType),
                    "narrative": safeString(entry.narrative),
                    "referenceId": safeInt(entry.referenceId),
                    "referenceType": mapTransactionType(entry.transactionType),
                    "amount": safeAmount(entry.amount),
                    "referenceName": safeString(entry.referenceName),
                    "valueDate": entry.valueDate,
                    "currencyCode": safeString(entry.currencyCode),
                    "bankId": safeInt(entry.bankId),
                    "description": safeString(entry.description),
                    "creditTransaction": true,
                    "bankName": safeString(entry.bankName)
                })
            }
        }

        const req = {
            fromEntries: fromEntries,
            toEntries: toEntries
        }

        return req;
    }
    const handleDeleteEntries = (index, mode) => {
        if (mode === 'Debit') {
            const updatedFromEntries = fromEntriesTableData.filter((_, i) => i !== index);
            setFromEntriesTableData(updatedFromEntries);
        } else if (mode === 'Credit') {
            const updatedToEntries = toEntriesTableData.filter((_, i) => i !== index);
            setToEntriesTableData(updatedToEntries);
        }
    }

    const deleteJournalEntries = async () => {
        confirm(getConfirmOptions('Are you sure to delete this transaction?'))
            .then(async () => {
                try {
                    setWaiting(true);
                    var response = await WebAPI.put('/Transactions/ReverseTransaction', transactionIdentifier, { headers: { "AccessToken": authContext.token } });
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                    } else {
                        showAlert('Transaction deleted successfully.', 'success');
                        history.push('/transactionList');
                    }
                } catch (error) {
                    showAlert('Transaction delete failed. Internal server error.', 'error');
                } finally {
                    setWaiting(false);
                }

            }).catch(() => { });
    }

    const saveJournalEntries = async () => {
        const request = buildSaveRequest();

        try {
            setWaiting(true);
            const apiUrl = "Transactions/CreateTransaction2";
            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });

            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                showAlert('Transaction saved Successfully', 'success');
                history.push('/transactionList');
                setInputFocus('transactionType')
                setDialogResponse(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }


    useEffect(() => {
        const source = axios.CancelToken.source();
        populateData(source);
        return () => source.cancel();
    }, []);

    return (
        <div>
            <Grid container alignContent="space-between">
                <Grid item xs={6}>
                    <PageHeader heading="Journal Entry" />
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                    {
                        safeInt(transactionIdentifier) > 0 ? null :
                            <>
                                <Grid item xs={12} sm={6}>
                                    <Paper elevation={3} style={{ padding: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" align="center" gutterBottom>
                                            Debit Entry
                                        </Typography>
                                        <JournalEntry
                                            transactionMode="Debit"
                                            handleAddEntry={submitFormData}
                                            fieldValues={fieldValues}
                                            setFieldValues={setFieldValues}
                                            transactionType={transactionType}
                                            bankList={bankList}
                                        />
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Paper elevation={3} style={{ padding: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" align="center" gutterBottom>
                                            Credit Entry
                                        </Typography>
                                        <JournalEntry
                                            transactionMode="Credit"
                                            handleAddEntry={submitFormData}
                                            fieldValues={fieldValues}
                                            setFieldValues={setFieldValues}
                                            transactionType={transactionType}
                                            bankList={bankList}
                                        />
                                    </Paper>
                                </Grid>
                            </>
                    }

                    <Grid item xs={12}>
                        <JournalEntryTable transactionIdentifier={transactionIdentifier} handleDeleteAction={handleDeleteEntries} fromEntries={fromEntriesTableData} toEntries={toEntriesTableData} mapTransactionType={mapTransactionType} />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    {
                        safeInt(transactionIdentifier) > 0 ? <>
                            <Grid item container xs={12} sm={12} className={classes.pullRight} style={{ marginTop: '10px' }} alignItems="center">
                                <Grid item xs={6}>
                                    <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => history.goBack()}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className={classes.pullRight}>
                                    <ButtonBase title="Delete Journal Entries" onClick={deleteJournalEntries}
                                    >
                                        <AppIcon color="error" name="Delete" size="medium" />
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </> : <>
                            <Grid item xs={12} className={classes.pullRight} style={{ marginTop: '10px' }}>
                                <Grid item xs={6}>
                                    <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => history.goBack()}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className={classes.pullRight}>
                                    <Button
                                        type="button"
                                        disabled={fromEntriesTableData.length === 0 && toEntriesTableData.length === 0}
                                        variant='contained'
                                        className={classes.mt1}
                                        color='primary'
                                        onClick={saveJournalEntries}
                                    >
                                        Save Entries
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    }

                </Grid>
            </Grid>
        </div >
    );
};

export default JournalEntries;
