import axios from 'axios';
import config from '../../config.json';


const WebAPI = axios.create({        
    baseURL: config.ENVIRONMENT == "PROD" ? config.SERVER_URL : config.SERVER_URL_LOCAL_HOST, timeout: config.REQUEST_TIMEOUT, headers: {'Content-Type' : 'application/json'}
});

WebAPI.CancelToken = axios.CancelToken;
WebAPI.isCancel = axios.isCancel;

export default WebAPI;