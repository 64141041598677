import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "@fontsource/roboto";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { AuthContextProvider } from "../src/components/Auth/AuthContext";

const customTheme = createTheme({
  palette: {

    // DEEP PURPLE 311b92
    //  primary: {
    //    main: '#311b92',
    //    light:'#6746c3',
    //    dark:'#000063',
    //    contrastText: '#ffffff',
    //  },
    //  secondary: {
    //   main: '#9fa8da',
    //   light:'#d1d9ff',
    //   dark:'#6f79a8',
    //   contrastText: '#000000',
    // },

    // PURPLE THEME
    primary: {
      main: '#232F3E',
      light: '#ffffff',
      dark: '#131921',
      contrastText: '#F7F7F7',
    },
    secondary: {
      main: '#205BD4',
      light: '#F0F8FF',
      dark: '#0d47a1',
      highlight : '#61AFFE',
      contrastText: '#F7F7F7',
    },

    success: {
      main: '#4caf50',
      light: '#c8e6c9',
      dark: '#1b5e20',
      contrastText: '#000050',
    },

    //  secondary: {
    //   main: '#e1bee7',
    //   light:'#fff1ff',
    //   dark:'#af8eb5',
    //   contrastText: '#000000',
    // },  

    //BLUE THEME
    // primary: {
    //   light: '#9a67ea',
    //   main: '#673ab7',
    //   dark: '#320b86',
    //   contrastText: '#ffffff',
    // },
    // secondary: {      
    //   light: '#60ad5e',
    //   main: '#2e7d32', 
    //   dark: '#005005',
    //   contrastText: '#fff',      
    // },

  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 640,
  //     md: 900,
  //     lg: 1200,
  //     xl: 1536,
  //     mobile: 0,
  //     tablet: 640,
  //     laptop: 1024,
  //     desktop: 1200,
  //   },
  // },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

ReactDOM.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={customTheme}>
      <AuthContextProvider>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </ThemeProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
