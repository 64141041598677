import React, { useState, useContext, useEffect } from 'react';
import { PageHeader } from '../UI/Controls/Controls';
import MuiTable from '../UI/Controls/MuiTable';
import { Grid, Typography } from '@material-ui/core';
import AppIcon from '../UI/Controls/AppIcons';
import AuthContext from "../Auth/AuthContext";
import useStore from '../Base/Store';
import axios from 'axios';
import { CUSTOMER_ENTITY, SUPPLIER_ENTITY, PRODUCT_ENTITY, INVOICE_ENTITY, PURCHASE_BILL_ENTITY } from '../Base/Common';
import { Autocomplete } from '@material-ui/lab';
import { TextInput } from '../UI/Controls/InputFields';
import { Form, Formik } from 'formik';
import * as DataProvider from '../Base/DataProvider';
import * as yup from 'yup';
import { safeBool, safeInt, isNullOrUndefined, safeString, filterOptions, getLocaleDate, formatCurrencyAmount, getAmountIncludingTax } from '../Base/Utils';
import shallow from 'zustand/shallow';
import SelectParty from '../UI/Controls/Business/SelectParty';
import { getPartyList } from '../Business/Party/PartyBusiness';

export default function PriceList({ isModal, selectedProductId }) {
    const [products, setProducts] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    const [salesData, setSalesData] = useState([]);
    const [purchaseData, setPurchaseData] = useState([]);
    const authContext = useContext(AuthContext);
    const showAlert = useStore(state => state.showAlert);

    const [fieldValues, setFieldValues] = useState({
        "productId": safeInt(selectedProductId),
        "productDescription": "",
        "supplierReferenceId": 0,
        "customerReferenceId": 0
    });
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);
    const taxInclusivePricing = safeBool(authContext.settings.taxInclusivePricing);
    const dialogData = useStore(state => state.dialogData);
    const [partiesData, setPartiesData] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);


    const [supplierData, setSupplierData] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);


    const populateData = async (source) => {
        try {
            setWaiting(true);
            var productResponse = await DataProvider.getEntityList(PRODUCT_ENTITY, authContext, source.token);
            if (!isNullOrUndefined(productResponse) && safeInt(productResponse.code) > 0) {
                showAlert(productResponse.message, 'error');
            } else {
                setProducts(productResponse);
                let productId = safeInt(selectedProductId);
                if (safeInt(dialogData?.productId) > 0) {
                    productId = safeInt(dialogData?.productId);
                }
                if (safeInt(productId) > 0) {
                    setSelectedProduct(productResponse.find(p => p.id === productId));
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    const getPriceList = async (productId = 0, referenceType = CUSTOMER_ENTITY, referenceId = 0) => {
        try {
            let request = {
                productId: productId,
                referenceType: referenceType,
                referenceId: referenceId
            }
            const methodName = referenceType === CUSTOMER_ENTITY ? "GetSalesPriceList" : "GetPurchasePriceList";
            const entityName = referenceType === CUSTOMER_ENTITY ? INVOICE_ENTITY : PURCHASE_BILL_ENTITY;
            const response = await DataProvider.getDataList(entityName, methodName, request, authContext, null, 'put');
            if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
                showAlert(safeString(response.message), 'error');
            } else {
                if (referenceType === CUSTOMER_ENTITY) {
                    setSalesData(response);
                } else {
                    setPurchaseData(response);
                }
            }

        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            await populateData(source);
            let productId = safeInt(selectedProductId);
            if (safeInt(dialogData?.productId) > 0) {
                productId = safeInt(dialogData?.productId);
            }
            if (safeInt(productId) > 0) {
                await getPriceList(safeInt(productId), CUSTOMER_ENTITY, 0);
                await getPriceList(safeInt(productId), SUPPLIER_ENTITY, 0);
            }
            const partiesResponse = await getPartyList(CUSTOMER_ENTITY, authContext, showAlert);
            if (!isNullOrUndefined(partiesResponse)) {
                setPartiesData(partiesResponse);
            }

            const supplierResponse = await getPartyList(SUPPLIER_ENTITY, authContext, showAlert);
            if (!isNullOrUndefined(supplierResponse)) {
                setSupplierData(supplierResponse);
            }
        }
        init();
        return () => {
            source.cancel();
            setFieldValues({});
        };
    }, []);


    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({
            })}
        >
            {({ values, setFieldValue }) => (

                <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <PageHeader heading="Historical item rates" subHeading={values.orderNumber}>
                                <AppIcon name="History" size="medium" color="primary" />
                            </PageHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                filterOptions={filterOptions}
                                size="small"
                                id="productId"
                                name="productId"
                                options={products}
                                getOptionLabel={(option) => !isNullOrUndefined(option) ? (safeString(option.description) + " " + safeString(option.productCode) + " " + safeString(option.barCode)) : ""}
                                value={selectedProduct}
                                onChange={async (e, val) => {
                                    try {
                                        setWaiting(true);
                                        if (!isNullOrUndefined(val)) {
                                            setFieldValue('productId', safeInt(val.id));
                                            setSelectedProduct(val);
                                            if (safeInt(val?.id) > 0) {
                                                await getPriceList(safeInt(val?.id), CUSTOMER_ENTITY, values.customerReferenceId);
                                                await getPriceList(safeInt(val?.id), SUPPLIER_ENTITY, values.supplierReferenceId);                                                
                                            }
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    } finally {
                                        setWaiting(false);
                                    }

                                }}
                                disabled={waiting}
                                renderInput={(props) =>
                                    <TextInput fullWidth name="productDescription"  {...props} label="Select item" variant="outlined" required
                                        disabled={waiting} />
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div style={{ marginBottom: '8px' }}>
                                <Typography variant="subtitle1" color="primary" style={{ marginBottom: '5px' }}>Sales</Typography>
                                {
                                    !isNullOrUndefined(selectedProduct) &&
                                    <SelectParty
                                        name={`customerReferenceId`}
                                        parties={partiesData}
                                        selectedParty={selectedParty}
                                        referenceType={"Customer"}
                                        handleSelectionChanged={async (event, newValue) => {
                                            setSelectedParty(newValue);
                                            setFieldValue('customerReferenceId', safeInt(newValue?.id));
                                            await getPriceList(safeInt(selectedProduct?.id), CUSTOMER_ENTITY, safeInt(newValue?.id));                                            
                                        }}
                                    />
                                }

                            </div>
                            <MuiTable columns={[
                                {
                                    title: 'Date', field: 'orderDate', alwaysVisible: true, disableClick: true,
                                    render: rowData => <span>{getLocaleDate(rowData.orderDate)}</span>,
                                    customSort: (a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime()
                                },
                                { title: 'Party', field: 'referenceName', disableClick: true },
                                { title: 'Qty', field: 'quantity', alwaysVisible: true, align: 'right', disableClick: true },
                                {
                                    title: 'Rate', field: 'unitPrice', alwaysVisible: true, disableClick: true, align: 'right',
                                    render: rowData => formatCurrencyAmount(currencyCode, taxInclusivePricing ? getAmountIncludingTax(rowData.unitPrice, rowData.taxRate) : rowData.unitPrice, 'green')
                                },
                            ]} data={salesData} actions={[]}
                                toolbar={true} search={true} exportButton={false} paging={false} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div style={{ marginBottom: '8px' }}>
                                <Typography variant="subtitle1" color="primary" style={{ marginBottom: '5px' }}>Purchases</Typography>
                                {
                                    !isNullOrUndefined(selectedProduct) &&
                                    <SelectParty
                                        name={`supplierReferenceId`}
                                        parties={supplierData}
                                        selectedParty={selectedSupplier}
                                        referenceType={"Supplier"}
                                        handleSelectionChanged={async (event, newValue) => {
                                            setSelectedSupplier(newValue);
                                            setFieldValue('supplierReferenceId', safeInt(newValue?.id))
                                            await getPriceList(safeInt(selectedProduct?.id), SUPPLIER_ENTITY, safeInt(newValue?.id));                                            
                                        }}
                                    />
                                }

                            </div>
                            <MuiTable columns={[
                                {
                                    title: 'Date', field: 'orderDate', alwaysVisible: true, disableClick: true,
                                    render: rowData => <span>{getLocaleDate(rowData.orderDate)}</span>,
                                    customSort: (a, b) => new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime()
                                },
                                { title: 'Party', field: 'referenceName', disableClick: true },
                                { title: 'Qty', field: 'quantity', alwaysVisible: true, align: 'right', disableClick: true },
                                {
                                    title: 'Rate', field: 'unitPrice', alwaysVisible: true, disableClick: true, align: 'right',
                                    render: rowData => formatCurrencyAmount(currencyCode, taxInclusivePricing ? getAmountIncludingTax(rowData.unitPrice, rowData.taxRate) : rowData.unitPrice, 'navy')
                                },
                            ]} data={purchaseData} actions={[]}
                                toolbar={true} search={true} exportButton={false} paging={false} />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
