
import { isEmptyArray } from "formik";
import { CREDIT_NOTE_ENTITY, DEBIT_NOTE_ENTITY, INVOICE_ENTITY, QUOTATION_ENTITY, TAX_REG_COMPOSITION_SCHEME, TAX_REG_UNREGISTERED } from "../../../../Base/Common";
import { getAdditioanlFieldValue, ifNullOrEmpty, isNullOrEmpty, isNullOrUndefined, safeInt, safeString, getLocaleDate, getAmountInWords, safeBool, safeFloat, safeAmount, formatToCurency, getLocaleDateTime, getLocaleDate2, isEmptyObjectArray, formatAadhaarNumber } from "../../../../Base/Utils";
import { getAdditionalDetails } from "../../../OrderHelper";
import { getEntityFields, getEInvoiceQRCode } from "../../PDFHelper";
import { getFullAddressString, getLabelText, getPDFQRCode, getPDFStateName, getPlaceOfSupply } from "../ProcessPDFHelper";

export function header(printData) {
    var headerDefinition = {

    }
    return headerDefinition;
}
export function getFooter(currentPage, pageCount, printData, entityType = '', printFormatCustomSettings) {
    var stackData = [];
    if (safeBool(printFormatCustomSettings?.hideFooter) || !safeBool(printFormatCustomSettings?.repeatFooter)) {
        stackData.push({ text: 'Declaration: Certified that the particulars given above are true and correct.', alignment: 'center', fontSize: 8 });
        stackData.push({ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 12, bold: true, alignment: 'right', margin: [0, -8, 20, 0] });
        stackData.push({ text: 'This is a computer generated document.', alignment: 'center', fontSize: 8 });
    } else {
        var footerDefinition = {
            style: 'footerTable',
            table: {
                widths: ['65%', '35%'],
                body: [
                    [
                        getFooterSummary(printData.settings, printData.bankDetails, printFormatCustomSettings, printData.voucherData),
                        getOrderSummary(printData, printFormatCustomSettings)
                    ],
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 1;
                },
                vLineWidth: function (i, node) {
                    return 1;
                },
                hLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                vLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                paddingTop: function (i, node) { return 0; },
                paddingBottom: function (i, node) { return 0; },
                paddingLeft: function (i, node) { return 0; },
                paddingRight: function (i, node) { return 0; }
            }
        };
        stackData.push(footerDefinition);
        stackData.push({ text: 'Declaration: Certified that the particulars given above are true and correct.', alignment: 'center', fontSize: 8 });
        stackData.push({ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 12, bold: true, alignment: 'right', margin: [0, -8, 20, 0] });
        stackData.push({ text: 'This is a computer generated document.', alignment: 'center', fontSize: 8 });
    }
    return {
        stack: stackData
    }
}

export function getFooterSummary(settings, bankdetails, printFormatCustomSettings, voucherData) {

    let tableBody = [];

    tableBody.push([
        {
            colSpan: 3, text: [
                { text: 'Amount in words: ', style: 'fontSubHeading2' },
                { text: getAmountInWords(voucherData?.grandTotal, true), bold: true }
            ], bold: false, style: 'fontSubHeading2', alignment: 'left'
        },
        {},
        {}
    ]);

    if (safeBool(bankdetails?.isPrimary)) {
        tableBody.push([
            { colSpan: 2, text: 'Bank Details', bold: true, style: 'fontRegular', alignment: 'left' },
            {},
            { rowSpan: 6, stack: getPDFQRCode(bankdetails, 80, 'center'), },
        ]);

        tableBody.push([
            { text: 'A/c Name', style: 'fontRegular', alignment: 'left' },
            { text: bankdetails?.accountName, style: 'fontRegular', alignment: 'left', bold: true },
            {}
        ]);

        tableBody.push([
            { text: 'Bank Name', style: 'fontRegular', alignment: 'left' },
            { text: bankdetails?.bankName, style: 'fontRegular', alignment: 'left', bold: true },
            {}
        ]);

        tableBody.push([
            { text: 'Branch', style: 'fontRegular', alignment: 'left' },
            { text: bankdetails?.branchName, style: 'fontRegular', alignment: 'left', bold: true },
            {}
        ]);

        tableBody.push([
            { text: 'Ac. No.', style: 'fontRegular', alignment: 'left' },
            { text: bankdetails?.accountNumber, style: 'fontRegular', alignment: 'left', bold: true },
            {}
        ]);

        tableBody.push([
            { text: 'IFSC', style: 'fontRegular', alignment: 'left' },
            { text: bankdetails?.ifscCode, style: 'fontRegular', alignment: 'left', bold: true },
            {}
        ])
    } else {
        tableBody.push([
            { colSpan: 3, text: '', style: 'fontRegular', alignment: 'left' },
            {},
            {}
        ]);
    }

    tableBody.push([
        {
            colSpan: 2,
            text: 'Terms and Conditions',
            style: 'fontRegular',
            alignment: 'left',
            bold: true,
        },
        {},
        { text: '(E. & 0.E)', bold: false, alignment: 'right', style: 'fontRegular', }
    ]);

    tableBody.push([
        { colSpan: 3, text: settings.invoiceTermsAndConditions, style: 'fontRegular', alignment: 'left' },
    ])


    try {

        let footerSummary = [
            {
                style: 'nestedTable',
                table: {
                    widths: ['auto', 'auto', '*'],
                    body: tableBody
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 1 || i === 7 ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    hLineColor: function (i, node) {
                        return printFormatCustomSettings.borderColor;
                    },
                    vLineColor: function (i, node) {
                        return printFormatCustomSettings.borderColor;
                    },
                    paddingTop: function (i, node) {
                        return (i === 0 || i === 8) ? 2 : 0.5;
                    },
                    paddingBottom: function (i, node) {
                        return (i === 0 || i === 8) ? 2 : 0.5;
                    }
                }
            }
        ];
        return footerSummary;
    } catch (error) {
        console.error('Error in getFooterSummary:', error);
        return [];
    }
}




export function getOrderSummary(printData, printFormatCustomSettings) {
    let tableBody = [];

    const subscriber = printData.subscriber;
    const settings = printData.settings;
    const voucherData = printData.voucherData;
    var totalAmountDue = printData.totalPaymentDueAmount;
    var paymentInfo = printData.paymentInfo;

    const isCompositionScheme = subscriber.taxRegistrationTypeId == TAX_REG_COMPOSITION_SCHEME;
    const isUnregisteredBusiness = subscriber.taxRegistrationTypeId == TAX_REG_UNREGISTERED;
    const printTaxDetails = isCompositionScheme === false && isUnregisteredBusiness === false;
    const orderAdjustments = voucherData?.orderAdjustments;

    if (printTaxDetails) {
        tableBody.push([
            { text: 'Taxable Total', bold: true, style: 'fontSubHeading2', alignment: 'left', fillColor: printFormatCustomSettings.backgroundFillColor },
            { text: safeFloat(voucherData.orderTotal).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right', fillColor: printFormatCustomSettings.backgroundFillColor },
        ]);

        if (safeBool(voucherData.isIGST)) {
            tableBody.push([
                { text: 'Add: IGST', bold: true, style: 'fontSubHeading2', alignment: 'left' },
                { text: safeFloat(voucherData?.taxAmount).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
            ])
        } else {
            tableBody.push([
                { text: 'Add: CGST', bold: true, style: 'fontSubHeading2', alignment: 'left' },
                { text: safeFloat(voucherData?.taxAmount / 2).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
            ]);

            tableBody.push([
                { text: 'Add: SGST', bold: true, style: 'fontSubHeading2', alignment: 'left' },
                { text: safeFloat(voucherData?.taxAmount / 2).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
            ])
        }

        tableBody.push([
            { text: 'Total Tax', bold: true, style: 'fontSubHeading2', alignment: 'left', fillColor: printFormatCustomSettings.backgroundFillColor },
            { text: safeFloat(voucherData?.taxAmount, 2), bold: true, style: 'fontSubHeading2', alignment: 'right', fillColor: printFormatCustomSettings.backgroundFillColor },
        ]);
    }
    if (!isEmptyArray(orderAdjustments) && !isEmptyObjectArray(orderAdjustments)) {
        for (let i = 0; i < orderAdjustments.length; i++) {
            let orderAdjustmentItem = orderAdjustments[i];
            if (orderAdjustmentItem.adjustmentType === 'TDS' || orderAdjustmentItem.adjustmentType === 'TCS') {
                tableBody.push([
                    { text: `${safeString(orderAdjustmentItem?.adjustmentText)}%`, bold: true, style: 'fontSubHeading2', alignment: 'left', },
                    { text: safeAmount(orderAdjustmentItem?.adjustmentAmount).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
                ]);
            } else {
                tableBody.push([
                    { text: `${safeString(orderAdjustmentItem?.adjustmentText)}`, bold: true, style: 'fontSubHeading2', alignment: 'left', },
                    { text: safeAmount(orderAdjustmentItem?.adjustmentAmount).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
                ]);
            }
        }
    }
    if (safeFloat(voucherData.orderDiscountAmount) > 0) {
        tableBody.push([
            { text: 'Discount', bold: true, italics: true, style: 'fontSubHeading2', alignment: 'left' },
            {
                text: `-${safeFloat(voucherData.orderDiscountAmount).toFixed(2)}`,
                bold: true, italics: true, style: 'fontSubHeading2', alignment: 'right'
            },
        ])
    }

    tableBody.push([
        { text: 'Round off', bold: true, italics: true, style: 'fontSubHeading2', alignment: 'left' },
        { text: safeFloat(voucherData?.roundOffAmount).toFixed(2), bold: true, italics: true, style: 'fontSubHeading2', alignment: 'right' },
    ]);

    tableBody.push([
        { text: 'Grand Total', bold: true, style: 'fontSubHeading2', alignment: 'left', fillColor: printFormatCustomSettings.backgroundFillColor },
        { text: safeFloat(voucherData?.grandTotal, 2).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right', fillColor: printFormatCustomSettings.backgroundFillColor },
    ]);

    if (!isNullOrUndefined(paymentInfo) && safeBool(settings.printPaymentInfo)) {
        tableBody.push([
            { text: 'Received', bold: true, style: 'fontSubHeading2', alignment: 'left', },
            { text: safeFloat(paymentInfo?.amountReceived).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
        ])
    }

    if (!isNullOrUndefined(paymentInfo) && safeBool(settings.printPaymentInfo)) {
        tableBody.push([
            { text: 'Due', bold: true, style: 'fontSubHeading2', alignment: 'left', },
            { text: safeFloat(paymentInfo.amountDue).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' },
        ])
    }
    if (!isNullOrUndefined(totalAmountDue) && safeBool(settings.printTotalPendingAmount)) {
        tableBody.push([
            { text: 'Total Outstanding', bold: true, style: 'fontSubHeading2', alignment: 'left', },
            { text: safeFloat(totalAmountDue, 2).toFixed(2), bold: true, style: 'fontSubHeading2', alignment: 'right' }
        ]);
    }


    let stackData = null;
    const emptySpaceHeight = 27;

    if (!isNullOrEmpty(subscriber.signatureAndSeal)) {
        stackData = {
            image: subscriber.signatureAndSeal,
            width: 90,
            alignment: 'center'

        };
    } else if (!isNullOrEmpty(settings.authorisedSignature)) {
        stackData = {
            image: settings.authorisedSignature,
            width: 90,
            alignment: 'center'
        };
    } else {
        stackData = {
            stack: [
                { text: '', margin: [0, emptySpaceHeight] }
            ]
        };
    }

    tableBody.push([
        {
            colSpan: 2, stack: [
                { text: 'FOR ' + subscriber.legalName, alignment: 'center', bold: true, style: "fontRegular" },
                [stackData],
                { text: 'Authorised  Signatory ', alignment: 'center', bold: true, style: "fontRegular" },
            ]
        },
    ]);


    try {

        let footerOrderSummary = [
            {
                style: 'nestedTable',
                table: {
                    widths: ['*', 'auto'],
                    body: tableBody
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 ? 0 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    hLineColor: function (i, node) {
                        return printFormatCustomSettings.borderColor;
                    },
                    vLineColor: function (i, node) {
                        return printFormatCustomSettings.borderColor;
                    }
                }
            }
        ];


        return footerOrderSummary;
    } catch (error) {
        console.error('Error in getfooterOrderSummary:', error);
        return [];
    }
}
export function getCompanyDetails(printData, printFormatCustomSettings) {
    const subscriber = printData.subscriber;
    const subscriberAddress = printData.subscriber.addressList[0];
    const stateList = printData.stateList;
    const drugLicense = getPDFDrugLicenseValue(subscriber);

    let lutNumber = "";
    let lutStartDate = "";
    let lutEndDate = "";
    if (!isNullOrEmpty(subscriber.additionalDetails) && safeInt(printData.party?.businessTypeId) === 4) {
        lutNumber = getAdditioanlFieldValue(JSON.parse(subscriber.additionalDetails), "lutNumber");
        lutStartDate = getAdditioanlFieldValue(JSON.parse(subscriber.additionalDetails), "lutStartDate");
        lutEndDate = getAdditioanlFieldValue(JSON.parse(subscriber.additionalDetails), "lutEndDate");
    }

    let companyAddress = [];
    companyAddress.push([{ text: subscriber.legalName, bold: true, style: 'fontMainHeading1', alignment: 'left' }])
    companyAddress.push([{ text: subscriberAddress.addressLine1, alignment: 'left', bold: false, style: "fontSubHeading1" }]);
    companyAddress.push([{ text: subscriberAddress.addressLine2, alignment: 'left', bold: false, style: "fontSubHeading1" }]);
    companyAddress.push([{ text: subscriberAddress?.city + ', ' + getPDFStateName(subscriberAddress?.stateId, stateList) + ' ' + subscriberAddress?.pinCode, alignment: 'left', bold: false, style: "fontSubHeading1" }]);
    // companyAddress.push([getLabelText('GSTIN:', subscriber.taxIdNumber, true, false, 'left', 'fontSubHeading2')]);


    let companyInfo = [];

    // companyInfo.push(getLabelText('Name:', subscriber.firstName, false, true, 'right'));
    companyInfo.push(getLabelText('Phone:', subscriberAddress.contactPhone, false, true, 'right'));
    companyInfo.push(getLabelText('Email:', subscriberAddress.contactEmail, false, true, 'right'));
    if (!isNullOrEmpty(subscriberAddress.website)) {
        companyInfo.push(getLabelText('Website:', subscriberAddress.website, false, true, 'right'));
    }

    if (!isNullOrEmpty(subscriber.additionalDetails)) {
        let additionalDetails = JSON.parse(subscriber.additionalDetails);
        let fssaiLicense = getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
        if (!isNullOrEmpty(fssaiLicense)) {
            companyInfo.push([getLabelText('FSSAI License:', fssaiLicense, true, false, 'right')]);
        }
        let importExportCode = getAdditioanlFieldValue(additionalDetails, 'importExportCode');
        if (!isNullOrEmpty(importExportCode)) {
            companyInfo.push([getLabelText('IEC:', importExportCode, true, false, 'right')]);
        }
        let msmeCertificateNumber = getAdditioanlFieldValue(additionalDetails, 'msmeCertificateNumber');
        if (!isNullOrEmpty(msmeCertificateNumber)) {
            companyInfo.push([getLabelText('MSME:', msmeCertificateNumber, true, false, 'right')]);
        }
        let CertificateOne = getAdditioanlFieldValue(additionalDetails, 'CertificateOne');
        if (!isNullOrEmpty(CertificateOne)) {
            companyInfo.push([getLabelText(' Certificate 1 #:', CertificateOne, true, false, 'right')]);
        }
        let CertificateTwo = getAdditioanlFieldValue(additionalDetails, 'CertificateTwo');
        if (!isNullOrEmpty(CertificateTwo)) {
            companyInfo.push([getLabelText(' Certificate 2 #:', CertificateTwo, true, false, 'right')]);
        }
        let tanNumber = getAdditioanlFieldValue(additionalDetails, 'tanNumber');
        if (!isNullOrEmpty(tanNumber)) {
            companyInfo.push([getLabelText('TAN Number:', tanNumber, true, false, 'right')]);
        }
        if (drugLicense !== '') {
            companyInfo.push([getLabelText('D.L. No.:', drugLicense, true, false, 'right')]);
        }
        if (safeInt(printData.party?.businessTypeId) === 4) {
            if (!isNullOrEmpty(lutNumber)) {
                companyInfo.push([getLabelText('LUT No.: ', lutNumber, true, false, 'right')]);
            }
            if (!isNullOrEmpty(lutStartDate)) {
                companyInfo.push([getLabelText('LUT Date: ', `${getLocaleDate(lutStartDate)} to ${getLocaleDate(lutEndDate)}`, true, false, 'right')]);
            }
        }
    }
    return [
        {
            style: 'nestedTable',
            table: {
                widths: ['*', 'auto'],
                body: [[companyAddress, companyInfo]]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                vLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                padding: function (i, node) { return 0; }
            }
        }
    ];
}

export function getVoucherHeading(printData) {
    let taxIdNumber = [];
    if (!isNullOrEmpty(printData.heading)) {
        taxIdNumber.push([{
            text: [{ text: printData.heading, bold: true, alignment: 'center', style: 'fontMainHeading1' }],
        }]);
    }
    return taxIdNumber;
}

export function getCreditDetails(voucherData, printFormatCustomSettings) {
    let isCreditValue = "";
    if (!safeBool(voucherData.isCredit)) {
        isCreditValue = "Yes"
    } else {
        isCreditValue = "No"
    }

    return isCreditValue;
}

export function getLogo(subscriber, printFormatCustomSettings) {
    const subscriberLogo = safeString(subscriber.logo);
    if (isNullOrEmpty(subscriberLogo)) {
        return [
            { text: '' }
        ];
    } else {
        return [
            {
                image: subscriberLogo,
                width: safeInt(printFormatCustomSettings?.logoWidth, 100),
                alignment: "left",
                margin: [5, 5, 5, 5]
            }
        ];
    }

}

function getPDFDrugLicenseValue(subscriber) {
    var drugLicencse = '';
    try {
        if (!isNullOrEmpty(subscriber.additionalDetails)) {
            const additionalDetails = JSON.parse(subscriber.additionalDetails);
            const drugLicense20B = !isNullOrUndefined(additionalDetails) ? getAdditioanlFieldValue(additionalDetails, 'drugLicense20B') : '';
            const drugLicense21B = !isNullOrUndefined(additionalDetails) ? getAdditioanlFieldValue(additionalDetails, 'drugLicense21B') : '';


            if (drugLicense20B !== '' || drugLicense21B !== '') {
                drugLicencse = drugLicense20B + "/" + drugLicense21B;
            }
        }
        return drugLicencse;
    } catch (error) {
        return drugLicencse;
    }
}

export function getPDFPageBorder(printFormatCustomSettings) {
    if (!safeBool(printFormatCustomSettings.repeatFooter)) {
        return [
            {
                canvas: [
                    { type: 'line', x1: 20, y1: 30, x2: 575, y2: 30, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Up line
                    // { type: 'line', x1: 20, y1: 30, x2: 20, y2: 602, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //left line
                    // { type: 'line', x1: 20, y1: 822, x2: 575, y2: 822, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Bottom line
                    // { type: 'line', x1: 575, y1: 30, x2: 575, y2: 602, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Rigth line
                ]
            }
        ];
    } else {
        return [
            {
                canvas: [
                    { type: 'line', x1: 20, y1: 30, x2: 575, y2: 30, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Up line
                    { type: 'line', x1: 20, y1: 30, x2: 20, y2: 602, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //left line
                    // { type: 'line', x1: 20, y1: 822, x2: 575, y2: 822, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Bottom line
                    { type: 'line', x1: 575, y1: 30, x2: 575, y2: 602, lineWidth: 1, lineColor: printFormatCustomSettings.borderColor }, //Rigth line
                ]
            }
        ];
    }

}

export function getPartyDetails(printData, printFormatCustomSettings) {
    const party = printData.party;
    const partyName = getPDFPartyName(party, printData.entityName);
    const stateList = printData.stateList;
    const voucherData = printData.voucherData;
    const drugLicense = getPDFDrugLicenseValue(party);
    const fssaiLicense = getFssaiLicense(party);
    const additionalDetails = JSON.parse(party.additionalDetails);
    const aadhaarNumber = !isNullOrUndefined(additionalDetails) ? getAdditioanlFieldValue(additionalDetails, 'aadhaarNumber') : '';
    const panNumber = !isNullOrUndefined(additionalDetails) ? getAdditioanlFieldValue(additionalDetails, 'panNumber') : '';

    let partyAddress = {};
    if (!isNullOrUndefined(party.addressList) && party.addressList.length > 0) {
        partyAddress = party.addressList.find(a => a.addressType === 'Billing' && a.isPrimary === true)
    }

    let partyDetails = [
        {
            style: 'nestedTable',
            table: {
                widths: [40, '*'],
                body: [
                    [
                        [{ text: 'BILL TO', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: partyName, style: 'fontRegular', alignment: 'left' }]
                    ],
                    [
                        [{ text: 'Address', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: getFullAddressString(partyAddress, stateList), style: 'fontRegular', alignment: 'left' }]
                    ],
                    [
                        [{ text: 'Phone', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: partyAddress.contactPhone, style: 'fontRegular', alignment: 'left' }]
                    ],
                    [
                        [{ text: 'GSTIN', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: safeString(party.taxIdNumber), style: 'fontRegular', alignment: 'left', bold: true }]
                    ],
                    [
                        [{ text: 'Place of Supply', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: getPlaceOfSupply(voucherData, partyAddress, stateList), style: 'fontRegular', alignment: 'left' }]
                    ],
                    [
                        [{ text: partyAddress?.contactPerson !== "" ? 'Contact Person' : '', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: partyAddress?.contactPerson !== "" ? safeString(partyAddress?.contactPerson) : '', style: 'fontRegular', alignment: 'left' }]
                    ],
                    ...(drugLicense !== "" ? [[
                        [{ text: 'DL No', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: safeString(drugLicense), style: 'fontRegular', alignment: 'left' }]
                    ]] : []),
                    ...(fssaiLicense !== "" ? [[
                        [{ text: 'FSSAI License', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: safeString(fssaiLicense), style: 'fontRegular', alignment: 'left', bold: true }]
                    ]] : []),
                    ...(aadhaarNumber !== "" ? [[
                        [{ text: 'Aadhaar No.', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: formatAadhaarNumber(safeString(aadhaarNumber)), style: 'fontRegular', alignment: 'left', bold: true }]
                    ]] : []),
                    ...(panNumber !== "" ? [[
                        [{ text: 'PAN No.', bold: true, style: 'fontRegular', alignment: 'left' }], [{ text: safeString(panNumber), style: 'fontRegular', alignment: 'left', bold: true }]
                    ]] : [])
                ],
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                vLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                padding: function (i, node) { return 0; },
                margin: function (i, node) { return 0; }
            }
        }
    ];

    return [
        {
            style: 'nestedTable',
            table: {
                widths: ['*'],
                body: [
                    [{ text: 'Customer Details', style: 'fontRegular', alignment: 'center', bold: true }],
                    [partyDetails]
                ],
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === 1 ? 0.5 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                vLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                padding: function (i, node) { return 0; },
                margin: function (i, node) { return 0; }
            }
        }
    ];

}

export function getEntityDetails(printData, printFormatCustomSettings, entityName) {
    let voucherData = printData.voucherData;
    const stateList = printData.stateList;
    let shippingAddress = printData.deliveryAddress;

    const customFields = printData.customFields;
    let fieldDefinition = getEntityFields(customFields, INVOICE_ENTITY);
    let additionalDetails = getAdditionalDetails(voucherData);
    const isEInvoice = (!isNullOrUndefined(voucherData) && !isNullOrUndefined(voucherData.eInvoiceData));
    let voucherInfo = [];

    voucherInfo.push([
        { text: `${entityName} No`, style: 'fontRegular', alignment: 'left' },
        { text: voucherData.orderNumber, bold: true, style: 'fontRegular', alignment: 'left' },
        { text: `${entityName} Date`, style: 'fontRegular', alignment: 'left' },
        { text: getLocaleDate(voucherData.orderDate), bold: true, style: 'fontRegular', alignment: 'left' }
    ]);

    if (!isNullOrEmpty(voucherData?.originalOrderNumber) && (entityName === CREDIT_NOTE_ENTITY || entityName === DEBIT_NOTE_ENTITY)) {
        voucherInfo.push([
            { text: `${entityName === CREDIT_NOTE_ENTITY ? 'Buyer' : 'Vendor'}'s Ref :`, style: 'fontRegular', alignment: 'left' },
            { text: voucherData?.originalOrderNumber, bold: true, style: 'fontRegular', alignment: 'left' },
            { text: `Dated`, style: 'fontRegular', alignment: 'left' },
            { text: getLocaleDate(voucherData?.originalOrderDate), bold: true, style: 'fontRegular', alignment: 'left' }
        ]);
    }

    if (!isNullOrUndefined(fieldDefinition)) {
        let customFieldVal = '';
        let rowArray = [];
        for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
            const customField = fieldDefinition?.customFields[i];
            if (safeBool(customField?.print)) {
                if (safeBool(customField?.isStandard)) {
                    customFieldVal = safeString(voucherData[customField?.name]);
                } else {
                    if (!isNullOrUndefined(additionalDetails)) {
                        customFieldVal = safeString(additionalDetails[safeString(customField?.name)]);
                    } else {
                        customFieldVal = '';
                    }
                }
                if (customField?.type === 'DateTime') {
                    customFieldVal = getLocaleDateTime(customFieldVal);
                } else if (customField?.type === 'Date') {
                    customFieldVal = getLocaleDate2(customFieldVal);
                } else if (customField?.type === 'Currency') {
                    customFieldVal = safeFloat(customFieldVal).tofixed(2);
                }
                if (safeBool(customField.fullWidth)) {
                    voucherInfo.push([
                        { text: safeString(customField?.label), style: 'fontRegular', alignment: 'left' },
                        { colSpan: 3, text: customFieldVal, bold: true, style: 'fontRegular', alignment: 'left' }
                    ]);
                } else {
                    rowArray.push(
                        { text: safeString(customField?.label), style: 'fontRegular', alignment: 'left' }
                    );
                    rowArray.push(
                        { text: customFieldVal, bold: true, style: 'fontRegular', alignment: 'left' },
                    );
                    if (rowArray.length === 4) {
                        voucherInfo.push(rowArray);
                        rowArray = [];
                    } else if (rowArray.length === 2 && i === (fieldDefinition?.customFields.length - 1)) {
                        // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                        rowArray.push(
                            { text: '', style: 'fontRegular', alignment: 'left' }
                        );
                        rowArray.push(
                            { text: '', bold: true, style: 'fontRegular', alignment: 'left' },
                        );
                        voucherInfo.push(rowArray);
                        rowArray = [];
                    }
                }
                // At the end also check if any non-fullwidth fields were left out
                if (rowArray.length === 2 && i === (fieldDefinition?.customFields.length - 1)) {
                    rowArray.push(
                        { text: '', style: 'fontRegular', alignment: 'left' }
                    );
                    rowArray.push(
                        { text: '', bold: true, style: 'fontRegular', alignment: 'left' },
                    );
                    voucherInfo.push(rowArray);
                    rowArray = [];
                }
            }
        }
    } else {
        voucherInfo.push([
            { text: 'P.O. No', style: 'fontRegular', alignment: 'left' },
            { text: voucherData.poReference, bold: true, style: 'fontRegular', alignment: 'left' },
            { text: 'P.O. Date', style: 'fontRegular', alignment: 'left' },
            { text: getLocaleDate(voucherData.poDate), bold: true, style: 'fontRegular', alignment: 'left' }
        ]);
        voucherInfo.push([
            { text: 'E-Way No', style: 'fontRegular', alignment: 'left' },
            { text: voucherData.eWayBillNo, bold: true, style: 'fontRegular', alignment: 'left' },
            { text: 'Reference', style: 'fontRegular', alignment: 'left' },
            { text: voucherData.orderReference, bold: true, style: 'fontRegular', alignment: 'left' }
        ]);
        voucherInfo.push([
            { text: 'Transport details', style: 'fontRegular', alignment: 'left' },
            { colSpan: 3, text: voucherData.transportDetails, bold: true, style: 'fontRegular', alignment: 'left' }
        ]);
        voucherInfo.push([
            { text: 'Notes/Terms', style: 'fontRegular', alignment: 'left' },
            { colSpan: 3, text: voucherData.termsAndConditions, bold: true, style: 'fontRegular', alignment: 'left' }
        ]);
    }

    voucherInfo.push([
        { text: 'Cash Bill', style: 'fontRegular', alignment: 'left' },
        { colSpan: 3, text: getCreditDetails(voucherData, printFormatCustomSettings), bold: true, style: 'fontRegular', alignment: 'left' }
    ]);

    voucherInfo.push(
        [
            { colSpan: 2, stack: getShippingAddress(shippingAddress, stateList) },
            {},
            { colSpan: 2, stack: isEInvoice ? getEInvoiceQRCode(voucherData) : [] }
        ]);


    let entityDetails = [
        {
            style: 'nestedTable',
            table: {
                widths: ['auto', '*', 'auto', '*'],
                body: voucherInfo,
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === node.table.body.length - 1 && !isNullOrUndefined(shippingAddress) ? 0.5 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                vLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                padding: function (i, node) { return 0; },
                margin: function (i, node) { return 0; }
            }
        }
    ];
    return entityDetails;
}


export function getQuotationEntityDetails(printData, printFormatCustomSettings, entityName) {
    let voucherData = printData.voucherData;
    const stateList = printData.stateList;
    let shippingAddress = printData.deliveryAddress;

    const customFields = printData.customFields;
    let fieldDefinition = getEntityFields(customFields, QUOTATION_ENTITY);
    let additionalDetails = getAdditionalDetails(voucherData);

    let voucherInfo = [];

    voucherInfo.push([
        { text: `${printData.heading === "Proforma Invoice" ? "P.I:" : 'Quotation #:'}`, style: 'fontRegular', alignment: 'left' },
        { text: voucherData.orderNumber, bold: true, style: 'fontRegular', alignment: 'left' },
        { text: `${printData.heading === "Proforma Invoice" ? "P.I date:" : 'Quotation date: '}`, style: 'fontRegular', alignment: 'left' },
        { text: getLocaleDate(voucherData.orderDate), bold: true, style: 'fontRegular', alignment: 'left' },
    ]);
    voucherInfo.push([
        { text: 'Reference:', style: 'fontRegular', alignment: 'left' },
        { colSpan: 3, text: voucherData.orderReference, bold: true, style: 'fontRegular', alignment: 'left' }
    ]);
    voucherInfo.push([
        { text: 'Notes/Terms', style: 'fontRegular', alignment: 'left' },
        { colSpan: 3, text: voucherData.termsAndConditions, bold: true, style: 'fontRegular', alignment: 'left' }
    ]);

    if (!isNullOrUndefined(fieldDefinition)) {
        let customFieldVal = '';
        let rowArray = [];
        for (let i = 0; i < fieldDefinition?.customFields.length; i++) {
            const customField = fieldDefinition?.customFields[i];
            if (safeBool(customField?.print)) {
                if (safeBool(customField?.isStandard)) {
                    customFieldVal = safeString(voucherData[customField?.name]);
                } else {
                    if (!isNullOrUndefined(additionalDetails)) {
                        customFieldVal = safeString(additionalDetails[safeString(customField?.name)]);
                    } else {
                        customFieldVal = '';
                    }
                }
                if (customField?.type === 'DateTime') {
                    customFieldVal = getLocaleDateTime(customFieldVal);
                } else if (customField?.type === 'Date') {
                    customFieldVal = getLocaleDate2(customFieldVal);
                } else if (customField?.type === 'Currency') {
                    customFieldVal = safeFloat(customFieldVal).tofixed(2);
                }
                if (safeBool(customField.fullWidth)) {
                    voucherInfo.push([
                        { text: safeString(customField?.label), style: 'fontRegular', alignment: 'left' },
                        { colSpan: 3, text: customFieldVal, bold: true, style: 'fontRegular', alignment: 'left' }
                    ]);
                } else {
                    rowArray.push(
                        { text: safeString(customField?.label), style: 'fontRegular', alignment: 'left' }
                    );
                    rowArray.push(
                        { text: customFieldVal, bold: true, style: 'fontRegular', alignment: 'left' },
                    );
                    if (rowArray.length === 4) {
                        voucherInfo.push(rowArray);
                        rowArray = [];
                    } else if (rowArray.length === 2 && i === (fieldDefinition?.customFields.length - 1)) {
                        // If it was the last field and we could not fit in two columns, ad empty text in the second column and insert
                        rowArray.push(
                            { text: '', style: 'fontRegular', alignment: 'left' }
                        );
                        rowArray.push(
                            { text: '', bold: true, style: 'fontRegular', alignment: 'left' },
                        );
                        voucherInfo.push(rowArray);
                        rowArray = [];
                    }
                }
                // At the end also check if any non-fullwidth fields were left out
                if (rowArray.length === 2 && i === (fieldDefinition?.customFields.length - 1)) {
                    rowArray.push(
                        { text: '', style: 'fontRegular', alignment: 'left' }
                    );
                    rowArray.push(
                        { text: '', bold: true, style: 'fontRegular', alignment: 'left' },
                    );
                    voucherInfo.push(rowArray);
                    rowArray = [];
                }
            }
        }
    } else {
        voucherInfo.push([
            { text: `${printData.heading === "Proforma Invoice" ? "P.I:" : 'Quotation #:'}`, style: 'fontRegular', alignment: 'left' },
            { text: voucherData.orderNumber, bold: true, style: 'fontRegular', alignment: 'left' },
            { text: `${printData.heading === "Proforma Invoice" ? "P.I date:" : 'Quotation date: '}`, style: 'fontRegular', alignment: 'left' },
            { text: getLocaleDate(voucherData.orderDate), bold: true, style: 'fontRegular', alignment: 'left' }
        ]);
    }
    voucherInfo.push(
        [
            { colSpan: 2, stack: getShippingAddress(shippingAddress, stateList) },
            {},
            { colSpan: 2, stack: [] }
        ]);


    let entityDetails = [
        {
            style: 'nestedTable',
            table: {
                widths: ['auto', '*', 'auto', '*'],
                body: voucherInfo,
                margin: [0, 0, 0, 0],
                padding: [0, 0, 0, 0]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === node.table.body.length - 1 && !isNullOrUndefined(shippingAddress) ? 0.5 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                vLineColor: function (i, node) {
                    return printFormatCustomSettings.borderColor;
                },
                padding: function (i, node) { return 0; },
                margin: function (i, node) { return 0; }
            }
        }
    ];
    return entityDetails;
}

export function getPDFPartyAddress(printData) {
    const party = printData.party;
    const partyName = getPDFPartyName(party);
    const drugLicense = getPDFDrugLicenseValue(party);
    const fssaiLicense = getFssaiLicense(party);
    const stateList = printData.stateList;
    const voucherData = printData.voucherData;
    var partyAddress = {};

    let aadhaarNumber = "";
    if (!isNullOrEmpty(party.additionalDetails)) {
        aadhaarNumber = getAdditioanlFieldValue(JSON.parse(party.additionalDetails), 'aadhaarNumber')
    }

    if (!isNullOrUndefined(party.addressList) && party.addressList.length > 0) {
        partyAddress = party.addressList.find(a => a.addressType === 'Billing' && a.isPrimary === true)
    }
    var address = [];
    address.push([{ text: partyName, style: 'fontSubHeading2' }]);
    if (drugLicense !== '') {
        address.push([{ text: ['D.L. No.: ', { text: drugLicense, bold: true }], alignment: 'left' }]);
    }
    if (!isNullOrEmpty(fssaiLicense)) {
        address.push([{ text: ['FSSAI License.: ', { text: fssaiLicense, bold: true }], fontSize: 9, alignment: 'left' }]);
    }
    if (!isNullOrEmpty(aadhaarNumber)) {
        address.push([{ text: ['Aadhar Number.: ', { text: aadhaarNumber, bold: true }], fontSize: 9, alignment: 'left' }]);
    }

    address.push([{ text: safeString(partyAddress.addressLine1) + ' ' + safeString(partyAddress.addressLine2), bold: false, fontSize: 9 }]);
    address.push([{ text: safeString(partyAddress.city) + ' ' + getPDFStateName(safeString(partyAddress.stateId), stateList) + ' ' + safeString(partyAddress.pinCode), bold: false, fontSize: 9 }]);


    if (!isNullOrEmpty(partyAddress.contactPerson)) {
        address.push([{ text: ['Contact Name: ', { text: safeString(partyAddress.contactPerson) + ' PH: ' + safeString(partyAddress.contactPhone), bold: true, fontSize: 9 }], fontSize: 9 }]);
    } else {
        address.push([{ text: ['PH: ', { text: safeString(partyAddress.contactPhone), bold: true, fontSize: 9 }], fontSize: 9 }]);
    }
    address.push([{ text: [' Email: ' + safeString(partyAddress.contactEmail).substr(0, 63)], fontSize: 9 }]);
    address.push([{ text: 'State code/Place of supply: ' + safeString(ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId)) + '-' + getPDFStateName(ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId), stateList), bold: true, fontSize: 9 }]);
    if (!isNullOrEmpty(party.taxIdNumber)) {
        address.push([{ text: "GSTIN: " + safeString(party.taxIdNumber), bold: true, fontSize: 9 }]);
    }

    return address;
}

export function getPDFShippingAddress(printData) {
    const deliveryAddress = printData.deliveryAddress;
    const stateList = printData.stateList;

    var address = [];
    if (isNullOrUndefined(deliveryAddress)) {
        address.push([{ text: ['', { text: '', bold: true }] }]);
    } else {
        address.push([{ text: ['SHIP TO: ', { text: safeString(deliveryAddress.description), bold: true }], fontSize: 9 }]);
        address.push([{ text: safeString(deliveryAddress.addressLine1) + ' ' + safeString(deliveryAddress.addressLine2) + ' ' + safeString(deliveryAddress.city) + ' ' + getPDFStateName(safeString(deliveryAddress.stateId), stateList) + ' ' + safeString(deliveryAddress.pinCode), bold: false, fontSize: 9 }]);
        if (!isNullOrEmpty(deliveryAddress.contactPerson)) {
            address.push([{ text: ['Contact Name: ', { text: safeString(deliveryAddress.contactPerson), bold: true, fontSize: 9 }], fontSize: 9 }]);
        }
        address.push([{ text: ['PH: ', { text: safeString(deliveryAddress.contactPhone), bold: true, fontSize: 9 }], fontSize: 9 }]);
        address.push([{ text: [' Email: ' + safeString(deliveryAddress.contactEmail).substr(0, 63)], fontSize: 9 }]);
        address.push([{ text: 'State code/Place of supply: ' + safeString(deliveryAddress.stateId) + '-' + getPDFStateName(safeString(deliveryAddress.stateId), stateList), bold: true, fontSize: 9 }]);
    }
    return address;
}

export function getPDFPartyName(party) {
    return party.legalName;
}

function getFssaiLicense(subscriber) {
    var fssaiLicense = '';
    try {
        if (!isNullOrEmpty(subscriber.additionalDetails)) {
            const additionalDetails = JSON.parse(subscriber.additionalDetails);
            const fssaiLicenseData = !isNullOrUndefined(additionalDetails) ? getAdditioanlFieldValue(additionalDetails, 'fssaiLicense') : '';
            if (fssaiLicenseData !== '') {
                fssaiLicense = fssaiLicenseData;
            }
        }
        return fssaiLicense;
    } catch (error) {
        return fssaiLicense;
    }
}

function getShippingAddress(deliveryAddress, stateList) {
    var address = [];
    if (isNullOrUndefined(deliveryAddress)) {
        address.push([{ text: ['', { text: '', bold: true, style: 'fontRegular' }], style: 'fontRegular' }]);
    } else {
        address.push([{ text: ['SHIP TO: ', { text: safeString(deliveryAddress.description), bold: false, style: 'fontRegular' }], style: 'fontRegular', bold: true }]);
        address.push([{ text: getFullAddressString(deliveryAddress, stateList), bold: false, style: 'fontRegular' }]);
        if (!isNullOrEmpty(deliveryAddress.contactPerson)) {
            address.push([{ text: ['Contact Name: ', { text: safeString(deliveryAddress.contactPerson), bold: true, style: 'fontRegular' }], style: 'fontRegular' }]);
        }
        address.push([{ text: ['PH: ', { text: safeString(deliveryAddress.contactPhone), bold: true, style: 'fontRegular' }], style: 'fontRegular' }]);
        address.push([{ text: [' Email: ' + safeString(deliveryAddress.contactEmail)], style: 'fontRegular' }]);
        address.push([{ text: `Place of supply: ${getPDFStateName(safeString(deliveryAddress.stateId), stateList)} (${safeString(deliveryAddress.stateId)})`, bold: false, style: 'fontRegular' }]);
    }
    return address;
}
