import { useContext, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { PageHeader } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import { NumberInput, SelectInput } from '../../UI/Controls/InputFields';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import shallow from 'zustand/shallow';
import useStore from '../../Base/Store';
import { isNullOrUndefined, safeInt } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';

const POSvoucherSettings = ({ entityType }) => {
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const authContext = useContext(AuthContext);
    const additionalDetailData = JSON.parse(authContext.settings.additionalDetails);
    var fontValue;
    if (!isNullOrUndefined(additionalDetailData.POSinvoiceSettings) && additionalDetailData.POSinvoiceSettings !== "") {
        var savedFontSize = JSON.parse(additionalDetailData.POSinvoiceSettings);
        fontValue = safeInt(savedFontSize.tableFontSize);
    } else {
        fontValue = 8;
    }
    const initialValues = {
        itemSize: fontValue,
    };


    const submitFormData = (values) => {
        const POSvoucherSettingsData = {
            tableFontSize: safeInt(values.itemSize),
        };
        const POSresponse = {
            POSvoucherSettingsData: POSvoucherSettingsData,
            entityType: entityType
        }
        setDialogResponse(POSresponse);
        closeDialog();
    }

    const validationSchema = Yup.object({
        itemSize: Yup.number().min(8, 'Minimum is 8px').max(16, 'Maximum is 16px').required('Required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await submitFormData(values)
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12}>
                <PageHeader heading="POS Voucher Settings" style={{ fontSize: `${formik.values.fontMainHeading}px` }}>
                    <AppIcon name="Settings" color="primary" />
                </PageHeader>
            </Grid>

            {/* Heading Font Size Input */}
            {/*           <Grid item xs={12} style={{ margin: '20px 0' }}>
                <NumberInput
                    label="Heading Font Size"
                    type="number"
                    fullWidth
                    name="fontMainHeading"
                    value={formik.values.fontMainHeading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fontMainHeading && Boolean(formik.errors.fontMainHeading)}
                    helperText={formik.touched.fontMainHeading && formik.errors.fontMainHeading}
                    InputProps={{ inputProps: { min: 8, max: 32 } }}
                />
            </Grid> */}

            {/* Table Font Size Selection */}
            <Grid item xs={12} justifyContent="center" style={{ marginTop: '10px' }}>
                <SelectInput
                    label="Select Item Table Size"
                    name="itemSize"
                    value={formik.values.itemSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.itemSize && Boolean(formik.errors.itemSize)}
                    helperText={formik.touched.itemSize && formik.errors.itemSize}
                    required
                >
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                </SelectInput>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '10px' }}>
                <Button type="submit" variant="contained" color="primary">
                    Save Settings
                </Button>
            </Grid>
        </form>
    );
};

export default POSvoucherSettings;
