import React from 'react';
import { PageHeader } from '../../UI/Controls/Controls';
import { Button, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import AppIcon from '../../UI/Controls/AppIcons';
import { CheckboxInput, DateInput, NumberInput, SelectInput, TextInput } from '../../UI/Controls/InputFields';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import shallow from 'zustand/shallow';
import useStyles from '../../../styles';
import useStore from '../../Base/Store';
import AuthContext from '../../Auth/AuthContext';
import * as yup from 'yup';
import { useContext } from 'react';
import { EBCheckBox } from './../../UI/Controls/Controls';
import { CUSTOMER_ENTITY, INVOICE_ENTITY, ORDER_TYPE_INVOICE } from '../../Base/Common';
import WebAPI from '../../Base/WebAPI';
import { getDatePart, getMinDate, getTodaysDate, isNullOrUndefined, safeBool, safeInt } from '../../Base/Utils';
import { getOrder } from '../../Shared/OrderHelper';
export const Recurring = ({ orderData, isEditMode }) => {
    const classes = useStyles();
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const frequencies = authContext.masterData.filter(md => md.type === 'Frequency');
    const showAlert = useStore(state => state.showAlert);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [isCreateOrderOnStartDate, setIsCreateOrderOnStartDate] = useState(false);


    const [fieldValues, setFieldValues] = useState({
        "id": safeInt(orderData.id) > 0 && isEditMode ? orderData.id : 0,
        "orderTypeId": ORDER_TYPE_INVOICE,
        "orderJSON": "",
        "name": safeInt(orderData.id) > 0 && isEditMode ? orderData.name : orderData.referenceName,
        "referenceId": safeInt(orderData.referenceId),
        "referenceType": CUSTOMER_ENTITY,
        "frequency": safeInt(orderData.id) > 0 && isEditMode ? orderData.frequency : 1,
        "frequencyPeriod": 0,
        "frequencyPrecision": safeInt(orderData.id) > 0 && isEditMode ? orderData.frequencyPrecision : 1,
        "startDate": safeInt(orderData.id) > 0 && isEditMode ? getDatePart(orderData.startDate) : (() => {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return getDatePart(tomorrow.toISOString());
        })(),
        "firstOrderDate": safeInt(orderData.id) > 0 && isEditMode ? getDatePart(orderData.firstOrderDate) : getDatePart('1899-12-30'),
        "endDate": safeInt(orderData.id) > 0 && isEditMode ? getDatePart(orderData.endDate) : "",
        "numberOfOrders": safeInt(orderData.id) > 0 && isEditMode ? orderData.numberOfOrders : 0,
        "nextOrderDate": safeInt(orderData.id) > 0 && isEditMode ? getDatePart(orderData.nextOrderDate) : getDatePart('1899-12-30'),
        "autoEmail": safeInt(orderData.id) > 0 && isEditMode ? orderData.autoEmail : true,
        "autoSMS": safeInt(orderData.id) > 0 && isEditMode ? orderData.autoSMS : true,
        "autoWhatsApp": safeInt(orderData.id) > 0 && isEditMode ? orderData.autoWhatsApp : true,
        "active": true,
    });

    const submitFormData = async (values) => {
        try {

            setWaiting(true);
            var apiUrl = "/Invoice/CreateRecurringOrder";
            if (safeInt(orderData.id) > 0 && safeBool(isEditMode)) {
                apiUrl = '/Invoice/UpdateRecurringOrder';
            }

            // Check if both 'numberOfOrders' and 'endDate' are empty
            if (!values.numberOfOrders && !values.endDate) {
                showAlert("Number of orders or end date should be specified", 'error');
                setWaiting(false);
                return;
            }

            // Check if both 'numberOfOrders' and 'endDate' are filled
            if (values.numberOfOrders && values.endDate) {
                showAlert("Both number of orders and end date should not be specified", 'error');
                setWaiting(false);
                return;
            }


            var orderJson = await getOrder(safeInt(orderData.id), INVOICE_ENTITY, authContext);

            // Set endDate to values.endDate or "0001-01-01" if not present
            let endDateValue;
            if (values.endDate) {
                endDateValue = values.endDate;
            } else {
                endDateValue = getDatePart('1899-12-30');
            }

            const request = {
                "id": safeInt(values.id),
                "orderTypeId": ORDER_TYPE_INVOICE,
                "orderJSON": JSON.stringify(orderJson),
                "name": values.name,
                "referenceId": safeInt(values.referenceId),
                "referenceType": CUSTOMER_ENTITY,
                "frequency": safeInt(values.frequency),
                "frequencyPeriod": 0,
                "frequencyPrecision": safeInt(values.frequencyPrecision),
                "startDate": getDatePart(values.startDate),
                "firstOrderDate": values.firstOrderDate,
                "endDate": endDateValue,
                "numberOfOrders": safeInt(values.numberOfOrders),
                "nextOrderDate": getDatePart(values.nextOrderDate),
                "autoEmail": values.autoEmail,
                "autoSMS": values.autoSMS,
                "autoWhatsApp": values.autoWhatsApp,
                "active": true,
            }

            const response = await WebAPI.post(apiUrl, request, { headers: { "AccessToken": authContext.token } });
            if (!isNullOrUndefined(response.data) && safeInt(response.data.code) > 0) {
                showAlert(response.data.message, 'error');
            } else {
                if (!isNullOrUndefined(response.data)) {
                    showAlert('Recurring Invoice save successfully !', 'success');
                    setDialogResponse(response.data);
                    closeDialog();
                } else {
                    showAlert(response.data.message, 'error');
                }
            }

        } catch (error) {
            showAlert('internal server error', 'error');
        } finally {
            setWaiting(false);
        }
    }
    const validationSchema = yup.object().shape({
        startDate: yup
            .date()
            .test('isTomorrowOrLater', 'Start Date must be tomorrow or later', function (value) {
                const tomorrow = new Date();
                tomorrow.setHours(0, 0, 0, 0);
                tomorrow.setDate(tomorrow.getDate() + 1);
                const startDate = new Date(value);
                return startDate >= tomorrow;
            })
            .required('Start Date is required'),
        frequency: yup.string().required('Frequency is required'),
        numberOfOrders: yup
            .number()
            .min(0, 'Number of orders must be greater than 0'),
    });



    return (
        <>
            <Grid container alignContent="space-between">
                <Grid item xs={12}>
                    <PageHeader heading={"Create Recurring Invoice"}
                        subHeading={` ${orderData.id > 0 && isEditMode ? "" : orderData.orderNumber}`} bodyText={orderData.id > 0 && isEditMode ? "" : orderData.referenceName}
                    >
                        <AppIcon name={"Recurring"} color="primary" />
                    </PageHeader>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Formik
                        initialValues={fieldValues}
                        validationSchema={
                            validationSchema
                        }
                        enableReinitialize={true}
                        onSubmit={async (values) => { await submitFormData(values) }}
                    >
                        {({ values, setValues, setFieldValue, isSubmitting }) => (
                            <>
                                <Form>
                                    <div className={classes.page}>
                                        <Grid item container xs={12} spacing={1}>
                                            <Grid item xs={12}>
                                                <TextInput label="Name" name="name" placeholder="" required
                                                    inputProps={{ autoFocus: true, minLength: 1, maxLength: 100 }} />
                                            </Grid>
                                            <Grid item xs={4} className={classes.mt1}>
                                                <DateInput name="startDate" disabled={safeBool(isEditMode) ? true : false} label="Start date" required compact="true" />
                                            </Grid>
                                            <Grid item xs={8} className={classes.mt1}>
                                                <CheckboxInput
                                                    name="firstOrderDate"
                                                    label="Create order on start date?"
                                                    checked={isCreateOrderOnStartDate}
                                                    onChange={(event) => {
                                                        setIsCreateOrderOnStartDate(event.target.checked);
                                                        if (event.target.checked) {
                                                            setFieldValue('firstOrderDate', values.startDate);
                                                        }
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={12} className={classes.mt1}>
                                                <SelectInput label="Frequency" name="frequency"
                                                >
                                                    {
                                                        frequencies.map((item, index) => {
                                                            return (
                                                                <option key={item.code} value={item.code}>{item.description}</option>
                                                            )
                                                        })
                                                    }
                                                </SelectInput>
                                            </Grid>
                                            <Grid item xs={12} sm={12} className={`${classes.mt1}`}>
                                                <NumberInput
                                                    label="Number Of Orders"
                                                    name="numberOfOrders"
                                                    placeholder="frequency Precision"
                                                /*  onBlur={(e) => {
                                                     const newNumberOfOrders = parseInt(e.target.value, 10);
                                                     if (newNumberOfOrders > 0) {
                                                         const startDate = new Date(values.startDate);
                                                         const currentYear = startDate.getFullYear();
                                                         const currentMonth = startDate.getMonth();
                                                         const newMonth = currentMonth + newNumberOfOrders;
                                                         const newYear = currentYear + Math.floor(newMonth / 12);
                                                         const endMonth = newMonth % 12;
                                                         const endDate = new Date(newYear, endMonth, startDate.getDate());
                                                         setFieldValue('endDate', endDate.toISOString().slice(0, 10));
                                                     } else {
                                                         setFieldValue('endDate', '');
                                                     }
                                                 }} */
                                                />


                                            </Grid>
                                            <Grid item xs={12} sm={12} className={classes.mt1}>
                                                <DateInput name="endDate" label="End date" compact="true" />
                                            </Grid> {/* <span style={{ color: 'red', marginTop: '19px', marginLeft: '25px' }}>OR</span> */}


                                            {/* <Grid item xs={12}>
                                                <NumberInput label="Day" name="frequencyPrecision" placeholder="frequency Precision" required />
                                            </Grid> */}
                                            <Grid item xs={12} style={{ marginTop: '-10px' }}>
                                                <CheckboxInput name="autoWhatsApp" label="&nbsp;Send Automatically on WhatsApp?" checked={values.autoWhatsApp} />
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '-20px' }}>
                                                <CheckboxInput name="autoSMS" label="&nbsp;Send Automatically on SMS?" checked={values.autoSMS} />
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: '-20px' }}>
                                                <CheckboxInput name="autoEmail" label="&nbsp;Send Automatically on Email?" checked={values.autoEmail} />
                                            </Grid>

                                            <Grid item container xs={10} sm={8}>
                                                <Button type="submit" disabled={isSubmitting || waiting}
                                                    variant="contained" color="primary" style={{ cursor: 'pointer' }}>Save</Button>
                                                <Button variant="outlined" color="primary" style={{ cursor: 'pointer' }} onClick={() => closeDialog()} className={classes.ml1}>Cancel</Button>
                                            </Grid>

                                        </Grid>
                                    </div>

                                </Form>
                            </>
                        )}
                    </Formik>
                </Grid>
            </Grid >
        </>
    );
}
