import React from 'react';
import './style.css';
const Waiting = () => {
    return (
        <div>
            <div className="loading-spinner">
            </div>
        </div>
    );
}

export default Waiting;
