import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AppIcon from '../UI/Controls/AppIcons';
import useStyles from '../../styles';
import { safeInt } from './../Base/Utils';
import { useState } from 'react';
import { useHistory, useParams } from "react-router";
import useStore from '../Base/Store';
import WebAPI from '../Base/WebAPI';
import AuthContext from "../Auth/AuthContext";
import { useContext } from 'react';
import shallow from 'zustand/shallow';

export default function AddressCard({ address, openEditHandler, isSelectedOption, confirmDeletePopup, isDelete, referenceId, showEditButton = false, selectedShippingAddressId = 0 }) {
    const classes = useStyles();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { type, id } = useParams();
    const showAlert = useStore(state => state.showAlert);
    const selectedInvoice = useStore(state => state.selectedInvoice);
    const selectedDeliveryChallan = useStore(state => state.selectedDeliveryChallan);
    const selectedQuotation = useStore(state => state.selectedQuotation);
    const selectedSalesOrder = useStore(state => state.selectedSalesOrder);
    const selectedPurchaseOrder = useStore(state => state.selectedPurchaseOrder);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);

    let selectedAddressIdParameter = ''
    switch (type) {
        case 'dc':
            selectedAddressIdParameter = selectedDeliveryChallan.deliveryAddressId
            break;
        case 'quotation':
            selectedAddressIdParameter = selectedQuotation.deliveryAddressId
            break;
        case 'salesorder':
            selectedAddressIdParameter = selectedSalesOrder.deliveryAddressId
            break;
        case 'purchaseorder':
            selectedAddressIdParameter = selectedPurchaseOrder.deliveryAddressId;
            break;
        default:
            selectedAddressIdParameter = selectedInvoice.deliveryAddressId;
            break;
    };

    const [selectedAddressId, setSelectedAddressId] = useState(selectedShippingAddressId > 0 ? selectedShippingAddressId : selectedAddressIdParameter);
    const setAsDeliveryAddress = async (rowData, remove = false) => {
        setSelectedAddressId(remove ? 0 : rowData.id)
        var apiUrl = '';
        var selectedOrderId = 0;
        switch (type) {
            case 'dc':
                apiUrl = '/DeliveryChallan/UpdateDeliveryChallanShippingAddress';
                selectedOrderId = selectedDeliveryChallan.id;
                break;
            case 'quotation':
                apiUrl = '/Quotation/UpdateQuotationShippingAddress';
                selectedOrderId = selectedQuotation.id;
                break;
            case 'salesorder':
                apiUrl = '/SalesOrder/UpdateSalesOrderShippingAddress';
                selectedOrderId = selectedSalesOrder.id;
                break;
            case 'purchaseorder':
                apiUrl = '/PurchaseOrder/UpdatePurchaseOrderShippingAddress';
                selectedOrderId = selectedPurchaseOrder.id;
                break;
            default:
                selectedOrderId = selectedInvoice.id;
                apiUrl = '/Invoice/UpdateInvoiceShippingAddress';
                break;
        };

        let request = {
            "orderId": selectedOrderId,
            "deliveryAddressId": remove ? 0 : rowData.id
        };

        const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
        if (!!response.data.code && response.data.code > 0) {
            showAlert(response.data.message, 'error');
        } else {
            if (!remove) {
                showAlert('Shipping address changed to: ' + rowData.description, 'success');
            } else {
                showAlert('Shipping address removed.', 'success');
            }
            if (safeInt(referenceId) > 0) {
                closeDialog();
                setDialogResponse(remove ? 0 : rowData.id)
            } else {
                history.go(-1);
            }
        }
    }

    return (
        <Card className={classes.root} style={{ height: '100%' }}>
            <CardActionArea className={classes.p1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#f0f8ff' }}>

                <Avatar style={{ backgroundColor: 'white', border: '1px solid steelblue' }}>
                    <ContactPhoneIcon color='secondary' fontSize='12px' />
                </Avatar>
                {
                    isSelectedOption ? (selectedAddressId == address.id) ?
                        <IconButton caption="Select" aria-label="Select address" color="primary" onClick={() => setAsDeliveryAddress(address, true)}><AppIcon name="SelectAddress" /></IconButton>
                        : <IconButton caption="Select" aria-label="Select address" onClick={() => setAsDeliveryAddress(address, false)}><AppIcon name="RadioUnchecked" /></IconButton> : null
                }
            </CardActionArea>
            <Divider />
            <CardContent style={{ height: '61%' }}>
                <List>
                    <ListItem className={classes.pullLeft}>
                        <ListItemText primary={address.description} />
                    </ListItem>
                    <ListItem className={classes.pullLeft}>
                        <ListItemText secondary={address.fullAddress} />
                    </ListItem>
                </List>
                <Typography variant='h'>
                    {address.contactPerson}
                </Typography>
                <Typography variant='h' className={classes.ml2}>
                    {address.contactPhone}
                </Typography>
            </CardContent>
            {
                (safeInt(referenceId) === 0 || showEditButton) &&
                <CardActions className={classes.pullRight}>
                    <Button size="small" color="primary" variant='outlined' onClick={() => openEditHandler(address)}>
                        <AppIcon name={"Edit"} />
                    </Button>
                    {
                        isDelete ? <Button size="small" color='primary' variant="outlined" style={{ color: 'red' }}
                            onClick={() => confirmDeletePopup(address)}>
                            <AppIcon name={"Delete"} />
                        </Button> : null
                    }

                </CardActions>
            }

        </Card>
    );
}