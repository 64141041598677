import { Typography } from "@material-ui/core";
import useStyles from "../../../styles";
import useStore from "../../Base/Store";
import { isNullOrUndefined, safeString, safeInt, getLocaleDateTime } from "../../Base/Utils";

export default function AuditInfo() {
    const auditDetails = useStore(state => state.auditDetails);
    const classes = useStyles();
    return (
        <>
            {
                !isNullOrUndefined(auditDetails) &&
                <div className={`${classes.page} ${classes.mt1}`}>
                    <table className={classes.auditTable}>
                        <tbody>
                            <tr>
                                <td><Typography variant="subtitle1" color="primary">Created by:</Typography></td>
                                <td><Typography variant="subtitle1" color="primary">{safeString(auditDetails.createUserName)}</Typography></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><Typography variant="subtitle1" color="primary">{safeString(auditDetails.createEmailAddress)}</Typography></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><Typography variant="subtitle1" color="primary">{getLocaleDateTime(auditDetails.dateCreated)}</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography variant="subtitle1" color="primary">Updated by:</Typography></td>
                                <td><Typography variant="subtitle1" color="primary">{safeString(auditDetails.updateUserName)}</Typography></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><Typography variant="subtitle1" color="primary">{safeString(auditDetails.updateEmailAddress)}</Typography></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><Typography variant="subtitle1" color="primary">{safeInt(auditDetails.updatedUserId) > 0 ? getLocaleDateTime(auditDetails.dateUpdated) : ""}</Typography></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            }
        </>
    )
};
