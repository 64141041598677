import React from 'react';
import { SwipeableDrawer, makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';


const Puller = styled('div')(({ theme }) => ({
    width: 50,
    height: 4,
    backgroundColor: "whitesmoke",
    borderRadius: 10,
    position: 'absolute',
    top: 18,
    left: 'calc(50% - 15px)',
}));
const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: drawerWidth,
        height: '70vh',
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: 18,
        borderTopRightRadius: 18,
    },
    drawerContent: {
        padding: theme.spacing(2),
        overflowY: 'auto',
        maxHeight: 'calc(70vh - 40px)',
    },
    closeIcon: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

const BottomDrawer = ({ open, onClose, component }) => {
    const classes = useStyles();

    const handleSwipe = (action) => (event) => {
        if (action === 'open' && event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        onClose();
    };

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleSwipe('close')}
            onOpen={handleSwipe('open')}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div style={{ padding: '10px', marginBottom: '15px' }}>
                <Puller />
            </div>
            <div className={classes.drawerContent}>
                {component}
            </div>
        </SwipeableDrawer>
    );
};

export default BottomDrawer;
