import { CUSTOMER_ENTITY, ERROR_UX_UNHANDLED } from "../../Base/Common";
import { getData } from "../../Base/DataProvider";
import { isNullOrUndefined, safeInt } from "../../Base/Utils";


export const getCustomerLiteList = async (authContext, showAlert, token = null) => {
    try {
        const response = await getData(CUSTOMER_ENTITY, "GetCustomerLiteList", authContext, token, authContext.currentUser.subscriberId);
        if (!isNullOrUndefined(response)) {
            if (safeInt(response.code) > 0) {
                showAlert(response.message, 'error');
            }
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }
}

export const getCustomerList = async (authContext, showAlert, token = null) => {
    try {
        const response = await getData(CUSTOMER_ENTITY, "GetCustomerList", authContext, token, authContext.currentUser.subscriberId);
        if (!isNullOrUndefined(response)) {
            if (safeInt(response.code) > 0) {
                showAlert(response.message, 'error');
            }
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }
}

export const getCustomer = async (id, authContext, showAlert, token = null) => {
    try {
        const response = await getData(CUSTOMER_ENTITY, "GetCustomerById", authContext, token, safeInt(id));
        if (!isNullOrUndefined(response)) {
            if (safeInt(response.code) > 0) {
                showAlert(response.message, 'error');
            }
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }
}

export const getCustomerAddress = async (id, authContext, showAlert, token = null) => {
    const customer = await getCustomer(id, authContext, showAlert, token);
    return customer?.addressList;
}