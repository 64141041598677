import { ERROR_UX_UNHANDLED, PRODUCT_ENTITY } from "../../Base/Common";
import { getData } from "../../Base/DataProvider";
import { isNullOrUndefined, safeInt } from "../../Base/Utils";

export const getLastProductPrices = async (productId, orderTypeId, referenceId, authContext) => {
    try {
        const response = await getData("Product", "GetLastProductPrices", authContext, null, "/" + orderTypeId + "/" + referenceId + "/" + productId);
        if (safeInt(response?.code) > 0) {
            return null;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProductList = async (authContext, showAlert, token) => {
    try {
        const response = await getData(PRODUCT_ENTITY, "ProductList", authContext, token, authContext.currentUser.subscriberId);
        if (!isNullOrUndefined(response)) {
            if (safeInt(response.code) > 0) {
                showAlert(response.message, 'error');
            }
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    } finally {
        
    }
}