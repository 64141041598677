
import { CUSTOMER_ENTITY, ERROR_UX_UNHANDLED } from "../../Base/Common";
import { getCustomer, getCustomerList, getCustomerLiteList } from "../Customer/CustomerBusiness";
import { getSupplier, getSupplierList, getSupplierLiteList } from "../Supplier/SupplierBusiness";


export const getPartyLiteList = async (referenceType, authContext, showAlert, token = null) => {
    try {
        let response = null;
        if (referenceType === CUSTOMER_ENTITY) {
            response = getCustomerLiteList(authContext, showAlert, token);
        } else {
            response = getSupplierLiteList(authContext, showAlert, token);
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }

}

export const getPartyList = async (referenceType, authContext, showAlert, token = null) => {
    try {
        let response = null;
        if (referenceType === CUSTOMER_ENTITY) {
            response = getCustomerList(authContext, showAlert, token);
        } else {
            response = getSupplierList(authContext, showAlert, token);
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }

}

export const getParty = async (referenceType, id, authContext, showAlert, token = null) => {
    try {
        let response = null;
        if (referenceType === CUSTOMER_ENTITY) {
            response = getCustomer(id, authContext, showAlert, token);
        } else {
            response = getSupplier(id, authContext, showAlert, token);
        }
        return response;
    } catch (error) {
        console.log(error);
        showAlert(ERROR_UX_UNHANDLED, 'error');
    }

}

export const getPartyAddress = async (referenceType, id, authContext, showAlert, token = null) => {
    const party = await getParty(referenceType, id, authContext, showAlert, token);
    return party?.addressList;
}