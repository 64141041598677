import React, { useContext } from 'react';
import AppIcon from '../../UI/Controls/AppIcons';
import { formatCurrencyAmount, isNullOrEmpty, safeString } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';
import { Paper, useMediaQuery, useTheme } from '@material-ui/core';

const JournalEntryTable = ({ transactionIdentifier, fromEntries, toEntries, handleDeleteAction }) => {
    const authContext = useContext(AuthContext);
    const currencyCode = authContext?.currentSubscriber?.currencyCode || '';
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const formatAccountNumber = (entry) => {
        const { referenceType, accountNumber, referenceName, bankName } = entry;
        if (referenceType === "Customer" || referenceType === "Supplier") {
            return referenceName;
        } else {
            if (accountNumber === "1010") {
                return `${accountNumber} ${bankName}`;
            } else {
                return `${accountNumber} ${referenceName}`;
            }
        }
    };

    const getTotalAmount = (entries) => {
        return entries?.reduce((sum, entry) => sum + entry.amount, 0);
    };

    return (
        <>
            <Paper elevation={3} style={{ padding: '16px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <div style={{ marginTop: '16px', display: 'flex', flexDirection: mobileDevice ? 'column' : 'row', overflowX: 'auto', }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', border: "1px solid #ddd", borderBottom: mobileDevice ? "1px solid #ddd" : "none", marginRight: '24px' }}>
                        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={5} style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Debit</th>
                                    </tr>
                                    <tr style={{ background: "rgb(148 200 251)" }}>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>SI.</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>A/C No</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Narrative</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Amount</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fromEntries?.map((fromEntry, fromIndex) => (
                                        <tr key={`fromEntry-${fromIndex}`}>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{fromIndex + 1}</td>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{formatAccountNumber(fromEntry)}</td>
                                            <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd' }}>{fromEntry?.narrative}</td>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}> {fromEntry?.amount}</td>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>
                                                <span
                                                    onClick={() => {
                                                        if (isNullOrEmpty(transactionIdentifier)) {
                                                            handleDeleteAction(fromIndex, "Debit");
                                                        }
                                                    }}
                                                    style={{
                                                        color: !isNullOrEmpty(transactionIdentifier) ? "gray" : "red",
                                                        cursor: !isNullOrEmpty(transactionIdentifier) ? "not-allowed" : "pointer"
                                                    }}
                                                >
                                                    <AppIcon name="Delete" size="small" />
                                                </span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* From Entries Total */}
                        <div style={{ marginTop: 'auto' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3" style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>Total</td>
                                        <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>
                                            {formatCurrencyAmount(currencyCode, getTotalAmount(fromEntries), "green", true)}
                                        </td>
                                        <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    {/* Credit Table */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', border: "1px solid #ddd" }}>
                        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={5} style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Credit</th>
                                    </tr>
                                    <tr style={{ background: "rgb(148 200 251)" }}>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>SI.</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>A/C No</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Narrative</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Amount</th>
                                        <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {toEntries?.map((toEntry, toIndex) => (
                                        <tr key={`toEntry-${toIndex}`}>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{toIndex + 1}</td>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{formatAccountNumber(toEntry)}</td>
                                            <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd' }}>{toEntry?.narrative}</td>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}> {toEntry?.amount}</td>
                                            <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>
                                                <span
                                                    onClick={() => {
                                                        if (isNullOrEmpty(transactionIdentifier)) {
                                                            handleDeleteAction(toIndex, "Credit");
                                                        }
                                                    }}
                                                    style={{
                                                        color: !isNullOrEmpty(transactionIdentifier) ? "gray" : "red",
                                                        cursor: !isNullOrEmpty(transactionIdentifier) ? "not-allowed" : "pointer"
                                                    }}
                                                >
                                                    <AppIcon name="Delete" size="small" />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* To Entries Total */}
                        <div style={{ marginTop: 'auto' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <tfoot>
                                    <tr>
                                        <td colSpan="3" style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>Total</td>
                                        <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>
                                            {formatCurrencyAmount(currencyCode, getTotalAmount(toEntries), "green", true)}
                                        </td>
                                        <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </Paper>
        </>
    );
};

export default JournalEntryTable;
