import { useParams } from "react-router-dom";
import { CUSTOMER_ENTITY, DELIVERY_CHALLAN_ENTITY, ORDER_TYPE_DELIVERY_CHALLAN } from "../../../Base/Common.js";
import OrderSummary from "./OrderSummary.js";

export default function DeliverychallansSummary() {
    var { from, id } = useParams();
    return (
        <OrderSummary toOrderType={DELIVERY_CHALLAN_ENTITY} fromOrderType={from} orderId={id} referenceType={CUSTOMER_ENTITY} heading={"Delivery Challan Summary"} orderType={ORDER_TYPE_DELIVERY_CHALLAN} icon={"DeliveryChallan"} />
    )
};
