import { Button, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import useStyles from "../../../styles";
import AuthContext from "../../Auth/AuthContext";
import useStore from "../../Base/Store";
import { isNullOrEmpty, isNullOrUndefined, safeString } from "../../Base/Utils";
import { TextInput } from "../../UI/Controls/InputFields";

export default function UOMSelect({ code, setPopover, isPopover, baseUnitOfMeasurement, conversionFactor }) {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [fieldValues, setFieldValues] = useState({
        unitOfMeasurement: code
    });
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [selectedUOM, setSelectedUOM] = useState(null);
    const [uomData, setUOMData] = useState([]);

    const submitFormData = (values) => {
        setDialogResponse(selectedUOM);
        if (!isPopover) {
            closeDialog();
        } else {
            setPopover(selectedUOM);
        }

    }

    useEffect(() => {
        if (!isNullOrEmpty(baseUnitOfMeasurement)) {
            const unitConversions = authContext.unitConversionList.filter(u => u.code === baseUnitOfMeasurement || u.primaryUOM === baseUnitOfMeasurement);
            setUOMData(unitConversions);
            setSelectedUOM(unitConversions.find(u => u.secondaryUOM === code && u.conversionFactor === conversionFactor && u.primaryUOM === baseUnitOfMeasurement));
        } else {
            const unitConversions = authContext.unitConversionList.filter(u => u.code === code || u.primaryUOM === code);
            setUOMData(unitConversions);
            setSelectedUOM(unitConversions.find(u => u.code === code));
        }
    }, []);

    return (
        <>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                onSubmit={
                    async (values) => {
                        await submitFormData(values);
                    }
                }
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        {/* <PageHeader heading="Select unit of measurement" />                         */}
                        <div className={classes.page}>
                            <Grid container spacing={2} alignContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        id="unitOfMeasurement"
                                        name="unitOfMeasurement"
                                        options={uomData}
                                        getOptionLabel={option => {
                                            if (!isNullOrUndefined(option)) {
                                                return safeString(option.description) + ' - ' + safeString(option.uqc)
                                            } else {
                                                return "";
                                            }
                                        }}
                                        value={selectedUOM}
                                        onChange={(e, uom) => {
                                            if (!isNullOrUndefined(uom)) {
                                                setFieldValue('unitOfMeasurement', uom.code);
                                                setSelectedUOM(uom);
                                            }
                                        }}
                                        renderInput={(props) =>
                                            <TextInput fullWidth name="unitOfMeasurement"  {...props} label="Unit of measurement" variant="outlined" required />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">OK</Button>
                                    <Button type="button" variant="outlined" color="primary" className={classes.ml1}
                                        onClick={() => !isPopover ? closeDialog() : setPopover()}>Cancel</Button>

                                </Grid>
                            </Grid>

                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );

};
