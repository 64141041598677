import { List, Popover } from "@material-ui/core";


export default function ContextMenu({ key, element, handleClose = () => { }, menuItems }) {
    return (
        <Popover
            id={key}
            open={Boolean(element)}
            anchorEl={element}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List>
                {menuItems}
            </List>

        </Popover>


    );
};
