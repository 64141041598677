import { PROJECT_ENTITY, REPORT_ENTITY } from "../../Base/Common";
import { getData, getDataList } from "../../Base/DataProvider";
import { safeInt } from "../../Base/Utils";

export const getProjectList = async (authContext, token = null) => {
    try {
        const response = await getData(PROJECT_ENTITY, "GetProjectList", authContext, token, authContext.currentUser.subscriberId);
        if (safeInt(response?.code) > 0) {
            return null;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProjectWiseTransactionList = async (filter, authContext, token = null) => {
    try {
        const response = await getDataList(REPORT_ENTITY, "GetProjectWiseTransactionsList", filter, authContext, token, 'put');
        if (safeInt(response?.code) > 0) {
            return null;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getProject = async (id, authContext, token = null) => {
    try {
        const response = await getData(PROJECT_ENTITY, "GetProjectById", authContext, token, id);
        if (safeInt(response?.code) > 0) {
            return null;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}