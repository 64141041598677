import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { AdaptiveButton, LabelText, LockIcon, PageHeader, PartyDescription } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import { CheckboxInput, CurrencyInput, SelectInput, TextInput } from '../../UI/Controls/InputFields';
import { Form, Formik } from 'formik';
import useStyles from '../../../styles';
import { useState } from 'react';
import { Alert, Autocomplete } from '@material-ui/lab';
import { filterOptions, getTodaysDate, isEmptyObjectArray, isNullOrUndefined, safeAmount, safeFloat, safeInt, safeString, wrapTextInBrackets } from '../../Base/Utils';
import { useEffect } from 'react';
import { APP_MODULE_Receivables, CUSTOMER_ENTITY, PAYMENT_LINK_ENTITY } from '../../Base/Common';
import AuthContext from '../../Auth/AuthContext';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { getBankList } from '../../Shared/OrderHelper';
import WebAPI from '../../Base/WebAPI';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import QRCode from 'qrcode.react';
import upiImage from '../../../images/UPI.jpeg';
import { getEntityList } from '../../Base/DataProvider';

const NewPaymentLink = ({ orderData, isCustomerPayment = false }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const moduleAccess = authContext.moduleAccess(APP_MODULE_Receivables);
    const [paymentMode, setPaymentMode] = useState("dueAmount");
    const [bankList, setBankList] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const showAlert = useStore(state => state.showAlert);
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [responseData, setResponseData] = useState([]);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const [fieldValues, setFieldValues] = useState({
        "id": 0,
        "referenceId": isCustomerPayment ? 0 : orderData?.referenceId,
        "referenceName": isCustomerPayment ? "" : orderData?.referenceName,
        "bankId": 0,
        "bankName": '',
        'specificAmount': 0,
        "amount": safeAmount(orderData?.amountDue, 2),
        "upiId": "",
        "accountName": "",
        "accountNumber": "",
        "notes": ""
    });


    const handlePaymentModeChange = (e, values, setFieldValue) => {
        setPaymentMode(e.currentTarget.value);
        if (e.currentTarget.value === "dueAmount") {
            setFieldValue('amount', values?.amountDue)
        } else if (e.currentTarget.value === "specificAmount1") {
            setFieldValue('amount', values?.specificAmount)
        } else {
            setFieldValue('amount', 0)
        }
    }

    const actionButton = (
        <>

            <Button variant='outlined' onClick={() => {
                navigator.clipboard.writeText(responseData?.messageLink);
                showAlert('Payment Link copied!');
            }} color='secondary' size='small' style={{ color: 'darkgreen' }} startIcon={<AppIcon name="Copy" title="Click to copy payment link" />}>
                Copy
            </Button>

        </>
    );

    const whatsAppPayment = () => {
        setDialogResponse({
            referenceId: responseData?.referenceId,
            messageText: responseData?.messageText
        });
        closeDialog();
    }

    const fetchData = async (token) => {
        try {
            if (isCustomerPayment) {
                var partyList = await getEntityList(CUSTOMER_ENTITY, authContext, token, true);
                if (!!partyList.code && partyList.code > 0) {
                    showAlert(partyList.message, 'error');
                } else {
                    setParties(partyList);
                }
            }
            setWaiting(true);
            var bankListResponse = await getBankList(authContext);
            setBankList(bankListResponse);
            const defaultBank = bankListResponse.find((bank) => bank.isPrimary);
            setSelectedBank(defaultBank);

        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Request cancelled');
            } else {
                showAlert('Failed to load bank details. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const submitFormData = async (values) => {
        try {
            setWaiting(true);

            const request = {
                "id": 0,
                "referenceId": safeInt(values?.referenceId),
                "referenceType": CUSTOMER_ENTITY,
                "referenceName": safeString(values?.referenceName),
                "amount": safeAmount(values?.amount),
                "bankId": safeInt(selectedBank?.id),
                "paymentDate": getTodaysDate(),
                "expiryDate": getTodaysDate(),
                "bankName": safeString(selectedBank?.bankName),
                "upiId": safeString(selectedBank?.upiId),
                "accountName": safeString(selectedBank?.accountName),
                "accountNumber": safeString(selectedBank?.accountNumber),
                "notes": safeString(values.notes),
                "active": true
            }
            const response = await WebAPI.put('/Payments/CreatePaymentLink', request, { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                setResponseData(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        if (!moduleAccess.allowAdd || !moduleAccess.allowView) {
            history.push("/notauthorised");
        } else {
            let source = axios.CancelToken.source();
            async function init() {
                if (authContext.token != null) {
                    await fetchData(source.token);
                }
            }
            init();
        }
    }, []);

    return (
        <>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                onSubmit={
                    async (values, { resetForm }) => {
                        await submitFormData(values);
                    }
                }
            >
                {({ values, setValues, setFieldValue, isSubmitting }) => (
                    <>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <PageHeader heading={"Payment Link"} subHeading="Collect payments instantly using UPI">
                                    <AppIcon name={"Link"} color="primary" />
                                </PageHeader>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography color="primary" variant="body1" className={classes.pullRight}>
                                    {values?.referenceName}
                                </Typography>
                                <Typography color="error" variant="body" className={classes.pullRight}>
                                    {`Due Amount : ${safeAmount(values?.amountDue).toFixed(2)}`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Form>
                                    <div className={classes.page}>

                                        {
                                            isCustomerPayment &&
                                            <>
                                                {/*  <SelectInput searchable={true} label="Customer" /> */}
                                                <Grid container className={`${classes.pullCenter}`}>
                                                    <Grid item xs={12} sm={10} className={classes.pullCenter}>
                                                        <SelectInput
                                                            searchable={true}
                                                            id="referenceId"
                                                            name="referenceId"
                                                            options={parties}
                                                            getOptionLabel={(option) => {
                                                                // e.g value selected with enter, right from the input
                                                                if (typeof option === 'string') {
                                                                    return option;
                                                                }
                                                                if (option.inputValue) {
                                                                    return option.inputValue;
                                                                }
                                                                return option.legalName + wrapTextInBrackets(option.shortName);
                                                            }}
                                                            renderOption={
                                                                (option) => <PartyDescription party={option} />
                                                            }
                                                            value={selectedParty}
                                                            onChange={(event, newValue) => {
                                                                if (!isNullOrUndefined(newValue)) {
                                                                    setSelectedParty(newValue);
                                                                    setFieldValue('referenceId', safeAmount(newValue?.id));
                                                                    setFieldValue('referenceName', safeString(newValue?.legalName));
                                                                    setFieldValue('amountDue', safeAmount(newValue?.outstandingAmount));
                                                                    setFieldValue('amount', safeAmount(newValue?.outstandingAmount));
                                                                } else {
                                                                    setSelectedParty(null);
                                                                    setFieldValue('referenceId', 0);
                                                                    setFieldValue('referenceName', '');
                                                                    setFieldValue('amountDue', 0);
                                                                    setFieldValue('amount', 0);
                                                                }
                                                            }}
                                                            renderInput={(props) =>
                                                                <TextInput fullWidth name="referenceName"  {...props} label="Select a Customer" variant="outlined" required />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }


                                        <Grid item container xs={12} spacing={1} className={classes.pullCenter}>
                                            <RadioGroup name="paymentMode" value={paymentMode} onChange={(e) => handlePaymentModeChange(e, values, setFieldValue)} row>
                                                <FormControlLabel value="dueAmount" control={<Radio size="small" />} label="Due Amount" />
                                                <FormControlLabel value="anyAmount" control={<Radio size="small" />} label="Any Amount" />
                                                <FormControlLabel value="specificAmount1" control={<Radio size="small" />} label="Specific Amount" />
                                            </RadioGroup>
                                        </Grid>
                                        {
                                            paymentMode === "specificAmount1" ?
                                                <Grid container className={`${classes.pullCenter}`}>
                                                    <Grid item xs={12} sm={10} className={classes.mt1}>
                                                        <CurrencyInput label="Amount" name="specificAmount" placeholder="" required currencyCode={currencyCode}
                                                            inputProps={{}} onBlur={(e) => {
                                                                if (!isNullOrUndefined(e.target.value) && !isNaN(e.target.value)) {
                                                                    setFieldValue('specificAmount', safeAmount(e.target.value))
                                                                    setFieldValue('amount', safeAmount(e.target.value))
                                                                } else {
                                                                    setFieldValue('specificAmount', "")
                                                                }
                                                            }} />
                                                    </Grid></Grid> : null
                                        }
                                        <Grid container className={`${classes.pullCenter}`}>
                                            <Grid item xs={12} sm={10} className={classes.mt1}>
                                                <Autocomplete
                                                    filterOptions={filterOptions}
                                                    size="small"
                                                    name="bankId"
                                                    options={bankList}
                                                    getOptionLabel={(option) => !!option ? option.bankName : ''}
                                                    value={selectedBank}
                                                    onChange={(e, value) => {
                                                        if (!isNullOrUndefined(value)) {
                                                            setSelectedBank(value);
                                                            setFieldValue('bankId', value.id);
                                                            setFieldValue('bankName', value.bankName);
                                                            setFieldValue('upiId', value.upiId);
                                                            setFieldValue('accountName', value.accountName);
                                                            setFieldValue('accountNumber', value.accountNumber);
                                                        } else {
                                                            setSelectedBank(null);
                                                            setFieldValue('bankId', 0);
                                                            setFieldValue('bankName', '');
                                                            setFieldValue('upiId', '');
                                                            setFieldValue('accountName', '');
                                                            setFieldValue('accountNumber', '')
                                                        }
                                                    }}
                                                    renderInput={(props) =>
                                                        <TextInput {...props} name="bankName" label="Bank" variant="outlined" />
                                                    }
                                                >
                                                </Autocomplete>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={`${classes.pullCenter}`}>
                                            <Grid item xs={12} sm={10} className={classes.mt1}>
                                                <TextInput label="Payment Notes" name="notes" placeholder="" multiline minRows={1} inputProps={{ className: classes.textarea, maxLength: 2000 }} />
                                            </Grid>
                                        </Grid>
                                        {
                                            !isNullOrUndefined(selectedBank) ?
                                                <>
                                                    <Grid container className={`${classes.pullCenter}`} style={{ marginTop: '20px' }}>
                                                        <Typography variant='h5' style={{ color: "green", fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                                                            &#8377; {safeFloat(values.amount)}
                                                        </Typography>
                                                    </Grid>
                                                    {
                                                        selectedBank?.upiId ? (
                                                            <>
                                                                <Grid container>
                                                                    <Grid item xs={12} className={`${classes.mt2} ${classes.mb2} ${classes.pullCenter}`}>
                                                                        <QRCode
                                                                            value={`upi://pay?pa=${selectedBank?.upiId}&pn=${selectedBank.accountName}&tn=cftrhwetaw4gta&am=${values.amount}`}
                                                                            size="80"
                                                                            logoWidth="60"
                                                                            logoHeight="60"
                                                                            logoOpacity="0.6"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container className={classes.pullCenter}>
                                                                    <LabelText label={"UPI ID"} text={selectedBank?.upiId} />
                                                                </Grid>
                                                            </>
                                                        ) :
                                                            <Grid container className={`${classes.pullCenter} ${classes.mb2}`}>
                                                                <Grid item xs="12" sm="12" className={`${classes.pullCenter} ${classes.mb2}`}>
                                                                    <Typography color="error" align='center' style={{ display: 'block' }} >No UPI found for the selected bank account.</Typography>
                                                                </Grid>
                                                                <AdaptiveButton
                                                                    caption="Add UPI ID" iconbutton={<img width="40" height="25" src={upiImage} alt="upi" />} onClick={(e) => {
                                                                        history.push(`/bankaccount/${selectedBank?.id}`);
                                                                        closeDialog();
                                                                    }} />
                                                            </Grid>
                                                    }
                                                    {

                                                        !isEmptyObjectArray(responseData) ? <>
                                                            <Grid item container xs={12} sm={12} style={{ marginTop: "10px" }} className={`${classes.mt2} ${classes.pullLeft}`}>
                                                                <Grid item xs={12} sm={12} className={classes.pullCenter}>
                                                                    <Alert severity="info" action={actionButton}>
                                                                        <a href={responseData?.messageLink} target="_blank" className={classes.ellipsis} rel="noreferrer">
                                                                            {responseData?.messageLink}
                                                                        </a>
                                                                    </Alert>
                                                                </Grid>
                                                            </Grid>
                                                        </> : null
                                                    }

                                                    <Grid container xs={12} style={{ marginTop: "10px" }} className={`${classes.mt2} ${classes.pullLeft}`}>
                                                        <Grid item xs={12} sm={5}>
                                                            <Button startIcon={<AppIcon name="Link" size="small" />} type="submit" disabled={isSubmitting || waiting || (values?.referenceId === 0)}
                                                                fullWidth={mobileDevice ? true : false} variant="contained" color="primary" style={{ cursor: 'pointer', marginTop: "10px" }}>Create Payment Link</Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <Button
                                                                style={{ cursor: 'pointer', marginTop: "10px", }}
                                                                type="button"
                                                                fullWidth={mobileDevice ? true : false}
                                                                disabled={!isEmptyObjectArray(responseData) ? false : true}
                                                                variant="contained" color="primary"
                                                                className={`${!isEmptyObjectArray(responseData) ? classes.successButton : true}`}
                                                                title="WhatsApp Payment Link"
                                                                startIcon={<AppIcon name="WhatsappIcon" size="small" />}
                                                                onClick={() => whatsAppPayment()}
                                                            >
                                                                Send On WhatsApp
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={mobileDevice ? 12 : 0}>
                                                            <Button variant="outlined" fullWidth={mobileDevice ? true : false} color="primary" style={{ cursor: 'pointer', marginTop: "10px" }} onClick={() => closeDialog()}>Cancel</Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                : null
                                        }

                                    </div>

                                </Form>
                            </Grid>

                        </Grid>
                    </>
                )}

            </Formik >

        </>
    );
}

export default NewPaymentLink;
