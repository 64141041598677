import { Drawer, IconButton, Tooltip, Typography } from "@material-ui/core";
import AppIcon from "./AppIcons";
import * as Utility from '../../Base/Utils';
import useStyles from "../../../styles"

export default function QuickInfoDrawer({ isInfoDrawerOpen, toggleDrawerOpen, content, title, anchor = "right", isFullWidth = false }) {
    const classes = useStyles();
    return (
        <>
            <Drawer
                anchor={anchor}
                open={isInfoDrawerOpen}
                onClose={toggleDrawerOpen}
            >
                {/*   <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.navToolBar}>
                        <IconButton
                            color="inherit"
                            aria-label="open side drawer"
                            edge="start"
                        >
                        </IconButton>
                        <ButtonBase title="Click to go to home screen." >
                            <h5>Setting</h5>
                        </ButtonBase>
                    </Toolbar>
                </AppBar> */}
                <div className={isFullWidth ? classes.infoDrawerTopToolBarFullWidth : classes.infoDrawerTopToolBar} style={{ cursor: 'pointer' }}>
                    <Tooltip title="Close">
                        <IconButton onClick={toggleDrawerOpen} className={classes.noSpacing}>
                            <AppIcon name="Clear" style={{ cursor: 'pointer', margin: '5px' }} color="error" size="large" />
                        </IconButton>
                    </Tooltip>
                    <div className={classes.infoDrawerTitle}>
                        <Typography variant="h6">{Utility.isNullOrUndefined(title) ? 'Quick Info' : title}</Typography>
                    </div>
                </div>

                {content}
            </Drawer>
        </>
    );
};
