import { useState, useContext, useEffect } from "react"
import { isNullOrUndefined, safeInt, safeString } from "../../Base/Utils"
import { Form, Formik } from "formik";
import { getParty, sendSMS } from "../OrderHelper";
import * as yup from 'yup';
import { PageHeader } from "../../UI/Controls/Controls";
import AppIcon from "../../UI/Controls/AppIcons";
import useStyles from "../../../styles";
import { Button, Grid } from "@material-ui/core";
import { PhoneInput } from "../../UI/Controls/InputFields";
import useStore from "../../Base/Store";
import AuthContext from '../../Auth/AuthContext';
import shallow from "zustand/shallow";

export default function SMSSend({ orderType, referenceType, selectedOrder, selectedParty }) {
    const [fieldValues, setFieldValues] = useState(
        {
            phone: ""
        }
    );
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const showAlert = useStore(state => state.showAlert);
    const closeDialog = useStore(state => state.closeDialog);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    const pageInfo = {
        pageHeading: 'Send SMS',
        pageIcon: 'SMS',
        okButtonCaption: 'Send SMS',
        cancelButtonCaption: 'Cancel',
        entityName: ""
    }

    useEffect(() => {
        async function init() {
            try {
                setWaiting(true);
                const party = await getParty(safeInt(selectedParty.id), referenceType, authContext);
                let tempValues = { ...fieldValues };
                if (!isNullOrUndefined(party)) {
                    if (!isNullOrUndefined(party.addressList) && party.addressList.length > 0) {
                        const address = party.addressList.find(a => a.addressType === 'Billing')
                        tempValues.phone = safeString(address.contactPhone);
                    }
                }
                setFieldValues(tempValues);
            } catch (error) {
                console.log(error);
            } finally {
                setWaiting(false);
            }

        }
        init();
    }, []);

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({

            })}
            onSubmit={
                async (values) => {
                    await sendSMS(values.phone, orderType, referenceType, selectedOrder, selectedParty, showAlert, authContext);
                    closeDialog();
                }
            }
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <PageHeader heading={pageInfo.pageHeading}>
                        <AppIcon name={pageInfo.pageIcon} color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PhoneInput label="Mobile number" name="phone" placeholder="" required
                                    inputProps={{ maxLength: 10, autoFocus: true }} />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item container xs={12} className={`${classes.mt2}`} alignItems="center">
                                <Button type="submit" disabled={isSubmitting || waiting} variant="contained" color="secondary"
                                    startIcon={<AppIcon name="SMS" size="small" />}>
                                    {pageInfo.okButtonCaption}
                                </Button>
                                <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => { closeDialog() }}>
                                    {pageInfo.cancelButtonCaption}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    )
};
