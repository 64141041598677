import SelectParty from './SelectParty';
import { CUSTOMER_ENTITY } from '../../../Base/Common';
export default function SelectCustomer({ customers, selectedCustomer, handleSelectionChanged, isRequired = true, boldInputText = false }) {
    return (
        <SelectParty
            parties={customers}
            selectedParty={selectedCustomer}
            handleSelectionChanged={handleSelectionChanged}
            referenceType={CUSTOMER_ENTITY}
            isRequired={isRequired}
            boldInputText={boldInputText}
        />
    )
};
