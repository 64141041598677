import { Grid, Button, Typography, useTheme, useMediaQuery, Backdrop, CircularProgress, ListItemText, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, IconButton, Checkbox, ButtonBase, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import useStyles from '../../../styles';
import MuiAlert from '@material-ui/lab/Alert';
import { DateInput } from "../../UI/Controls/InputFields";
import AppIcon from '../../UI/Controls/AppIcons';
import ReactSignatureCanvas from 'react-signature-canvas';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useRef, useState } from 'react';

import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import { formatToCurency, getAmountIncludingTax, isEmptyObjectArray, isNullOrEmpty, isNullOrUndefined, safeBool, safeFloat, safeString } from '../../Base/Utils';
import { INVOICE_ENTITY } from '../../Base/Common';
import { useField } from 'formik';
import Waiting from '../Background/Waiting/Waiting';

export function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function PageHeader(props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <div className={classes.formHeader}>
            {props.children}
            <div className={classes.iconLabel}>
                <Typography className={classes.ml1} variant="subtitle1" color="secondary" style={{ fontWeight: 'bold' }}>{props.heading}</Typography>
                <Typography className={classes.ml1} variant="subtitle2" style={{ color: theme.palette.success.main }}>{props.subHeading}</Typography>
                <Typography className={classes.ml1} style={{ color: theme.palette.error.main }} variant="body2">{props.bodyText}</Typography>
                <Typography className={classes.ml1} style={{ color: theme.palette.secondary.main }} variant="body2">{props.bodyText2}</Typography>
            </div>
        </div>
    )
};

export function AdaptiveButton(props) {
    const classes = useStyles();
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const buttonVariant = props.textvariant === "true" ? 'text' : 'contained';

    const buttonSize = !!props.buttonsize ? props.buttonsize : 'small';
    return (
        <Button {...props} className={classes.adaptiveButton} color="secondary" variant={!mobileDevice ? buttonVariant : 'text'}
            size={!mobileDevice ? buttonSize : 'large'}
            startIcon={props.iconbutton} title={isNullOrUndefined(props.title) ? props.caption : safeString(props.title)}
        >  {!mobileDevice ? props.caption : props.captionsmall}
        </Button>
    )
}

export function AdaptiveGroupButton(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Button {...props}>
            {matches ? props.caption : ''}
        </Button>
    )
}

export function LoadingBackdrop(props) {
    const classes = useStyles();
    return (
        <>
            <Backdrop {...props} className={classes.loadingBackdrop}>
                <Waiting color="inherit" />
            </Backdrop>

        </>

    )
}

export function DateFilter({ isSubmitting }) {
    const classes = useStyles();
    return (
        <Grid container className={classes.pt1} spacing={1} alignItems="center">
            <Grid item xs={6} sm={2}>
                <DateInput name="fromDate" label="From date" required compact="true" />
            </Grid>
            <Grid item xs={6} sm={2}>
                <DateInput name="toDate" label="To date" required compact="true" />
            </Grid>
            <Grid item xs={12} sm={2}>
                {/* <AdaptiveButton variant="outlined" type="submit" buttonsize="medium"                
                    caption="Filter" captionsmall="Filter" disabled={isSubmitting}
                    iconbutton={<AppIcon name="Filter" />} /> */}
                <FilterButton open={true} handleClick={() => { }} anchorRef={null} handleToggle={() => { }} />
            </Grid>
        </Grid>
    )
}

export function ContextMenuItem({ iconname, label = 'Click action', labelcolor = 'info', iconColor = "primary" }) {
    const classes = useStyles();

    return (
        <>
            <AppIcon name={iconname} color={iconColor} />
            <ListItemText className={classes.ml1}>
                <Typography variant='subtitle1' color={labelcolor}>{label}</Typography>
            </ListItemText>
        </>

    )
}

export function ContextOrderMenuItem({ iconname, label = 'Click action', labelcolor = 'info', iconColor = "primary", iconSize = "20px" }) {
    const classes = useStyles();

    return (
        <>
            <ListItem style={{ cursor: 'pointer' }} className={classes.contextItemHover}>
                <ListItemText primary={label} style={{ marginRight: '21px', padding: '0px' }} />
                <ListItemSecondaryAction>
                    <ButtonBase>
                        <AppIcon name={iconname} color={iconColor} style={{ color: iconColor, fontSize: iconSize }} />
                    </ButtonBase>
                </ListItemSecondaryAction>
            </ListItem>
        </>

    )
}

export function ContextOrderMobileMenuItem({ iconname, label = 'Click action', labelcolor = 'info', iconColor = "primary", iconSize = "20px" }) {
    const classes = useStyles();

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '8px 0px', margin: '4px'}} className={classes.contextItemHover}>
                <Typography variant='button'>{label}</Typography>
                <span style={{ color: iconColor, fontSize: iconSize }}>
                    <AppIcon name={iconname} color={iconColor} style={{ color: iconColor, fontSize: iconSize }} />
                </span>
            </div>
        </>

    )
}


export function LockIcon() {
    return (
        <AppIcon name="Lock" style={{ fontSize: '14px' }} color="error" />
    )
}

export function SignaturePanel() {
    const classes = useStyles();
    return (
        <ReactSignatureCanvas className={classes.signaturePanel} penColor='blue' canvasProps={{ width: 500, height: 200, className: 'signaturePanel' }} />
    )
}

export function FilterButton({ hasMoreAction = false }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenuItemClick = () => {
        openDialog("advancedOrderFilter");
    }
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <>
            <ButtonGroup fullWidth={mobileDevice ? true : false} variant="outlined" color="primary" ref={anchorRef} aria-label="filter button">
                <Button type="submit" startIcon={<AppIcon name="Filter" />}>Filter</Button>
                {
                    hasMoreAction &&

                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="Advanced filter"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <KeyboardArrowDownIcon />
                    </Button>

                }
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem
                                        key={0}
                                        onClick={(event) => handleMenuItemClick(event)}
                                    >
                                        Advanced filter
                                    </MenuItem>

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>

    )
}

export const ProductDescription = ({ product, taxInclusivePricing = false, entityType = INVOICE_ENTITY }) => {
    if (!isNullOrEmpty(product)) {
        /* let displayPrice = isSalesVoucher(entityType) ? safeFloat(product.sellPrice) : safeFloat(product.buyPrice); */
        let sellPriceDisplay = safeFloat(product.sellPrice);
        let buyPriceDisplay = safeFloat(product.buyPrice);
        if (taxInclusivePricing) {
            sellPriceDisplay = getAmountIncludingTax(sellPriceDisplay, product.taxRate);
            buyPriceDisplay = getAmountIncludingTax(buyPriceDisplay, product.taxRate);

        }
        return (
            safeBool(product?.addNew) ?
                <>
                    <ButtonBase
                        style={
                            {
                                color: '#ffffff', width: '100%', padding: '8px', margin: '0px', border: '1px solid #1976D2', borderRadius: '5px', backgroundColor: '#1976D2'
                            }
                        }>
                        {safeString(product.description)}
                    </ButtonBase>
                </> :
                <>
                    <span {...product} style={{ fontSize: '12px', borderBottom: '1px dotted lightsteelblue', paddingBottom: '1px', width: "100%" }}>
                        {
                            safeString(product.description)
                        }
                        {
                            !isNullOrEmpty(safeString(product.productCode)) &&
                            <span style={{ fontWeight: 'bold' }}>{" | " + safeString(product.productCode)}</span>
                        }
                        {
                            !isNullOrEmpty(safeString(product.barCode)) &&
                            <span style={{ fontWeight: 'bold' }}>{" | " + safeString(product.barCode)}</span>
                        }
                        <br />
                        <span style={{ fontSize: '11px', backgroundColor: '#f7f7f7', padding: '4px', borderRadius: '15px' }}>
                            {
                                safeFloat(product.maximumRetailPrice) > 0 &&
                                <><LabelText label="MRP" text={formatToCurency(product.maximumRetailPrice)} textColor='navy' />&nbsp;|&nbsp;</>
                            }
                            <LabelText label="Buy" text={formatToCurency(buyPriceDisplay)} textColor='darkblue' /> | &nbsp;
                            <LabelText label="Sell" text={formatToCurency(sellPriceDisplay)} textColor='darkblue' />
                            {
                                product.productType === 'Goods' &&
                                <>
                                    {
                                        safeFloat(product.stockInHandQty) > 0 &&
                                        <>&nbsp;|&nbsp;<LabelText label="Stock" text={safeFloat(product.stockInHandQty) + " " + safeString(product.unitOfMeasurement)} textColor='darkgreen' /></>
                                    }

                                    {
                                        safeFloat(product.stockInHandQty) <= 0 &&
                                        <>&nbsp;|&nbsp;<LabelText label="Stock" text={safeFloat(product.stockInHandQty) + " " + safeString(product.unitOfMeasurement)} textColor='darkred' /></>
                                    }
                                </>
                            }

                        </span>
                    </span>
                </>
        );
    } else {
        return <span>Error!</span>
    }
}

export const LabelText = ({ label, text, boldValue = true, textColor = 'black', labelColor = "black" }) => {
    if (boldValue) {
        return (
            <span style={{ fontWeight: 'normal', color: labelColor }}>{label + ": "}
                <span style={{ fontWeight: 'bold', color: textColor }}>{text}</span>
            </span>
        )
    }
}

export const PartyDescription = ({ party, referenceType = 'Customer' }) => {

    let outstandingAmountColor = 'black';
    if (!isNullOrEmpty(party)) {
        var partyDesc = referenceType === 'Customer' ? safeString(party.legalName) : safeString(party.name);
        if (safeFloat(party.outstandingAmount) > 0) {
            outstandingAmountColor = 'darkred';
        }
        if (!isNullOrEmpty(party.shortName)) {
            partyDesc += " | " + party.shortName;
        }
        return (
            safeBool(party?.addNew) ?
                <>
                    <ButtonBase size="small"
                        style={
                            {
                                color: '#1976D2', width: '100%', padding: '8px', margin: '0px', border: '1px solid #1976D2', borderRadius: '5px'
                            }
                        }>
                        {partyDesc}
                    </ButtonBase>
                </> :
                <>
                    <span style={{ fontSize: '12px', borderBottom: '1px dotted lightsteelblue', width: '100%' }}>
                        {partyDesc} <br />
                        {
                            <LabelText label={'Due'} text={formatToCurency(party.outstandingAmount)} textColor={outstandingAmountColor} />
                        }

                    </span>
                </>
        )
    } else {
        return <span>Error!</span>
    }
}

export const ErrorDescription = ({ isError = true, errorObject }) => {
    try {
        if (!isNullOrEmpty(errorObject)) {
            let errorSource = 'Unknown';
            if (!isNullOrUndefined(errorObject.Source)) {
                errorSource = safeString(errorObject.Source);
            }
            if (!isEmptyObjectArray(errorObject.Messages)) {
                return <span style={{ padding: '16px' }}>
                    <h2>{'Source: ' + errorSource}</h2>
                    {
                        isError ?
                            <h4>{'Following errors occured: '}</h4>
                            :
                            <h4>{'Summary: '}</h4>
                    }

                    <ol style={{ paddingLeft: '16px' }}>
                        {
                            errorObject.Messages.map((message, i) => {
                                return (
                                    <li>{message}</li>
                                )
                            })
                        }
                    </ol>
                </span>
            } else {
                return <span>
                    <h3>{errorSource}</h3>
                </span>
            }
        } else {
            return <span style={{ color: 'red' }}>Unknown Error!</span>
        }
    } catch (error) {
        console.log(error);
        return <span style={{ color: 'red' }}>Unknown Error!</span>
    }
}

export const EditLabel = ({ value, handleClick }) => {
    const classes = useStyles();
    return (
        <span className={classes.editableLabel} onClick={handleClick}>
            <span style={{ marginRight: '2px' }}>
                {value}
            </span>
            <AppIcon name="EditSmall" size="xs" />
        </span>
    )
}

export const PdfViewer = ({ url }) => {
    return (
        <>
            <iframe src={url} width="900" height="700"></iframe>
        </>
    )
}


export const EBPopper = (props) => {
    const classes = useStyles();
    return <Popper {...props} className={classes.popperFullWidth} placement="bottom-start" />;
};


export const EBCheckBox = ({ caption, infotext, color = 'primary', isAuthorised = true, ...props }) => {
    const [field, meta] = useField(props);
    if (props.hidden) {
        return false;
    }
    return (
        <>
            <Grid item xs={10} sm={6} md={3}>
                <Typography variant="button" display="block" gutterBottom style={{ fontWeight: "600" }}>
                    {caption}
                    {
                        !isAuthorised && <>
                            &nbsp;<LockIcon />
                        </>

                    }
                </Typography>
                <Typography style={{ fontStyle: 'italic', color: 'gray' }} variant="caption" display="block" gutterBottom>
                    {infotext}

                </Typography>

            </Grid>
            <Grid item xs={2} sm={6} md={3}>
                <Checkbox
                    {...field} {...props}
                    color={color}
                />

            </Grid>
        </>
    );
}

// const EBCheckBox = ({ name, checked, caption, infotext, color = 'primary', ...props }) => {
//     const [field, meta] = useField(props);
//     if (props.hidden) {
//         return false;
//     }
//     return (
//         <FormControlLabel
//             control={
//                 <Checkbox
//                     {...field} {...props}
//                     size="small"
//                 />

//             }
//             label={label}
//         >
//             {children}
//         </FormControlLabel>

//     )
// };