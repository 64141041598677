import { useState, useContext, useEffect } from "react"
import { isNullOrEmpty, isNullOrUndefined, safeInt, safeString } from "../../Base/Utils"
import { Form, Formik } from "formik";
import { getParty, sendWhatsApp } from "../OrderHelper";
import * as yup from 'yup';
import { PageHeader } from "../../UI/Controls/Controls";
import AppIcon from "../../UI/Controls/AppIcons";
import useStyles from "../../../styles";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { PhoneInput } from "../../UI/Controls/InputFields";
import useStore from "../../Base/Store";
import AuthContext from '../../Auth/AuthContext';
import shallow from "zustand/shallow";

export default function WhatsAppSend({ orderType, referenceType, selectedOrder, selectedParty, paymentLink = null }) {
    const [fieldValues, setFieldValues] = useState(
        {
            phone: ""
        }
    );
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const showAlert = useStore(state => state.showAlert);
    const closeDialog = useStore(state => state.closeDialog);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    const pageInfo = {
        pageHeading: 'Send via WhatsApp',
        pageIcon: 'WhatsappIcon',
        okButtonCaption: 'Send to number',
        secondaryButtonCaption: 'Send to contact',
        cancelButtonCaption: 'Close',
        entityName: ""
    }

    useEffect(() => {
        async function init() {
            try {
                setWaiting(true);
                const party = await getParty(safeInt(selectedParty.id), referenceType, authContext);
                let tempValues = { ...fieldValues };
                if (!isNullOrUndefined(party)) {
                    if (!isNullOrUndefined(party.addressList) && party.addressList.length > 0) {
                        const address = party.addressList.find(a => a.addressType === 'Billing')
                        tempValues.phone = safeString(address.contactPhone);
                    }
                }
                setFieldValues(tempValues);
            } catch (error) {
                console.log(error);
            } finally {
                setWaiting(false);
            }

        }
        init();
    }, []);

    const sendFromContacts = async (values) => {
        await submitFormData(values, true);
    }

    const submitFormData = async (values, sendFromContacts = false) => {
        if (!isNullOrUndefined(paymentLink)) {
            if (sendFromContacts) {
                window.open(`https://api.whatsapp.com/send/?text=${paymentLink}`, '_blank');
            } else {
                window.open(`https://api.whatsapp.com/send/?phone=${safeString(values.phone)}&text=${paymentLink}`, '_blank');
            }
        } else {
            await sendWhatsApp(values.phone, orderType, referenceType, selectedOrder, selectedParty, showAlert, authContext, sendFromContacts);
            closeDialog();
        }
    }

    return (
        <Formik
            initialValues={fieldValues}
            enableReinitialize={true}
            validationSchema={yup.object({

            })}
            onSubmit={
                async (values) => {
                    await submitFormData(values, false);
                }
            }
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <PageHeader heading={pageInfo.pageHeading}>
                        <AppIcon name={pageInfo.pageIcon} color="primary" />
                    </PageHeader>
                    <div className={classes.page}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PhoneInput label="Mobile number" name="phone" placeholder="" required
                                    inputProps={{ maxLength: 10, autoFocus: true }} />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} className={`${classes.mt2}`} spacing={1}>

                            <Grid item xs={12}>
                                <Button fullWidth type="submit" disabled={isSubmitting || waiting} variant="contained" color="primary"
                                    className={classes.successButton} title="WhatsApp to entered mobile number"
                                    startIcon={<AppIcon name="WhatsappIcon" size="small" />}>
                                    {pageInfo.okButtonCaption}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth type="button" disabled={isSubmitting || waiting} variant="contained" color="secondary"
                                    startIcon={<AppIcon name="WhatsappIcon" size="small" />} title="Send to an existing contact on WhatsApp"
                                    onClick={() => { sendFromContacts(values) }}
                                >
                                    {pageInfo.secondaryButtonCaption}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>

                                <Button variant="outlined" color="primary" fullWidth onClick={() => { closeDialog() }}>
                                    {pageInfo.cancelButtonCaption}
                                </Button>
                            </Grid>

                        </Grid>
                    </div>
                </Form>
            )}
        </Formik>
    )
};
